import {useFetchOrganization, apiResourceName} from './useFetchOrganization';
import {useQuery} from '@tanstack/react-query';

export const queryKeyOrganizationIndex = params => [apiResourceName, 'index', params];
export const queryKeyOrganizationById = params => [apiResourceName, 'byId', params];

export const useQueryOrganizationIndex = ({params, useQueryOptions}) => {
	const {index} = useFetchOrganization();
	return useQuery({
		queryKey: queryKeyOrganizationIndex(params),
		queryFn: () => index(params),
		staleTime: 60 * 1000,
		...useQueryOptions,
	});
};

export const useQueryOrganizationById = ({params, useQueryOptions}) => {
	const {byId} = useFetchOrganization();
	return useQuery({
		queryKey: queryKeyOrganizationById(params),
		queryFn: () => byId(params),
		...useQueryOptions,
	});
};
