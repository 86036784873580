import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	apiToken: null,
	activeOrganizationId: null,
	user: null,
	enioCallerCredentials: null,
	organizationChangeRedirect: null,
	personalNotices: [],
	personalNoticesPagination: {},
	loadingPersonalNotices: true,
	personalNoticesProcessing: false,
	userEntriesToday: {},
	processingTimeEntries: false,
	activeTimerRunning: null,
	activeTimerId: null,
	feedbackFormInitValues: {
		type: 'bug',
		text: '',
		view: '',
		subView: '',
	},
	callSession: {
		id: '',
		status: '',
		active: false,
		startTime: null,
		answerTime: null,
		endTime: null,
		customerURI: '',
		customerDetails: {},
		errors: null,
	},
	callSessionLogs: [],
	enioCallerVisible: true,
	sipCallerExpanded: false,
	deviceId: null,
	feedbackLoading: false,
	feedbackModalOpen: false,
	leaddeskTalkExpanded: true,
	apiKeys: {
		PUSHER_API_KEY: '',
		GOOGLE_API_KEY: '',
		HERE_MAPS_API_KEY: '',
		MAPBOX_API_KEY: '',
	},
	overviewSearchIds: [],
	overviewSearchTeams: [],
	reasonMappings: [],
	processingTag: false,
	activeCallPoolId: null,
	activeCallPools: [],
	activeCallPoolsProcessing: false,
	dailyCallDurationInSeconds: null,
};
