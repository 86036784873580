import * as perms from 'utils/perms';
import {over} from 'utils/lenses';
import memoize from 'memoize-one';
//import {inDev} from 'constants/app';

// memoize the permission utils used, since their parameters usually don't change here and they may get used multiple times
const userAppPermissions = memoize(perms.userAppPermissions);
const isSubcontractorUser = memoize(perms.isSubcontractorUser);
const isCustomerOrganization = memoize(perms.isCustomerOrganization);
const isPilotUser = memoize(perms.isPilotUser);
const isSalesmanagerUser = memoize(perms.isSalesmanagerUser);
// const showAllMarketingLeads = memoize(perms.showAllMarketingLeads);
// const showActiveOrganizationMarketingLeads = memoize(
// 	perms.showActiveOrganizationMarketingLeads,
// );
const usersAppRolesPageAccess = memoize(perms.usersAppRolesPageAccess);
const usersAppOrganizationsPageAccess = memoize(perms.usersAppOrganizationsPageAccess);
const marketingAppSettingsPageAccess = memoize(perms.marketingAppSettingsPageAccess);
const buildingsAppRemovalRequestsPageAccess = memoize(
	perms.buildingsAppRemovalRequestsPageAccess,
);
const buildingsAppShowCsvImporterAccess = memoize(
	perms.buildingsAppShowCsvImporterAccess,
);

// NOTE: try to keep the routes in views equal to the "doShow" criteria here. otherwise people can just navigate to the right url to get access.

const pageDefsBase = [
	{
		name: 'Wäylä [sovellus]',
		id: 'wayla',
		href: 'https://wayla.renoa.com',
		iconName: 'waylaApp',
		pages: [],
		hiddenPages: [],
		noPermissionNeeded: true,
		doShow: ({activeOrganization, user}) =>
			!isCustomerOrganization(activeOrganization) && user.accountId === 1,
	},
	{
		name: 'Maps [app]',
		id: 'maps',
		to: '/maps',
		iconName: 'mapsApp',
		pages: [],
		hiddenPages: [],
		//help: `In this app, you can control callpools and inspect areas and buildings on a map. You can use callpools to define which booking team should call which area from the Calls app.`,
	},
	{
		name: 'Calls [app]',
		id: 'calls',
		to: '/calls',
		iconName: 'callsApp',
		pages: [
			{name: 'Situation center', to: '/dashboard'},
			{name: 'My reservations', to: '/reservations'},
			{
				name: 'Map view [Freeride]',
				to: '/freeride',
				doShow: ({user}) => !isSubcontractorUser(user),
			},
			{
				name: 'Search [alt]',
				to: '/listview',
				doShow: ({user}) => !isSubcontractorUser(user),
			},
			{
				name: 'Lists',
				to: '/lists',
			},
		],
		hiddenPages: [{name: 'Building', to: '/buildings/[^/]+'}],
	},
	{
		name: 'Visits [app]',
		id: 'd2d',
		to: '/d2d',
		iconName: 'd2dApp',
		pages: [
			{name: 'Map view [Freeride]', to: '/freeride'},
			{name: 'Situation center', to: '/dashboard'},
			{name: 'My reservations', to: '/reservations'},
			{name: 'Search [alt]', to: '/listview'},
			{name: 'Lists', to: '/lists'},
		],
		hiddenPages: [{name: 'Building', to: '/buildings/[^/]+'}],
	},
	{
		name: 'Sales Tool [app]',
		id: 'salesman-app',
		to: '/salesman-app',
		iconName: 'salesmanApp',
		pages: [
			{
				name: 'Statistics',
				to: '/stats',
			},
			{
				name: 'Nearest contacts',
				to: '/contacts',
				// Preemptively hiding contacts page from customerOrganization
				doShow: ({activeOrganization}) => !isCustomerOrganization(activeOrganization),
			},
			{
				name: 'Area management',
				to: '/team-areas',
				doShow: ({user}) => isSalesmanagerUser(user),
			},
		],
		hiddenPages: [
			{name: 'The goal of the team', to: '/team-target'},
			{name: 'The goal of the organization', to: '/organization-target'},
		],
	},
	{
		name: 'Project Sales [app]',
		id: 'project-sales',
		to: '/project-sales',
		iconName: 'projectSalesApp',
		pages: [
			{name: 'Situation center', to: '/dashboard'},
			{name: 'Map', to: '/map'},
			{name: 'Sales pipeline', to: '/ca-tracking'},
		],
		hiddenPages: [],
		//doShow: ({user}) => user.accountId === 1 || (user.accountId === 3 && inDev),
	},
	{
		name: 'Team Calendar [app]',
		id: 'team-calendar',
		to: '/team-calendar',
		iconName: 'teamCalendarApp',
		pages: [],
		hiddenPages: [],
	},
	{
		name: 'Calendar [app]',
		id: 'own-calendar',
		to: '/calendar',
		iconName: 'calendarApp',
		pages: [
			{name: 'Calendar', to: '/calendar'},
			{
				name: 'Work time management',
				to: '/time-entries',
				doShow: ({user}) => !isSubcontractorUser(user),
			},
			{
				name: 'Visits management',
				to: '/salesman-visits',
				doShow: ({user}) => !isSubcontractorUser(user),
			},
		],
		hiddenPages: [],
	},
	{
		name: 'Leads [app]',
		id: 'leads',
		to: '/leads',
		iconName: 'leadsApp',
		pages: [
			{name: 'Leads', to: '/leads'},
			{name: 'Add lead', to: '/create'},
		],
		hiddenPages: [{name: 'Lead', to: '/lead/[^/]+'}],
	},
	{
		name: 'Marketing [app]',
		id: 'marketing',
		to: '/marketing',
		iconName: 'marketingApp',
		pages: [
			{name: 'Leads [tip]', to: '/leads'},
			{name: 'Add lead', to: '/create'},
			{name: 'Reminders', to: '/reminders'},
			{name: 'Tracking', to: '/tracking'},
			{name: 'Client register', to: '/clients'},
			{name: 'Changelog', to: '/changelog'},
			{
				name: 'Settings',
				to: '/settings',
				doShow: ({user}) => marketingAppSettingsPageAccess(user),
			},
		],
		hiddenPages: [{name: 'Marketing lead', to: '/lead/[^/]+'}],
	},
	{
		name: 'Statistics [app]',
		id: 'stats',
		to: '/stats',
		iconName: 'statsApp',
		pages: [
			{name: 'Statistics', to: '/stats'},
			{name: 'Settings', to: '/settings'},
		],
		hiddenPages: [],
	},
	{
		name: 'Reports [app]',
		id: 'reports',
		to: '/reports',
		iconName: 'reportsApp',
		pages: [
			{name: 'Reports', to: '/reports'},
			{name: 'Tracking forms', to: '/tracking-forms'},
		],
		hiddenPages: [{name: 'Report', to: '/reports/[^/]+'}],
	},
	{
		name: 'Overview [app]',
		id: 'overview',
		to: '/overview',
		iconName: 'monitorChart',
		pages: [
			{
				name: 'Overview [app]',
				to: '/overview',
				doShow: ({user}) => userAppPermissions(user).has('overview'),
			},
			{
				name: 'Free appointment times',
				to: '/free-calendar-resource-report',
				doShow: ({user}) => userAppPermissions(user).has('overview'),
			},
			{
				name: 'Working hours distribution',
				to: '/working-hours-report',
				doShow: ({user}) => userAppPermissions(user).has('overview'),
			},
			{
				name: 'Tracking',
				to: '/tracking',
				doShow: ({user}) => userAppPermissions(user).has('overview'),
			},
			{
				name: 'Calls [overview]',
				to: '/calls',

				doShow: ({user}) => userAppPermissions(user).has('overview'),
			},
			{
				name: 'Ending reasons',
				to: '/ending-reasons',
				doShow: ({user}) =>
					userAppPermissions(user).has('overview') || perms.canReadEncounterable(user),
			},
			{
				name: 'SMS logs',
				to: '/sms-logs',

				doShow: ({user}) => userAppPermissions(user).has('overview'),
			},
		],
		hiddenPages: [],
	},
	{
		name: 'Buildings [app]',
		id: 'buildings',
		to: '/buildings',
		iconName: 'buildingsApp',
		pages: [
			{name: 'Buildings', to: '/buildings'},
			{name: 'Client register', to: '/clients'},
			{name: 'Ban list', to: '/bans'},
			{name: 'Add building', to: '/add-building'},
			{
				name: 'Removal request register',
				to: '/removal-requests',
				doShow: ({user}) => buildingsAppRemovalRequestsPageAccess(user),
			},
			{
				name: 'CSV Import',
				to: '/csv-imports',
				doShow: ({user}) => buildingsAppShowCsvImporterAccess(user),
			},
		],
		hiddenPages: [{name: 'Building', to: '/buildings/[^/]+'}],
	},
	{
		name: 'Files [app]',
		id: 'files',
		to: '/files',
		iconName: 'filesApp',
		pages: [],
		hiddenPages: [],
	},
	{
		name: 'Control Center [app]',
		id: 'control-center',
		to: '/control-center',
		iconName: 'groupLogo',
		pages: [
			{name: 'Situation center', to: '/dashboard'},
			{name: 'Logs', to: '/logs'},
			{name: 'Feedback [plural]', to: '/feedback'},
			{name: 'Events', to: '/events'},
		],
		hiddenPages: [],
		doShow: ({user}) => user.accountId === 1,
	},
	{
		id: 'users',
		// message id of the app name
		name: 'Users [app]',
		to: '/users',
		iconName: 'usersApp',
		pages: [
			{name: 'Users', to: '/users'},
			{name: 'Teams', to: '/teams'},
			{name: 'Roles', to: '/roles', doShow: ({user}) => usersAppRolesPageAccess(user)},
			{
				name: 'Organizations',
				to: '/organizations',
				doShow: ({user}) => usersAppOrganizationsPageAccess(user),
			},
			{
				name: 'Settings',
				to: '/settings',
				pages: [
					{
						name: 'Tags',
						to: '/tags',
					},
					{
						name: 'ReasonMappings',
						to: '/reasonMappings',
					},
					{
						name: 'SmsTemplates',
						to: '/smsTemplates',
					},
					{
						name: 'DashboardTargets',
						to: '/dashboard-targets',
					},
					{
						name: 'CalendarEventTypes',
						to: '/calendar-event-types',
					},
					{
						name: 'Project states',
						to: '/project-states',
					},
					{
						name: 'Project types',
						to: '/project-types',
					},
				],
			},
			// supply the "doShow" predicate function to give a page criteria for when it's shown. see the logic below for for the parameters "doShow" receives.
			// set "isPiloting" to true to mark page visible only to pilot users
		],
		// hiddenPages are only relevant for finding the title of the current page. they should override any possible match found in "pages" when deciding the title.
		hiddenPages: [],
		// app description text message id. we can keep it simple because one line is always enough.
		//help: `In this app, you can manage users, teams and organizations.`,
		// set "isPiloting" to true to mark app visible only to pilot users
	},
	{
		name: 'WheelQ',
		id: 'wheelq',
		href: 'https://app.wheelq.com/',
		iconName: 'groupLogo',
		pages: [],
		hiddenPages: [],
	},
	{
		name: 'Netvisor',
		id: 'netvisor',
		href: 'https://login.netvisor.fi/',
		iconName: 'groupLogo',
		pages: [],
		hiddenPages: [],
	},
	{
		name: 'Mapon',
		id: 'mapon',
		href: 'https://mapon.com/new/',
		iconName: 'groupLogo',
		pages: [],
		hiddenPages: [],
	},
	{
		name: 'References [app]',
		id: 'references',
		to: '/references',
		iconName: 'referencesApp',
		pages: [{name: 'List view', to: '/listview'}],
		hiddenPages: [],
	},
];

const buildPageDefs = (definitions = [], path = '') => {
	return definitions.map(p => {
		const fullPathToHere = path + p.to;

		return p.to
			? {
					...p,
					to: fullPathToHere,
					fullPathToHere,
					pages: buildPageDefs(p?.pages || [], fullPathToHere),
			  }
			: p;
	});
};

const _pageDefs = pageDefsBase.map(app => ({
	...app,
	pages: buildPageDefs(app.pages, app.to),
	hiddenPages: buildPageDefs(app.hiddenPages, app.to),
}));

export const pageDefs = (user, activeOrganization) => {
	const permissions = userAppPermissions(user);
	const isPilot = isPilotUser(user);

	/* 2024-01-27:
	 *
	 * We want show 'ending-reasons' page to any user who has either full access to overview app or specific read access to the encounterables page.
	 * Having read access to ending reasons is enough to access the overview app (but other content will be authorized separately).
	 *
	 * Hence, this special case for the overview app.
	 */
	const overviewAppAccess = a => defaultAppAccess(a) || perms.canReadEncounterable(user);

	const defaultAppAccess = a =>
		(permissions.has(a.id) || a.noPermissionNeeded) &&
		(!a.isPiloting || isPilot) &&
		(!a.doShow || a.doShow({user, activeOrganization}));

	return _pageDefs
		.filter(a => (a.id === 'overview' ? overviewAppAccess(a) : defaultAppAccess(a)))
		.map(
			over(['pages'], pages =>
				pages.filter(p =>
					// prettier-ignore
					p.doShow
						? p.doShow({user, activeOrganization})
						: !p.isPiloting || isPilot,
				),
			),
		);
};

const hiddenPageDefsBase = [
	{
		id: 'sales',
		name: 'Sales [app]',
		// TODO: get a new icon for this
		iconName: 'groupLogo',
	},
	{
		id: 'profile',
		name: 'Profile',
		iconName: 'groupLogo',
	},
	{
		id: 'notices',
		name: 'Internal releases',
		iconName: 'groupLogo',
	},
];

// permissions not checked for these since they're not used in listings, only for providing info about the current page. the apps themselves should do the actual permission enforcing.
// each app has an empty dummy pages list to make usage easier. similarly, they have an URL, "#", which makes react-router render a link to the current page (similar to href="").
export const hiddenPageDefs = hiddenPageDefsBase.map(a => ({...a, pages: [], to: '#'}));
