export const CALL_STATUS = {
	IDLE: 'Idle',
	TRYING: 'Trying',
	CALL_IN_PROGRESS: 'Call in progress...',
	RINGING: 'Ringing',
	OK: 'OK',
	IN_CALL: 'In Call',
	MEDIA_ADDED: 'Media Added',
	CALL_TERMINATED: 'Call terminated',
	TERMINATING: 'Call terminating...',
	BUSY_HERE: 'Busy Here',
	REQUEST_TERMINATED: 'Request Terminated',
	NOT_FOUND: 'Not Found',
	SESSION_PROGRESS: 'Session Progress',
	TEMPORARILY_UNAVAILABLE: 'Temporarily Unavailable',
	FORBIDDED: 'Forbidden',
	CALL_IS_BEING_FORWARDED: 'Call Is Being Forwarded',
	SIP_ERROR: 'SIP_ERROR',
	DOES_NOT_EXIST_ANYWHERE: 'Does Not Exist Anywhere',
	CONCURRENT_THIRD_PARTY:
		'Concurrent third party phone calls exceeds configured value for user',
	NOT_ACCEPTABLE: 'Not Acceptable',
	SERVER_TIMEOUT: 'Server Time-out',
	TRANSPORT_ERROR: 'Transport error',
	ADDRESS_INCOMPLETE: 'Address Incomplete',
	TEMPORARILY_NOT_AVAILABLE: 'Temporarily not available',
	UNKOWN: 'unknown',
};
