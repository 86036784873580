import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {TARGET_BUDGET} from './constants';

const root = path(namespace);

export const initialized = pipe(root, prop('initialized'));

export const dashboardTargets = pipe(root, prop('dashboardTargets'));

export const dashboardBudgetTarget = createSelector(
	[dashboardTargets],
	targets => targets.find(t => t.type === TARGET_BUDGET) ?? null,
);

export const dashboardTargetsLoading = pipe(root, prop('dashboardTargetsLoading'));

export const processing = pipe(root, prop('processing'));

export const dashboardTargetsQuery = pipe(root, prop('dashboardTargetsQuery'));

export const availableTeams = pipe(root, prop('availableTeams'));

export const activeTeam = createSelector(
	[availableTeams, dashboardTargetsQuery],
	(teams, query) => {
		const teamId = query.teamId ?? null;
		if (teamId && teams && teams.length) {
			return teams.find(t => t.id === teamId);
		}
		return null;
	},
);
