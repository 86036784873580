import {useFetchTeam, apiResourceName} from './useFetchTeam';
import {useQuery} from '@tanstack/react-query';

export const useQueryTeamIndex = ({params, useQueryOptions}) => {
	const {index} = useFetchTeam();
	return useQuery({
		queryKey: [`${apiResourceName}`, 'index', params],
		queryFn: () => index(params),
		staleTime: 60 * 1000,
		...useQueryOptions,
	});
};

export const useQueryTeamById = ({params, useQueryOptions}) => {
	const {byId} = useFetchTeam();
	return useQuery({
		queryKey: [`${apiResourceName}`, 'byId', params],
		queryFn: () => byId(params),
		...useQueryOptions,
	});
};
