import * as React from 'react';
import clsx from 'clsx';
import {isValidPhoneNumber, normalizePhone} from 'utils/phone-number';
import {FormattedMessage} from '@meiko/react-intl';

export const CallDialForm = ({onSubmit, value, onChange}) => {
	const handleSubmit = () => {
		onSubmit(value);
	};

	const handleInputChange = e => {
		const normalizedPhone = normalizePhone(e.target.value);

		if (normalizedPhone < 1) {
			onChange(normalizedPhone);
			return;
		}
		if (!/^\+?\d*$/.test(normalizedPhone)) return;
		onChange(normalizedPhone);
	};

	const numberIsValid = value.length > 0 ? isValidPhoneNumber(value) : true;

	return (
		<div className="px-4 py-3 flex flex-row gap-2 text-sm bg-gray-50">
			<input
				placeholder="Puhelinnumero"
				style={{flex: 1}}
				value={value}
				onChange={handleInputChange}
				className={clsx(
					'border border-gray-200 rounded-md px-2 h-8',
					numberIsValid ? 'bg-white' : 'bg-red-50 border-red-500',
				)}
			/>
			<button
				className="bg-green-600 text-white px-2 rounded-lg h-8 disabled:opacity-50 disabled:cursor-not-allowed"
				disabled={!numberIsValid}
				onClick={handleSubmit}
			>
				<FormattedMessage id="Call" />
			</button>
		</div>
	);
};
