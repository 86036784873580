import {useApi} from 'hooks/api/useApi';

export const apiResourceName = 'target';
export const apiPath = '/targets';

export const useFetchTarget = () => {
	const {get, post} = useApi();

	// verbose and strict because we do not have ts interfaces yet
	const index = ({
		organizationId,
		teamId,
		teamIds,
		userId,
		userIds,
		month,
		year,
		timeSpan,
		type,
		getFullWeeks,
		startDateStart,
		startDateEnd,
	}) =>
		get(`${apiPath}/v2`, {
			params: {
				organizationId,
				teamId,
				teamIds,
				userId,
				userIds,
				month,
				year,
				timeSpan,
				type,
				getFullWeeks,
				startDateStart,
				startDateEnd,
			},
		});

	const byId = ({targetId}) => get(`${apiPath}/${targetId}`, {});

	const create = body => post(`${apiPath}`, {body});

	const batch = body => post(`${apiPath}/batch`, {body});

	return {
		index,
		byId,
		create,
		batch,
	};
};
