import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/PanelHeaderTitle';
import Panel from 'components/generic/Panel';
import React from 'react';
import Chart from 'react-chartjs-2';
import {compose} from 'redux';
import {getColorsByLabel} from './SalesFromSourcesChart';
import useWindowWidth from 'hooks/windowWidth';
import {screenMdMinValue} from 'styles/constants';

const options = {
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true,
		},
	},
	maintainAspectRatio: false,
};

const MeetingsByCustomerChart = ({data, intl}) => {
	const {width: windowWidth} = useWindowWidth();

	const datasByType = data.reduce((acc, row) => {
		(row?.values ?? []).forEach(({label, value}) => {
			const cur = acc[label] ?? [];
			acc[label] = [...cur, value];
		});
		return acc;
	}, {});
	const labels = data.map(entry => entry.columnLabel);

	const chartData = {
		labels,
		datasets: [
			{
				type: 'line',
				label: intl.formatMessage({id: 'Dashboard target'}),
				borderColor: 'rgb(255, 99, 132)',
				borderWidth: 1,
				fill: false,
				data: data.map(entry => entry.target),
				datalabels: {
					display: false,
				},
			},
			...Object.keys(datasByType).map((key, index) => {
				const d = datasByType[key];
				return {
					type: 'bar',
					label: key,
					data: d,
					backgroundColor: getColorsByLabel(key).bg,
					borderColor: getColorsByLabel(key).fg,
					borderWidth: 1,
				};
			}),
		],
	};

	return (
		<Panel
			header={
				<PanelHeaderTitle>
					<FormattedMessage id="Meetings by customer sources" />
				</PanelHeaderTitle>
			}
		>
			<Chart
				height={windowWidth > screenMdMinValue ? 300 : 250}
				type="bar"
				data={chartData}
				options={options}
			/>
		</Panel>
	);
};

export default compose(injectIntl)(MeetingsByCustomerChart);
