import styled from 'styled-components';

// TODO use tailwind + clsx here
const PanelHeaderTitle = styled.span`
	font-size: 16px;
	font-weight: 700;
	font-family: rift;
	text-transform: uppercase;
	${({textCenter}) => (textCenter ? `text-align: center; display: block;` : '')}
`;

export default PanelHeaderTitle;
