import {useApi} from 'hooks/api/useApi';

export const apiResourceName = 'holiday';
export const apiPath = '/holidays';

export const useFetchHoliday = () => {
	const {get} = useApi();

	const index = ({start, end}) =>
		get(apiPath, {
			params: {
				start,
				end,
			},
		});

	return {
		index,
	};
};
