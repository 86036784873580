import React from 'react';
import {TeamTargetsForm} from './TeamTargetsForm';
import {useQueryTargetIndex} from 'hooks/api/target/useQueryTarget';
import {FormattedNumber, FormattedMessage} from '@meiko/react-intl';
import {useQueryUserIndex} from 'hooks/api/user/useQueryUser';
import {
	currencyOptions,
	sessionStorageKeys,
	getThisMonthISOString,
	useMonthWeeks,
} from './utils';
import {getYear, getMonth} from 'date-fns';
import {Loader2} from 'lucide-react';
import MonthPicker from './MonthPicker';
import TeamPicker from './TeamPicker';
import {useAuth} from 'hooks/useAuth';
import {useSessionStorage} from 'react-use';

export const TeamTargets = () => {
	const [teamId, setTeamId] = useSessionStorage(sessionStorageKeys.teamId, null);

	const [date, setDate] = useSessionStorage(
		sessionStorageKeys.date,
		getThisMonthISOString(),
	);

	const {user} = useAuth();
	const canShowBudget = !!user?.permissions.find(p => p.slug === 'budget.show');

	const year = date ? getYear(new Date(date)) : null;
	const month = date ? getMonth(new Date(date)) + 1 : null;

	const {data: usersData, isFetching: isUsersDataFetching} = useQueryUserIndex({
		params: {
			teamId,
		},
		useQueryOptions: {
			enabled: !!teamId,
		},
	});

	const monthWeeks = useMonthWeeks({date});
	const weeks = monthWeeks.filter(({sameMonthDays}) => sameMonthDays.length);

	const {data: weekTargetData, isFetching: isWeekTargetDataFetching} =
		useQueryTargetIndex({
			params: {
				startDateStart: weeks[0]?.start?.toISOString(),
				startDateEnd: weeks[weeks.length - 1]?.end?.toISOString(),
				timeSpan: 'week',
				userIds: usersData?.data?.map(({id}) => id),
			},
			useQueryOptions: {
				enabled: !!date && !!teamId && !!weeks.length,
			},
		});

	const {data: monthlyDayTargetData, isFetching: isMonthlyTargetDataFetching} =
		useQueryTargetIndex({
			params: {
				year,
				month,
				timeSpan: 'day',
				userIds: usersData?.data?.map(({id}) => id),
			},
			useQueryOptions: {
				enabled: !!date && !!teamId,
			},
		});

	const monthlySalesTargetSum = monthlyDayTargetData?.data
		?.filter(t => t.type === 'dashboard-sales-sum')
		.reduce((acc, t) => acc + t.target, 0);

	const weekTargetSum = weekTargetData?.data
		?.filter(t => t.type === 'dashboard-sales-sum')
		.reduce((acc, t) => acc + t.target, 0);

	const {data: monthBudgetData, isFetching: isMonthBudgetDataFetching} =
		useQueryTargetIndex({
			params: {
				year,
				month,
				timeSpan: 'month',
				type: 'dashboard-sales-budget-sum',
				teamId,
			},
			useQueryOptions: {
				enabled: !!date && !!teamId && canShowBudget,
			},
		});

	const budgetSum = monthBudgetData?.data?.[0]?.target;

	const budgetDiff = (monthlySalesTargetSum || 0) - (budgetSum || 0);
	const isBudgetDiffVisible =
		canShowBudget && !isWeekTargetDataFetching && budgetDiff !== 0;

	const isPending = isWeekTargetDataFetching || isUsersDataFetching;

	return (
		<div className="flex flex-col gap-6">
			<div className="flex justify-between items-start gap-8">
				<div className="flex flex-col gap-2 pl-4 border-r border-gray-100 pr-8">
					<MonthPicker month={date} onMonthChange={setDate} />
					<TeamPicker teamId={teamId} onTeamIdChange={setTeamId} />
				</div>

				<div className="flex justify-end items-start gap-6">
					{canShowBudget && (
						<div className="bg-amber-100 font-medium px-4 py-2 flex flex-col gap-1 rounded-lg border border-amber-200 min-w-32">
							<span className="text-yellow-700">
								<FormattedMessage id="Budget" />
							</span>
							<span className="flex items-center gap-1">
								{isMonthBudgetDataFetching ? (
									<Loader2 className="size-4 animate-spin my-1" />
								) : (
									<span className="text-gray-700 text-base">
										{budgetSum ? (
											<FormattedNumber value={budgetSum} {...currencyOptions} />
										) : (
											'-'
										)}
									</span>
								)}
							</span>
						</div>
					)}
					<div className="bg-teal-50 text-teal-800 font-medium py-2 flex divide-x divide-teal-800/10 border border-teal-800/10 rounded-lg min-w-32">
						<div className="flex flex-col gap-1 px-4">
							<span>
								<FormattedMessage id="Monthly target sum {date}" values={{date}} />
							</span>
							<div className="flex items-center gap-1.5">
								{isMonthlyTargetDataFetching ? (
									<Loader2 className="size-4 animate-spin my-1" />
								) : (
									<span className="text-base text-gray-700">
										{monthlySalesTargetSum ? (
											<FormattedNumber
												value={monthlySalesTargetSum}
												{...currencyOptions}
											/>
										) : (
											'-'
										)}
									</span>
								)}
								{isBudgetDiffVisible && (
									<span className="text-sm text-gray-700/50">
										(<FormattedNumber value={budgetDiff} {...currencyOptions} />)
									</span>
								)}
							</div>
						</div>
						<div className="flex flex-col gap-1 px-4">
							<span>
								<FormattedMessage id="Weekly target sum" />
							</span>
							<div className="flex items-center gap-1.5">
								{isMonthlyTargetDataFetching ? (
									<Loader2 className="size-4 animate-spin my-1" />
								) : (
									<span className="text-base text-gray-700">
										{weekTargetSum ? (
											<FormattedNumber value={weekTargetSum} {...currencyOptions} />
										) : (
											'-'
										)}
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<TeamTargetsForm
				teamId={teamId}
				date={date}
				targets={weekTargetData?.data}
				users={usersData?.data}
				isPending={isPending}
			/>
		</div>
	);
};
