import {useApi} from 'hooks/api/useApi';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
// Initial data is loaded in `afterApiTokenReceival` in modules/common/effects.js
// so we use the same include for the query
import {userInclude} from 'modules/common/constants';

export const apiResourceName = 'user';
export const apiPath = '/users';

export const useFetchUser = () => {
	const {get} = useApi();

	const index = ({userIds = [], teamId}) =>
		get(apiPath, {
			params: {
				userIds,
				teamId,
				_limit: 999,
			},
		});

	const byId = ({userId}) => get(`${apiPath}/${userId}`);

	const me = () =>
		get(`${apiPath}/me`, {
			params: {
				include: userInclude.include,
			},
		}).then(getResponseData(normalize.user));

	return {
		index,
		byId,
		me,
	};
};
