import React, {useMemo, useState} from 'react';
import {FormattedMessage, FormattedNumber, injectIntl} from '@meiko/react-intl';
import Table from 'components/generic/Table';
import PanelHeaderTitle from 'components/generic/PanelHeaderTitle';
import Panel from 'components/generic/Panel';
import {compose} from 'redux';
import {Settings, Loader2} from 'lucide-react';
import Button from 'components/generic/Button';
import {currency} from 'constants/loc';
import {useAuth} from 'hooks/useAuth';
import {useQueryTargetIndex} from 'hooks/api/target/useQueryTarget';
import {useQueryTeamIndex} from 'hooks/api/team/useQueryTeam';
import {getYear, getMonth} from 'date-fns';
import TargetEditorModal from 'views/TargetEditor/TargetEditorModal';
const numberFormatOptions = {
	style: 'currency',
	currency,
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
};

const BudgetValue = ({value}) => (
	<span className="px-2 py-0.5 bg-amber-100 text-gray-700 rounded-lg font-medium">
		{typeof value === 'number' ? (
			<FormattedNumber value={value} {...numberFormatOptions} />
		) : (
			<span className="text-gray-700">-</span>
		)}
	</span>
);

const BudgetTable = ({data, isFetching}) => (
	<Table slimRows>
		<tbody>
			<tr>
				<td>
					<FormattedMessage id="Ongoing month" />
				</td>
				<td className="text-right">
					{isFetching ? (
						<div className="h-3">
							<Loader2 className="size-3 animate-spin text-gray-500" />
						</div>
					) : (
						<BudgetValue value={data?.month} />
					)}
				</td>
			</tr>
			<tr>
				<td>
					<FormattedMessage id="Ongoing year" />
				</td>
				<td className="text-right">
					{isFetching ? (
						<div className="h-3">
							<Loader2 className="size-3 animate-spin text-gray-500" />
						</div>
					) : (
						<BudgetValue value={data?.year} />
					)}
				</td>
			</tr>
		</tbody>
	</Table>
);

const BudgetPanel = () => {
	const {organizationId} = useAuth();
	const [dashboardTargetsModalOpen, setDashboardTargetsModalOpen] = useState(false);
	const year = getYear(new Date());
	const month = getMonth(new Date()) + 1;

	const {data: teamsData} = useQueryTeamIndex({
		params: {
			organizationId,
			getAllTeams: false,
			type: 'sales',
		},
	});

	const teamIds = teamsData?.data?.map(({id}) => id);

	const budgetParamsBase = {
		organizationId,
		year,
		timeSpan: 'month',
		type: 'dashboard-sales-budget-sum',
	};

	const teamsBudgetParamsBase = {
		...budgetParamsBase,
		teamIds,
	};

	const useQueryOptions = {
		enabled: !!organizationId,
	};

	const {
		data: organizationBudgetDataForCurrentMonth,
		isFetching: isOrganizationBudgetDataForCurrentMonthFetching,
	} = useQueryTargetIndex({
		params: {
			...budgetParamsBase,
			month,
		},
		useQueryOptions,
	});

	const {
		data: organizationBudgetDataForCurrentYear,
		isFetching: isOrganizationBudgetDataForCurrentYearFetching,
	} = useQueryTargetIndex({
		params: budgetParamsBase,
		useQueryOptions,
	});

	const {
		data: teamsBudgetDataForCurrentMonth,
		isFetching: isTeamsBudgetDataForCurrentMonthFetching,
	} = useQueryTargetIndex({
		params: {
			...teamsBudgetParamsBase,
			month,
		},
		useQueryOptions,
	});

	const {
		data: teamsBudgetDataForCurrentYear,
		isFetching: isTeamsBudgetDataForCurrentYearFetching,
	} = useQueryTargetIndex({
		params: teamsBudgetParamsBase,
		useQueryOptions,
	});

	const getOrganizationBudgetSum = data =>
		data
			?.filter(({teamId, userId}) => !teamId && !userId)
			.reduce((acc, cur) => acc + cur.target, 0);

	const getTeamsBudgetSum = data =>
		data
			?.filter(({teamId, userId}) => !!teamId && !userId)
			.reduce((acc, cur) => acc + cur.target, 0);

	const data = useMemo(() => {
		return {
			organization: {
				month: getOrganizationBudgetSum(organizationBudgetDataForCurrentMonth?.data),
				year: getOrganizationBudgetSum(organizationBudgetDataForCurrentYear?.data),
			},
			teams: {
				month: getTeamsBudgetSum(teamsBudgetDataForCurrentMonth?.data),
				year: getTeamsBudgetSum(teamsBudgetDataForCurrentYear?.data),
			},
		};
	}, [
		organizationBudgetDataForCurrentMonth,
		organizationBudgetDataForCurrentYear,
		teamsBudgetDataForCurrentMonth,
		teamsBudgetDataForCurrentYear,
	]);

	const isFetching =
		isOrganizationBudgetDataForCurrentMonthFetching ||
		isOrganizationBudgetDataForCurrentYearFetching ||
		isTeamsBudgetDataForCurrentMonthFetching ||
		isTeamsBudgetDataForCurrentYearFetching;

	return (
		<Panel
			header={
				<div className="flex gap-2 items-center justify-between">
					<PanelHeaderTitle>
						<FormattedMessage id="Budget" />
					</PanelHeaderTitle>
					<Button size="xs" onClick={() => setDashboardTargetsModalOpen(true)}>
						<Settings className="size-4" />
					</Button>
				</div>
			}
		>
			<div className="py-4">
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						<span className="text-gray-500">
							<FormattedMessage id="Organization" />
						</span>
						<div className="pl-2">
							<BudgetTable data={data?.organization} isFetching={isFetching} />
							{isFetching && <div className="h-4" />}
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<span className="text-gray-500">
							<FormattedMessage id="Teams" />
						</span>
						<div className="pl-2">
							<BudgetTable data={data?.teams} isFetching={isFetching} />
							{isFetching && <div className="h-4" />}
						</div>
					</div>
				</div>
			</div>

			<TargetEditorModal
				show={dashboardTargetsModalOpen}
				onClose={() => setDashboardTargetsModalOpen(false)}
				initialTab="budget"
			/>
		</Panel>
	);
};

export default compose(injectIntl)(BudgetPanel);
