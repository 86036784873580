import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';

const root = path(namespace);

export const smsTemplates = pipe(root, prop('smsTemplates'));

export const smsTemplatesPagination = pipe(root, prop('smsTemplatesPagination'));

export const smsTemplatesLoading = pipe(root, prop('smsTemplatesLoading'));

export const isSmsTemplateModalOpen = pipe(root, prop('isSmsTemplateModalOpen'));

export const activeSmsTemplate = pipe(root, prop('activeSmsTemplate'));

export const smsTemplatesQuery = pipe(root, prop('smsTemplatesQuery'));

export const showDeleted = createSelector([smsTemplatesQuery], q => {
	return q.trashed;
});
