import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [
				{
					...state,
				},
				effects.initialize(),
			];
		}

		case actions._initialize.type: {
			return [
				{
					...state,
					initialized: true,
				},
				null,
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.getDashboardTargets.type: {
			return [
				{
					...state,
					dashboardTargetsLoading: true,
				},
				effects.getDashboardTargets(),
			];
		}

		case actions._getDashboardTargets.type: {
			return [
				{
					...state,
					dashboardTargetsLoading: false,
					dashboardTargets: payload,
				},
				null,
			];
		}

		case actions.createDashboardTarget.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.createDashboardTarget(payload),
			];
		}

		case actions._createDashboardTarget.type: {
			return [
				{
					...state,
					processing: false,
				},
				null,
			];
		}

		case actions.createDashboardBudget.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.createDashboardBudget(payload),
			];
		}

		case actions._createDashboardBudget.type: {
			return [
				{
					...state,
					processing: false,
				},
				null,
			];
		}

		case actions.setQuery.type: {
			return [
				{
					...state,
					dashboardTargetsQuery: {
						...state.dashboardTargetsQuery,
						...payload,
					},
				},
				effects.setQuery(),
			];
		}

		case actions._setAvailableTeams.type: {
			return [
				{
					...state,
					availableTeams: payload,
				},
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
