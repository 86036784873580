import {
	getDay,
	getWeek,
	eachDayOfInterval,
	startOfMonth,
	endOfMonth,
	startOfWeek,
	endOfWeek,
	startOfYear,
	endOfYear,
	isBefore,
	isAfter,
	isSameWeek,
	isSameMonth,
	addMonths,
	subMonths,
} from 'date-fns';
import {fi} from 'date-fns/locale';
import {currency} from 'constants/loc';
import {useMemo} from 'react';
import {useQueryHolidayIndex} from 'hooks/api/holiday/useQueryHoliday';

export const sessionStorageKeys = {
	teamId: 'target-editor.teamId',
	date: 'target-editor.date',
};

export const currencyOptions = {
	style: 'currency',
	currency,
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
};

export const getThisMonthISOString = () => {
	const now = new Date();
	return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
};

// create batch payload for budgets
export const createBatchPayloadForBudgets = ({formData, organizationId, year, month}) => {
	const targetBase = {
		organizationId,
		year,
		month,
		type: 'dashboard-sales-budget-sum',
		timespan: 'month',
	};

	return [
		...formData.organizations
			.filter(data => !!data.budget)
			.map(data => ({
				...targetBase,
				...(data.id && {id: data.id}),
				target: data.budget,
			})),
		...formData.teams
			.filter(data => !!data.budget)
			.map(data => ({
				...targetBase,
				teamId: data.teamId,
				...(data.id && {id: data.id}),
				target: data.budget,
			})),
	];
};

// create batch payload for week targets
export const createBatchPayloadForWeekTargets = ({
	formData,
	organizationId,
	teamId,
	year,
}) =>
	formData.users
		.reduce(
			(acc, user) => [
				...acc,
				...user.weeks.map(({weekNumber, sales, offers, visits}) => {
					const targetBase = {
						organizationId,
						userId: user.id,
						teamId,
						timespan: 'week',
						week: weekNumber,
						year,
					};

					return [
						{
							...targetBase,
							type: 'dashboard-sales-sum',
							...(sales.id && {id: sales.id}),
							target: sales.target,
						},
						{
							...targetBase,
							type: 'dashboard-offers-count',
							...(offers.id && {id: offers.id}),
							target: offers.target,
						},
						{
							...targetBase,
							type: 'dashboard-visits-count',
							...(visits.id && {id: visits.id}),
							target: visits.target,
						},
					];
				}),
			],
			[],
		)
		.flat()
		.filter(({target}) => !!target);

// get month weeks
export const getMonthWeeks = date => {
	if (!date) return [];

	const start = startOfMonth(new Date(date));
	const end = endOfMonth(new Date(date));
	const allDays = eachDayOfInterval({start, end});
	const weeks = new Map();

	allDays.forEach(day => {
		const weekNumber = getWeek(new Date(day), {locale: fi});

		if (!weeks.has(weekNumber)) {
			weeks.set(weekNumber, {
				weekNumber,
				start: startOfWeek(new Date(day), {weekStartsOn: 1}),
				end: endOfWeek(new Date(day), {weekStartsOn: 1}),
			});
		}
	});

	return Array.from(weeks.values());
};

// use month weeks
export const useMonthWeeks = ({date}) => {
	const monthDate = useMemo(() => (date ? new Date(date) : undefined), [date]);
	const monthWeeks = monthDate ? getMonthWeeks(monthDate) : [];
	const firstWeekStart = monthDate ? monthWeeks[0].start : undefined;
	const lastWeekEnd = monthDate ? monthWeeks[monthWeeks.length - 1].end : undefined;
	const startOfYearDate = monthDate ? startOfYear(monthDate) : undefined;
	const endOfYearDate = monthDate ? endOfYear(monthDate) : undefined;

	const {data: holidayData} = useQueryHolidayIndex({
		params: {
			start: monthDate
				? isBefore(firstWeekStart, startOfYearDate)
					? firstWeekStart.toISOString()
					: startOfYearDate.toISOString()
				: undefined,
			end: monthDate
				? isAfter(lastWeekEnd, endOfYearDate)
					? lastWeekEnd.toISOString()
					: endOfYearDate.toISOString()
				: undefined,
		},
		useQueryOptions: {
			enabled: !!monthDate,
		},
	});

	const holidays = useMemo(() => holidayData?.data ?? {}, [holidayData]);

	const weeks = useMemo(
		() =>
			monthDate && holidays
				? getMonthWeeks(monthDate).map(({weekNumber, start, end}) => {
						const holidaysThisWeek = Object.entries(holidays)
							.filter(([holidayDate]) =>
								isSameWeek(new Date(holidayDate), new Date(start)),
							)
							.map(([holidayDate, label]) => ({
								date: new Date(holidayDate),
								dayOfWeek: getDay(new Date(holidayDate)),
								label,
							}))
							.filter(({dayOfWeek}) => dayOfWeek >= 1 && dayOfWeek <= 5);

						const days = eachDayOfInterval({
							start,
							end,
						})
							// TODO: make effective days configurable
							.slice(0, 5)
							.map(day => {
								const dayOfWeek = getDay(day);
								const isHoliday = !!holidaysThisWeek.find(h => h.dayOfWeek === dayOfWeek);
								const isThisMonthDay = isSameMonth(monthDate, day);
								const isNextMonthDay = isSameMonth(addMonths(monthDate, 1), day);
								const isPreviousMonthDay = isSameMonth(subMonths(monthDate, 1), day);

								return {
									date: day,
									dayOfWeek,
									isHoliday,
									isThisMonthDay,
									isNextMonthDay,
									isPreviousMonthDay,
								};
							});

						return {
							weekNumber,
							start,
							end,
							isCurrentWeek: isSameWeek(new Date(), new Date(start)),
							sameMonthDays: days.filter(d => d.isThisMonthDay && !d.isHoliday),
							days,
							holidays: holidaysThisWeek,
						};
				  })
				: [],
		[date, holidays],
	);

	return weeks;
};
