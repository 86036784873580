import {useApi} from 'hooks/api/useApi';

export const apiResourceName = 'organization';
export const apiPath = '/organizations';

export const useFetchOrganization = () => {
	const {get} = useApi();

	const index = () => get(apiPath, {});
	const byId = ({organizationId}) => get(`${apiPath}/${organizationId}`);

	return {
		index,
		byId,
	};
};
