// core dependencies
import React from 'react';
import styled from 'styled-components';
import {reduxForm, change} from 'redux-form';
import PropTypes from 'prop-types';
import {connect, useSelector, useDispatch} from 'react-redux';
import {compose, groupBy, toPairs} from 'ramda';
import {organizations} from 'modules/usersApp/common/selectors';
import {tags} from 'modules/usersApp/tagsPage/selectors';
// custom helpers & utils
import {applyDispatch, applyState} from 'utils/redux';
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import msgs from 'dicts/messages';
// components
import Input from 'components/generic/Input';
import Field from 'components/generic/Field';
import ReactSelect from 'components/generic/ReactSelect';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import FormField from 'components/generic/FormField';
import FieldSet from 'components/generic/FieldSet';
import Legend from 'components/generic/Legend';
import SpacedInputs from 'components/generic/SpacedInputs';
import {tagTypes} from 'dicts/tags';

import {appNames} from 'modules/common/constants';
import {appName as d2dApp} from 'modules/d2dApp/constants';
import {appName as salesApp} from 'modules/salesApp/constants';
import {appName as callsApp} from 'modules/callsApp/constants';

const viewTypeKeys = [d2dApp, salesApp, callsApp];

const StyledColorPickerContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	margin-top: 5px;
`;

const StyledColorInput = styled(Input)`
	width: 30px;
	height: 30px;
	border: none;
	padding: 0;
	background-color: none;
	border-radius: 6px;
`;

export const TAG_FORM = 'tagForm';

function validate(values, {tags = [], intl, isNew}) {
	const existingTags = tags;

	const requiredMsg = intl.formatMessage({id: msgs.requiredField});
	let errors = {};

	if (!values.name) errors.name = requiredMsg;
	if (!values.type) errors.type = requiredMsg;

	if (
		isNew &&
		values.name &&
		values.type &&
		existingTags.length &&
		existingTags.find(t => t.name === values.name && t.type === values.type)
	) {
		errors.name = intl.formatMessage({id: 'Tag already exists'});
		errors.type = intl.formatMessage({id: 'Tag already exists'});
	}

	return errors;
}

const TagForm = ({
	intl,
	handleSubmit,
	isNew,
	organizations,
	searchUsers,
	searchTeams,
}) => {
	const [isCustomColorUsed, setIsCustomColorUsed] = React.useState(false);
	const [selectedColor, setSelectedColor] = React.useState(null);

	// Workaround for async select
	// By default if just plugging 'search*' into loadOptions additional selections won't have any values to select from..
	const [_availableUsers, setAvailableUsers] = React.useState([]);

	const availableUsers = _availableUsers.map(u => ({
		value: u.id,
		label: `${u.firstName} ${u.lastName}`,
	}));

	const [availableTeams, setAvailableTeams] = React.useState([]);
	const availableTeamsByOrg = Object.entries(
		groupBy(t => t.organization.title, availableTeams),
	).map(t => {
		return {
			label: t[0],
			options: toPairs(t[1]).map(([key, team]) => ({
				value: team.id,
				label: team.title,
			})),
		};
	});

	const dispatch = useDispatch();

	const showOnMap = useSelector(
		state => state.form[TAG_FORM]?.values?.showOnMap || false,
	);
	const mapColor = useSelector(state => state.form[TAG_FORM]?.values?.mapColor || null);

	// Set initial state values on mount
	React.useEffect(() => {
		setIsCustomColorUsed(mapColor !== '' && mapColor !== null);
		setSelectedColor(mapColor);
	}, []);

	// mapColor field is updated when selectedColor or isCustomColorUsed changes
	React.useEffect(() => {
		const value = isCustomColorUsed ? selectedColor : null;
		dispatch(change(TAG_FORM, 'mapColor', value));
	}, [selectedColor, isCustomColorUsed, dispatch]);

	const isColorPickerVisible = React.useMemo(() => {
		if (!showOnMap || showOnMap === 0) return false;
		if (!isCustomColorUsed) return false;
		return true;
	}, [showOnMap, isCustomColorUsed]);

	return (
		<form id={TAG_FORM} onSubmit={handleSubmit}>
			<FieldSet noTopMargin noBottomMargin>
				<Field
					required
					name="name"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Name" />
							</Label>
							<Input {...input} id={inputId} meta={meta} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					name="type"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Type" />
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								meta={meta}
								block
								options={Object.keys(tagTypes).map(key => ({
									value: key,
									label: intl.formatMessage({id: tagTypes[key]}),
								}))}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					name="views"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="View" /> (
								<FormattedMessage id="Restrict to chosen options" />)
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								meta={meta}
								block
								isMulti
								closeMenuOnSelect={false}
								options={viewTypeKeys.map(key => ({
									value: key,
									label: intl.formatMessage({id: appNames[key]}),
								}))}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					name="users"
					props={{availableUsers, setAvailableUsers}}
					component={({input, inputId, availableUsers, setAvailableUsers}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Users" /> (
								<FormattedMessage id="Restrict to chosen users" />)
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								block
								useOptionsAsValues
								isMulti
								options={availableUsers}
								onFocus={() => {
									if (!availableUsers.length) {
										// Fetch tags on focus
										searchUsers({
											text: '',
											callback: users => {
												setAvailableUsers(users);
											},
										});
									}
								}}
							/>
						</FormBlock>
					)}
				/>

				<Field
					name="teams"
					props={{availableTeamsByOrg, setAvailableTeams}}
					component={({input, inputId, availableTeamsByOrg, setAvailableTeams}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Teams" /> (
								<FormattedMessage id="Restrict to chosen teams" />)
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								block
								useOptionsAsValues
								isMulti
								options={availableTeamsByOrg}
								onFocus={() => {
									if (!availableTeamsByOrg.length) {
										searchTeams({
											text: '',
											callback: users => {
												setAvailableTeams(users);
											},
										});
									}
								}}
							/>
						</FormBlock>
					)}
				/>

				<Field
					name={`showOnMap`}
					type="checkbox"
					component={({input, inputId}) => (
						<FormField block>
							<Input {...input} id={inputId} />
							<Label htmlFor={inputId}>
								&nbsp;{intl.formatMessage({id: 'Show on map'})}
							</Label>
						</FormField>
					)}
				/>

				{showOnMap === true || showOnMap === 1 ? (
					<FormField block>
						<Input
							id="useCustomColor"
							type="checkbox"
							checked={isCustomColorUsed}
							onChange={() => {
								setIsCustomColorUsed(isCustomColorUsed => !isCustomColorUsed);
								if (!selectedColor) {
									setSelectedColor('#000000');
								}
							}}
						/>
						<Label htmlFor="useCustomColor">
							&nbsp;{intl.formatMessage({id: 'Use custom color'})}
						</Label>
					</FormField>
				) : null}

				{isColorPickerVisible ? (
					<StyledColorPickerContainer>
						<StyledColorInput
							type="color"
							value={selectedColor}
							onChange={e => setSelectedColor(e.target.value)}
						/>
						<Field
							name={`mapColor`}
							component={({input, inputId}) => (
								<FormField>
									<Input type="hidden" {...input} id={inputId} defaultValue="#000000" />
								</FormField>
							)}
						/>
						<div>{mapColor ?? '#000000'}</div>
					</StyledColorPickerContainer>
				) : null}

				<FieldSet>
					<Legend bottomSpacing>
						<FormattedMessage id="Organizations" />
					</Legend>
					<SpacedInputs>
						{organizations.map(org => (
							<Field
								key={org.id}
								name={`organizations.${org.id}`}
								type="checkbox"
								component={({input, inputId}) => (
									<FormField block>
										<Input {...input} id={inputId} defaultValue={0} />
										<Label htmlFor={inputId}>&nbsp;{org.title}</Label>
									</FormField>
								)}
							/>
						))}
					</SpacedInputs>
				</FieldSet>
			</FieldSet>
		</form>
	);
};

TagForm.propTypes = {
	intl: PropTypes.object.isRequired,
	isNew: PropTypes.bool.isRequired,
	organizations: PropTypes.array.isRequired,
	tags: PropTypes.array,
};

export default compose(
	injectIntl,
	connect(
		applyState({
			organizations,
			tags,
		}),
		applyDispatch({}),
	),
	reduxForm({
		form: TAG_FORM,
		validate,
	}),
)(TagForm);
