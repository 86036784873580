import {useFetchUser, apiResourceName} from './useFetchUser';
import {useQuery} from '@tanstack/react-query';

export const queryKeyUserIndex = params => [apiResourceName, 'index', params];
export const queryKeyUserById = params => [apiResourceName, 'byId', params];
export const queryKeyUserMe = [apiResourceName, 'me'];

export const useQueryUserIndex = ({params, useQueryOptions}) => {
	const {index} = useFetchUser();
	return useQuery({
		queryKey: queryKeyUserIndex(params),
		queryFn: () => index(params),
		staleTime: 60 * 1000,
		...useQueryOptions,
	});
};

export const useQueryUserById = ({params, useQueryOptions}) => {
	const {byId} = useFetchUser();
	return useQuery({
		queryKey: queryKeyUserById(params),
		queryFn: () => byId(params),
		...useQueryOptions,
	});
};

export const useQueryUserMe = ({useQueryOptions}) => {
	const {me} = useFetchUser();
	return useQuery({
		queryKey: queryKeyUserMe,
		queryFn: () => me(),
		...useQueryOptions,
	});
};
