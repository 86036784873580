import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {user} from 'modules/common/selectors';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const callLogs = pipe(root, prop('callLogs'));

export const timeEntries = pipe(root, prop('timeEntries'));

export const users = pipe(root, prop('users'));

export const organizations = pipe(root, prop('organizations'));

export const searchQuery = pipe(root, prop('searchQuery'));

export const _page = pipe(root, prop('_page'));

export const teams = pipe(root, prop('teams'));

export const teamType = pipe(root, prop('teamType'));

export const trackingData = pipe(root, prop('trackingData'));

export const cities = pipe(root, prop('cities'));

export const processing = pipe(root, prop('processing'));

export const encounterables = pipe(root, prop('encounterables'));

export const canReadEncounterable = createSelector(
	[user],
	user => user && perms.canReadEncounterable(user),
);

export const canReadTeamsEncounterable = createSelector(
	[user],
	user => user && perms.canReadTeamsEncounterable(user),
);

export const canReadAnyEncounterable = createSelector(
	[user],
	user => user && perms.canReadAnyEncounterable(user),
);
