import React, {useState} from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import {TeamTargets} from './TeamTargets';
import {OrganizationBudget} from './OrganizationBudget';
import {useAuth} from 'hooks/useAuth';

const TargetEditor = ({initialTab}) => {
	const {user} = useAuth();
	const canShowBudget = !!user?.permissions.find(p => p.slug === 'budget.show');
	const canEditDashboardTargets = !!user?.permissions.find(
		p => p.slug === 'users.dashboard-targets',
	);

	const mayUseInitialTab = canEditDashboardTargets && canShowBudget;
	const initiallyActiveTab = mayUseInitialTab
		? initialTab
		: canEditDashboardTargets
		? 'targets'
		: 'budget';
	const [activeTab, setActiveTab] = useState(initiallyActiveTab);

	return (
		<div className="flex flex-col gap-6 -mt-4">
			<div className="flex gap-2 border-b">
				{canEditDashboardTargets && (
					<button
						onClick={() => setActiveTab('targets')}
						className={`px-4 py-2 font-medium text-sm border-b-2 -mb-px ${
							activeTab === 'targets'
								? 'text-gray-800 border-gray-800'
								: 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
						}`}
					>
						<FormattedMessage id="Weekly targets" />
					</button>
				)}
				{canShowBudget && (
					<button
						onClick={() => setActiveTab('budget')}
						className={`px-4 py-2 font-medium text-sm border-b-2 -mb-px ${
							activeTab === 'budget'
								? 'text-gray-800 border-gray-800'
								: 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
						}`}
					>
						<FormattedMessage id="Budget" />
					</button>
				)}
			</div>
			<div className="px-4">
				{activeTab === 'targets' && canEditDashboardTargets && <TeamTargets />}
				{activeTab === 'budget' && canShowBudget && <OrganizationBudget />}
			</div>
		</div>
	);
};

export default TargetEditor;
