import React from 'react';
import {Calendar1} from 'lucide-react';
import MonthSelector from 'components/generic/MonthSelector';

const MonthPicker = ({month, onMonthChange}) => {
	return (
		<div className="flex gap-3 items-center">
			<Calendar1 className="size-5 text-gray-400" />
			<MonthSelector value={month} onChange={onMonthChange} className="w-56" />
		</div>
	);
};

export default MonthPicker;
