import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useFetchTarget, apiResourceName} from './useFetchTarget';

export const useMutationTargetBatch = () => {
	const {batch: mutationFn} = useFetchTarget();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn,
		onMutate() {
			queryClient.cancelQueries({queryKey: [apiResourceName]});
		},
		onSettled() {
			queryClient.invalidateQueries({queryKey: [apiResourceName]});
		},
	});
};

export const useMutationCreateTarget = () => {
	const {create: mutationFn} = useFetchTarget();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn,
		onMutate() {
			queryClient.cancelQueries({queryKey: [apiResourceName]});
		},
		onSettled() {
			queryClient.invalidateQueries({queryKey: [apiResourceName]});
		},
	});
};
