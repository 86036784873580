import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ChevronLeft, ChevronRight} from 'lucide-react';
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import {capitalize} from 'utils/strings';

const formatDate = date => `${date.year}-${String(date.month + 1).padStart(2, '0')}`;

const MonthSelector = ({value, onChange, className, intl}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(() => {
		if (value) {
			const [year, month] = value.split('-');
			return {year: parseInt(year), month: parseInt(month) - 1};
		}
		const now = new Date();
		return {year: now.getFullYear(), month: now.getMonth()};
	});

	useEffect(() => {
		if (selectedDate) {
			onChange(formatDate(selectedDate));
		}
	}, [selectedDate, onChange]);

	useEffect(() => {
		if (value) {
			const [year, month] = value.split('-');
			const newYear = parseInt(year);
			const newMonth = parseInt(month) - 1;

			// Only update if values are different
			setSelectedDate(prev => {
				if (prev.year === newYear && prev.month === newMonth) {
					return prev;
				}
				return {year: newYear, month: newMonth};
			});
		}
	}, [value]);

	useEffect(() => {
		const handleClickOutside = event => {
			if (!event.target.closest('.month-selector')) {
				setIsOpen(false);
			}
		};

		document.addEventListener('click', handleClickOutside);
		return () => document.removeEventListener('click', handleClickOutside);
	}, []);

	const handlePrevMonth = () => {
		setSelectedDate(prev => {
			const newDate = {...prev};
			if (newDate.month === 0) {
				newDate.month = 11;
				newDate.year--;
			} else {
				newDate.month--;
			}
			return newDate;
		});
	};

	const handleNextMonth = () => {
		setSelectedDate(prev => {
			const newDate = {...prev};
			if (newDate.month === 11) {
				newDate.month = 0;
				newDate.year++;
			} else {
				newDate.month++;
			}
			return newDate;
		});
	};

	const handleYearChange = e => {
		setSelectedDate(prev => ({...prev, year: parseInt(e.target.value)}));
	};

	const handleMonthSelect = monthIndex => {
		setSelectedDate(prev => ({...prev, month: monthIndex}));
		setIsOpen(false);
	};

	const handleCurrentMonth = () => {
		const now = new Date();
		setSelectedDate({
			year: now.getFullYear(),
			month: now.getMonth(),
		});
		setIsOpen(false);
	};

	const formatMonth = date => {
		return capitalize(intl.formatDate(date, {month: 'long'}));
	};

	return (
		<div className={`relative month-selector ${className || ''}`}>
			<div className="flex h-8 items-center border rounded-md shadow-sm bg-white">
				<button
					type="button"
					onClick={handlePrevMonth}
					className="px-2 h-full flex items-center justify-center hover:bg-gray-100 rounded-l-md border-r"
				>
					<ChevronLeft className="size-4 text-gray-600" />
				</button>

				<div className="flex-1 h-full">
					<button
						type="button"
						onClick={() => setIsOpen(!isOpen)}
						className="w-full font-medium px-4 h-full flex items-center justify-center text-xs hover:bg-gray-50 focus:outline-none"
					>
						{formatMonth(new Date(selectedDate.year, selectedDate.month))}{' '}
						{selectedDate.year}
					</button>

					{isOpen && (
						<div className="absolute z-10 w-64 mt-1 bg-white border rounded-md shadow-lg">
							<div className="p-2 border-b">
								<select
									value={selectedDate.year}
									onChange={handleYearChange}
									className="w-full h-6 font-medium text-xs border rounded"
								>
									{Array.from({length: 21}, (_, i) => {
										const year = new Date().getFullYear() - 10 + i;
										return (
											<option key={year} value={year}>
												{year}
											</option>
										);
									})}
								</select>
							</div>
							<div className="grid grid-cols-3 gap-1 p-2">
								{Array.from({length: 12}, (_, i) => {
									const monthDate = new Date(2024, i);
									return (
										<button
											key={i}
											onClick={() => handleMonthSelect(i)}
											className={`h-6 px-2 text-xs font-medium rounded hover:bg-gray-100 ${
												selectedDate.month === i
													? 'bg-teal-50 text-teal-700 hover:bg-teal-50 cursor-default'
													: ''
											}`}
										>
											{formatMonth(monthDate)}
										</button>
									);
								})}
							</div>
							<div className="p-2 border-t">
								<button
									onClick={handleCurrentMonth}
									className="w-full h-6 text-xs font-medium text-gray-700 rounded hover:bg-gray-100"
								>
									<FormattedMessage id="This month" />
								</button>
							</div>
						</div>
					)}
				</div>

				<button
					type="button"
					onClick={handleNextMonth}
					className="px-2 h-full flex items-center justify-center hover:bg-gray-100 rounded-r-md border-l"
				>
					<ChevronRight className="size-4 text-gray-600" />
				</button>
			</div>
		</div>
	);
};

MonthSelector.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	intl: PropTypes.object.isRequired,
};

export default injectIntl(MonthSelector);
