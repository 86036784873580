import namespace from './namespace';
import {effect} from 'utils/redux';
import services from 'services';
import {catchNonFatalDefault, logInfo} from 'io/errors';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import {
	attachTag as attachTagIo,
	detachTag as detachTagIo,
	getBuildingsTags,
	getTags as getAvailableTags,
} from 'modules/common/io';
import * as actions from './actions';
import * as confirmerActions from 'modules/confirmer/actions';
import * as rootSelectors from 'modules/common/selectors';
import * as rootActions from 'modules/common/actions';
import {
	getInitData,
	getEncounters,
	getFreeCalendarResources,
	getSalesmen,
	getEncounter,
	putCalendarResource,
	postSalesmanVisit,
	putSalesmanVisit,
	postComment,
	putComment,
	deleteComment,
	postClient,
	putClient,
	postCallLog,
	getSalesmanVisits,
} from './io';
import {verifyOrg} from './utils';
import {appName} from '../constants';
import {P} from 'utils/types';
import {describeError} from 'utils/errors';
import msgs from 'dicts/messages';
import {change, blur} from 'redux-form';
import createDatePickEffects from 'fragments/calendarResourcePicker/effects';
import createCallReminderEffects from 'fragments/callReminder/effects';
import {bindToCalendarResourceReservationEvents} from 'fragments/calendarResourcePicker/effectHelpers';
import {initializeCallReminder} from 'fragments/callReminder/effectHelpers';
import {getReferrerUrl} from 'utils/url';
import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import {postCallReminder} from 'fragments/callReminder/io';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));
let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const history = services.get('history');

// simple local state

let st_previousOrganizationId = null;

const setupChannels = (getState, dispatch) => {
	const pusher = services.get('pusher');
	const user = rootSelectors.user(getState());
	const calendarResourcesChannel = pusher.subscribe(
		createTopic('calendarResource', user.accountId),
	);

	bindToCalendarResourceReservationEvents({actions, calendarResourcesChannel, user})(
		getState,
		dispatch,
	);
};

const clearChannels = (getState, dispatch) => {
	const user = rootSelectors.user(getState());
	if (!user) {
		// User not available in store (e.g. logged out), disconnect from pusher
		pusher.disconnect();
		return;
	}
	pusher.unsubscribe(createTopic('calendarResource', user.accountId));
};

const bail = msg => (getState, dispatch) => {
	const userMsg = intl.formatMessage(
		{id: 'Error: {errMsg}\nReturn to main menu?'},
		{errMsg: msg},
	);

	dispatch(
		confirmerActions.show({
			message: userMsg,
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: () => history.push('/'),
		}),
	);

	const error = describeError(userMsg, new Error('Init failed.'));
	logInfo(error);

	return Promise.reject(error);
};

const fetchRestInitData =
	({calendarResource: resource, buildingId, user, fetchSalesmanVisits}) =>
	(getState, dispatch) =>
		Promise.all([
			// fetch salesmen separately ASAP since the form won't render before
			getSalesmen(resource.organization.id).then(data => {
				dispatch(actions._setOrganizationSalesmen(data));
			}),
			getFreeCalendarResources(buildingId, user.id).then(data => {
				dispatch(actions._setFreeCalendarResources(data));
			}),
			getEncounters(buildingId).then(encounters => {
				dispatch(actions._setEncounters(encounters));
			}),
			initializeCallReminder({buildingId, actions})(getState, dispatch),
			getAvailableTags({
				type: TYPE_BUILDING,
				getAllTags: false,
				view: appName,
			}).then(({data: tags}) => {
				dispatch(actions._setAvailableTags(tags));
			}),
			fetchSalesmanVisits
				? getSalesmanVisits({buildingId}).then(salesmanVisits =>
						dispatch(actions._setSalesmanVisits(salesmanVisits)),
				  )
				: Promise.resolve(null),
		]);

export let initialize = id => (getState, dispatch) => {
	setPageTitleMessage('Sales [app]')(getState, dispatch);

	const user = rootSelectors.user(getState());

	setupChannels(getState, dispatch);

	ensureAccess(appName, user)(getState, dispatch)
		.then(() => {
			if (!id) {
				return bail(intl.formatMessage({id: 'Invalid address.'}))(getState, dispatch);
			}
		})
		.then(() =>
			decorateWithNotifications(
				{id: 'get-calendar-resource', failureStyle: 'warning'},
				getInitData(id),
			)(getState, dispatch),
		)
		.then(resource => {
			if (!resource.reservation) {
				return bail(
					intl.formatMessage({
						id: 'The requested calendar entry is not reserved for any building.',
					}),
				)(getState, dispatch);
			}

			const user = rootSelectors.user(getState());
			const activeOrg = rootSelectors.activeOrganization(getState());

			const resourceOrg = resource.organization;

			if (!verifyOrg(resourceOrg.id, user)) {
				return bail(
					intl.formatMessage({
						id: 'You do not have access to the application.',
					}),
				)(getState, dispatch);
			}

			if (resourceOrg.id !== activeOrg.id) {
				// store prev org id so that it can be restored when leaving
				st_previousOrganizationId = activeOrg.id;

				dispatch(rootActions.setOrganizationSilently(resourceOrg.id));
			}

			dispatch(
				actions._setInitData({
					calendarResource: resource,
					salesmanVisit: resource.salesmanVisit || null,
					building: resource.reservation.encounter.building,
				}),
			);

			const buildingId = resource.reservation.encounter.building.id;
			const fetchSalesmanVisits = !!resourceOrg.meta?.customContactHistory;

			dispatch(actions.getTags(buildingId));

			return decorateWithNotifications(
				{id: 'rest-init-data', failureStyle: 'warning'},
				fetchRestInitData({
					calendarResource: resource,
					buildingId,
					user,
					fetchSalesmanVisits,
				})(getState, dispatch),
			)(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize, P.Number);

export let fetchEncounter = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-encounter',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(encounter => {
			dispatch(actions._opOk());

			dispatch(actions._openEncounterPreviewModal(encounter.data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchEncounter = creator('fetchEncounter', fetchEncounter);

export let updateCalendarResource = calRes => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'save-calendar-resource',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		putCalendarResource(calRes),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(calRes => {
			dispatch(actions._calendarResourceUpdated(calRes));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarResource = creator('updateCalendarResource', updateCalendarResource);

export let saveSalesmanVisit =
	({isNew, salesmanVisit, callReminder}) =>
	(getState, dispatch) => {
		const referrerUrl = getReferrerUrl(history.location.search);
		const evt = isNew ? postSalesmanVisit : putSalesmanVisit;

		if (!salesmanVisit.clientId) {
			dispatch(
				nActions.warning({
					id: 'form-submit-fail',
					message: intl.formatMessage({id: 'Select client'}),
					duration: medDur,
				}),
			);
			dispatch(actions._opFailed());
			return;
		}

		decorateWithNotifications(
			{
				id: 'save-salesmanVisit',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Visit saved'}),
			},
			Promise.all([
				evt(salesmanVisit).then(data => {
					dispatch(actions._salesmanVisitSaved(data));
					return Promise.resolve(null);
				}),
				callReminder ? postCallReminder(callReminder) : Promise.resolve(null),
			]),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				if (referrerUrl) history.push(referrerUrl);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveSalesmanVisit = creator('saveSalesmanVisit', saveSalesmanVisit);

export let destroy = () => (getState, dispatch) => {
	const activeOrgId = rootSelectors.activeOrganizationId(getState());
	if (st_previousOrganizationId && st_previousOrganizationId !== activeOrgId) {
		dispatch(rootActions.setOrganizationSilently(st_previousOrganizationId));
	}
	// reset ASAP
	st_previousOrganizationId = null;

	clearChannels(getState, dispatch);
};
destroy = creator('destroy', destroy);

export let saveClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			!buildingId ? putClient(client) : postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(client => {
				dispatch(
					actions._clientUpdated({
						client: client.data,
						type: !buildingId ? 'update' : 'add',
					}),
				);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveClient = creator('saveClient', saveClient);

export let callLog = data => (getState, dispatch) => {
	postCallLog(data);
};

// comments, TEMP
export let createComment =
	({data, id}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'post-comment',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			id ? putComment(data, id) : postComment(data),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(comment => {
				dispatch(actions._commentSaved(comment));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createComment = creator('createComment', createComment);

export let removeComment = id => (getState, dispatch) => {
	const onConfirm = () => {
		decorateWithNotifications(
			{
				id: 'delete-comment',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Comment deleted'}),
			},
			deleteComment(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._commentRemoved(id));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete comment?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeComment = creator('removeComment', removeComment);
// ...

const datePickEffects = createDatePickEffects({
	namespace,
	actions,
	selectResource: dateId => (getState, dispatch) => {
		dispatch(change('salesmanVisitForm', 'newCalendarResourceId', dateId));
		// this does maybe something important with redux-form, not sure what
		setTimeout(() => {
			dispatch(blur('salesmanVisitForm', 'newCalendarResourceId'));
		});
	},
});

export const {selectCalendarResource} = datePickEffects;

const callReminderEffects = createCallReminderEffects({
	namespace,
	actions,
});

export const {saveCallReminder, removeCallReminder} = callReminderEffects;

export let getTags = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-tags',
			failureStyle: 'warning',
		},
		getBuildingsTags(id),
	)(getState, dispatch)
		.then(tags => {
			dispatch(actions._getTags(tags));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getTags = creator('getTags', getTags);

export let attachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'attach-tag',
			failureStyle: 'error',
		},
		attachTagIo(params),
	)(getState, dispatch)
		.then(() => dispatch(actions._attachTag()))
		.then(() => dispatch(actions.getTags(buildingId)))
		.catch(catchNonFatalDefault);
};

attachTag = creator('attachTag', attachTag);

export let detachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'detach-tag',
			failureStyle: 'error',
		},
		detachTagIo(params),
	)(getState, dispatch)
		.catch(catchNonFatalDefault)
		.then(() => dispatch(actions._detachTag()))
		.then(() => dispatch(actions.getTags(buildingId)));
};

detachTag = creator('detachTag', detachTag);
