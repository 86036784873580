import {scopedHandler} from 'utils/redux';
import {isEmpty} from 'ramda';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.reinitialize.type: {
			return [state, effects.initialize()];
		}

		case actions.afterLogin.type: {
			return [{...state, apiToken: payload}, effects.afterLogin(payload)];
		}

		case actions.setOrganization.type: {
			return [state, effects.setOrganization(payload)];
		}

		case actions.setOrganizationSilently.type: {
			return [{...state, activeOrganizationId: payload}, null];
		}

		case actions.setOrganizationChangeRedirect.type: {
			return [{...state, organizationChangeRedirect: payload}, null];
		}

		case actions.logout.type: {
			return [{...state, apiToken: null, user: null}, effects.clearLoginData()];
		}

		case actions.setNoticeSeen.type: {
			return [
				{...state, personalNoticesProcessing: true},
				effects.setNoticeSeen(payload),
			];
		}

		case actions.setAllNoticesSeen.type: {
			return [{...state, personalNoticesProcessing: true}, effects.setAllNoticesSeen()];
		}

		case actions.impersonateUser.type: {
			return [state, effects.impersonateUser(payload)];
		}

		case actions.openFeedbackModal.type: {
			return [{...state, feedbackModalOpen: true}, null];
		}

		case actions.closeFeedbackModal.type: {
			return [{...state, feedbackModalOpen: false}, null];
		}

		case actions.createFeedback.type: {
			const form = getFormValues('feedbackForm')(fullState);
			const {view, subView, ...rest} = form;
			const feedback = {...rest, view: view && subView ? subView : view ? view : null};

			return [{...state, feedbackLoading: true}, effects.createFeedback(feedback)];
		}

		case actions.expandLeaddeskTalk.type: {
			return [{...state, leaddeskTalkExpanded: true}, null];
		}

		case actions.closeLeaddeskTalk.type: {
			return [{...state, leaddeskTalkExpanded: false}, null];
		}

		case actions._feedbackSaved.type: {
			return [{...state, feedbackLoading: false, feedbackModalOpen: false}, null];
		}

		case actions._stopProcessingFeedback.type: {
			return [{...state, feedbackLoading: false}, null];
		}

		case actions._setApiToken.type: {
			return [{...state, apiToken: payload, initialized: true}, null];
		}

		case actions._setPersonalNotices.type: {
			const {data, pagination, keepOld} = payload;
			const personalNotices = keepOld ? [...state.personalNotices, ...data] : data;
			const personalNoticesPagination = pagination;
			const newState = {
				...state,
				personalNotices,
				personalNoticesPagination,
				loadingPersonalNotices: false,
			};
			return [newState, null];
		}

		case actions.getMoreNotices.type: {
			const _page = payload;
			return [
				{...state, loadingPersonalNotices: true},
				effects.getPersonalNotices({_page}),
			];
		}

		case actions._addPersonalNotice.type: {
			return [
				{
					...state,
					personalNotices: state.personalNotices
						? [payload, ...state.personalNotices]
						: [payload],
				},
				null,
			];
		}

		case actions._setUser.type: {
			const {timeEntry, notices} = payload;

			//using userEntriesToday.restricted to let d2d and calls apps to know that we only know about activeTimeEntry and they need to show spinner while loading all timeEntries
			//no need to get all timeEntries before we navigate to d2d/calls dashboards
			const userEntriesToday = {
				...timeEntry,
				restricted: true,
			};

			const newState = {
				...state,
				user: payload,
				notices,
				activeTimerRunning: timeEntry.active,
				userEntriesToday,
			};

			return [newState, null];
		}

		case actions._setActiveOrganizationId.type: {
			return [{...state, activeOrganizationId: payload}, null];
		}

		case actions.setCallSession.type: {
			// TODO: these states should be constants
			const callSessionLogs =
				payload.status === 'Trying' ? [payload] : [...state.callSessionLogs, payload];

			return [
				{
					...state,
					callSession: payload,
					callSessionLogs,
				},
				null,
			];
		}

		case actions.markCallSessionTerminatedByUser.type: {
			const log = {
				...selectors.callSession(fullState),
				status: '__TERMINATED_BY_USER',
				active: false,
			};
			return [
				{
					...state,
					callSessionLogs: [...state.callSessionLogs, log],
				},
			];
		}

		case actions.flushCallSessionLogs.type: {
			return [{...state, callSessionLogs: []}, null];
		}

		case actions.toggleEnioCallerVisibility.type: {
			const toggledValue = !state.enioCallerVisible;
			return [{...state, enioCallerVisible: toggledValue}, null];
		}

		case actions.toggleSipCallerExpanded.type: {
			const toggledValue = !state.sipCallerExpanded;
			return [{...state, sipCallerExpanded: toggledValue}, null];
		}

		case actions.setDeviceId.type: {
			return [{...state, deviceId: payload}];
		}

		case actions._clearLoginData.type: {
			return [{...state, apiToken: null, user: null}, null];
		}

		case actions._setNoticeSeen.type: {
			const openedUnseenNotice = state.personalNotices.find(
				n => n.id === payload && !n.seen,
			);

			let {unseenPersonalNoticesCount} = state.personalNoticesPagination;
			let personalNotices;

			if (openedUnseenNotice) {
				unseenPersonalNoticesCount--;
				personalNotices = state.personalNotices.map(n => {
					return n.id === payload ? {...n, seen: 1} : n;
				});
			} else {
				personalNotices = state.personalNotices;
			}

			const personalNoticesPagination = {
				...state.personalNoticesPagination,
				unseenPersonalNoticesCount,
			};

			return [
				{
					...state,
					personalNotices,
					personalNoticesPagination,
					personalNoticesProcessing: false,
				},
				null,
			];
		}

		case actions._setAllNoticesSeen.type: {
			const personalNotices = state.personalNotices.map(n => {
				return {...n, seen: 1};
			});

			const personalNoticesPagination = {
				...state.personalNoticesPagination,
				unseenPersonalNoticesCount: 0,
			};

			return [
				{
					...state,
					personalNotices,
					personalNoticesPagination,
					personalNoticesProcessing: false,
				},
				null,
			];
		}

		case actions._setApiKeys.type: {
			return [{...state, apiKeys: payload}, null];
		}

		// time entry handlers
		// TODO need to make this to work with calendarApp/timeEntryPage timeEntry listing
		case actions.startTimer.type: {
			return [{...state, processingTimeEntries: true}, effects.startTimer(payload)];
		}

		// TODO need to make this to work with calendarApp/timeEntryPage timeEntry listing
		case actions.stopTimer.type: {
			return [{...state, processingTimeEntries: true}, effects.stopTimer()];
		}

		case actions.clearActiveTimer.type: {
			return [state, effects.clearActiveTimer()];
		}

		// note: this starts with "_" but fires effects?
		case actions._setTimeEntries.type: {
			const activeTimerRunning = !isEmpty(payload.active) ? payload.active.type : null;

			const newState = {
				...state,
				userEntriesToday: payload,
				processingTimeEntries: false,
				activeTimerRunning,
			};

			return [
				newState,
				activeTimerRunning ? effects.startActiveTimer() : effects.clearActiveTimer(),
			];
		}

		case actions._startTimer.type: {
			const activeTimerRunning = payload.active.type;
			return [{...state, activeTimerRunning, processingTimeEntries: false}, null];
		}

		case actions._stopTimer.type: {
			return [{...state, activeTimerRunning: null, processingTimeEntries: false}, null];
		}

		case actions._startProcessingTimeEntries.type: {
			return [{...state, processingTimeEntries: true}, null];
		}

		case actions._startTimerFromCalls.type: {
			return [{...state, processingTimeEntries: true}, effects.startTimer(payload)];
		}

		case actions._stopProcessingTimeEntries.type: {
			return [{...state, processingTimeEntries: false}, null];
		}

		case actions._updateTotalTime.type: {
			if (!state.activeTimerRunning) return [state, null];
			const {userEntriesToday, activeTimerRunning} = state;

			const _userEntriesToday = {
				...userEntriesToday,
				totalTimeDay: userEntriesToday.totalTimeDay + 60,
				reasons: {
					...userEntriesToday.reasons,
					[activeTimerRunning]: userEntriesToday.reasons[activeTimerRunning] + 60,
				},
			};

			return [{...state, userEntriesToday: _userEntriesToday}, null];
		}

		case actions._setActiveTimerId.type: {
			return [{...state, activeTimerId: payload}, null];
		}

		case actions.setOverviewSearchIds.type: {
			return [{...state, overviewSearchIds: payload}, null];
		}
		case actions.setOverviewSearchTeams.type: {
			return [{...state, overviewSearchTeams: payload}, null];
		}
		case actions._setEnioCallerDetails.type: {
			return [{...state, enioCallerCredentials: payload}, null];
		}
		case actions.updateUserMeta.type: {
			const userFromMeta = {...state.user};
			const newUser = {...userFromMeta, meta: payload};
			return [{...state, user: newUser}, null];
		}
		case actions.updateEnioCallerDetails.type: {
			return [{...state, enioCallerCredentials: payload}, null];
		}
		case actions.makeSipCall.type: {
			return [{...state}, effects.makeSipCall(payload)];
		}

		case actions.makeCall.type: {
			return [{...state}, effects.makeCall(payload)];
		}

		case actions.getActiveCallPools.type: {
			return [{...state, activeCallPoolsProcessing: true}, effects.getActiveCallPools()];
		}
		case actions._getActiveCallPools.type: {
			return [
				{
					...state,
					activeCallPools: payload,
					activeCallPoolsProcessing: false,
				},
				null,
			];
		}

		case actions.setReasonMappings.type: {
			return [
				{
					...state,
					reasonMappings: payload,
				},
				null,
			];
		}

		case actions.refreshDailyCallDuration.type: {
			return [
				{
					...state,
					dailyCallDurationInSeconds: null,
				},
				effects.refreshDailyCallDuration(),
			];
		}

		case actions._refreshDailyCallDuration.type: {
			return [
				{
					...state,
					dailyCallDurationInSeconds: payload,
				},
				null,
			];
		}

		case actions.searchProducts.type: {
			return [state, effects.searchProducts(payload)];
		}

		case actions.navigateToLeadInTeamCalendar.type: {
			return [
				{
					...state,
				},
				effects.navigateToLeadInTeamCalendar(payload),
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
})(handler);
