import {useState, useEffect, useMemo} from 'react';
import Cookies from 'js-cookie';
import {useQueryOrganizationById} from 'hooks/api/organization/useQueryOrganization';
import {useQueryUserMe} from 'hooks/api/user/useQueryUser';

export const useAuth = () => {
	const [apiToken, setApiToken] = useState(() => Cookies.get('api_token'));
	const [organizationId, setOrganizationId] = useState(() =>
		Cookies.get('organization_id'),
	);

	// current organization
	const {data: activeOrganizationData} = useQueryOrganizationById({
		params: {organizationId},
		useQueryOptions: {
			enabled: !!organizationId,
		},
	});
	const activeOrganization = useMemo(
		() => activeOrganizationData?.data,
		[activeOrganizationData],
	);

	// current user
	const {data: user} = useQueryUserMe({
		useQueryOptions: {
			enabled: !!organizationId,
		},
	});

	// Synchronizes auth cookies to state
	useEffect(() => {
		const syncAuthCookies = () => {
			const currentApiToken = Cookies.get('api_token');
			const currentOrganizationId = Cookies.get('organization_id');

			if (
				(currentOrganizationId && currentOrganizationId !== organizationId) ||
				(apiToken && apiToken !== currentApiToken)
			) {
				window.location.reload();
				return;
			}

			setApiToken(currentApiToken);
			setOrganizationId(currentOrganizationId);
		};

		const interval = setInterval(syncAuthCookies, 3000);

		const handleStorageEvent = () => syncAuthCookies();
		window.addEventListener('storage', handleStorageEvent);

		return () => {
			clearInterval(interval);
			window.removeEventListener('storage', handleStorageEvent);
		};
	}, []);

	return {apiToken, organizationId, activeOrganization, user};
};
