import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import * as perms from 'utils/perms';
import {
	reasonMappingTypeSalesmanVisit,
	reasonMappingTypeVisit,
	reasonMappingTypeCall,
} from './constants';

const root = path(namespace);

export const initialized = pipe(root, prop('initialized'));

export const apiToken = pipe(root, prop('apiToken'));

export const activeOrganizationId = pipe(root, prop('activeOrganizationId'));

export const callSession = pipe(root, prop('callSession'));

export const enioCallerVisible = pipe(root, prop('enioCallerVisible'));

export const sipCallerExpanded = pipe(root, prop('sipCallerExpanded'));

export const callSessionLogs = pipe(root, prop('callSessionLogs'));

export const isCallSessionNotAnswered = createSelector([callSessionLogs], logs => {
	// TODO: combine somic and eniocaller methods and constants under similar 'sipml-caller' interface tms
	const userEndedCall = !!logs.find(log => log.status === '__TERMINATED_BY_USER');

	const triedToCall = !!logs.find(log => log.status === 'Trying');
	const callTerminated = !!logs.find(log => log.status === 'Call terminating...');
	const clientIsBusy = !!logs.find(log => log.status === 'Busy Here');
	const notInCall = !!logs.find(log => log.status !== 'In Call');
	const serverErrored = !!logs.find(log => log.status === 'Server Time-out');

	const wasAnswered =
		triedToCall &&
		notInCall &&
		!userEndedCall &&
		(callTerminated || clientIsBusy || serverErrored);

	return wasAnswered;
});

// Very simplified check (will probably have some edge cases depending on use case) to see whether current callSession is ringing.
export const isCallSessionRinging = createSelector([callSessionLogs], logs => {
	// Session has to be ringing
	const isRinging = !!logs.find(log => log.status === 'Ringing');
	// Session cannot be 'In Call'
	const notInCall = logs.every(log => log.status !== 'In Call');
	return isRinging && notInCall;
});

export const deviceId = pipe(root, prop('deviceId'));

export const loggedIn = pipe(apiToken, Boolean);

export const user = pipe(root, prop('user'));
export const userDefaultCallClient = createSelector(user, user => user.defaultCall);

export const organizationChangeRedirect = pipe(root, prop('organizationChangeRedirect'));

export const activeOrganization = createSelector(
	[activeOrganizationId, user],
	(orgId, user) => (user ? user.organizations.find(o => o.id === orgId) : null),
);

export const personalNotices = pipe(root, prop('personalNotices'));

export const personalNoticesPagination = pipe(root, prop('personalNoticesPagination'));

export const loadingPersonalNotices = pipe(root, prop('loadingPersonalNotices'));

export const personalNoticesProcessing = pipe(root, prop('personalNoticesProcessing'));

export const leaddeskTalkExpanded = pipe(root, prop('leaddeskTalkExpanded'));

export const enioCallerCredentials = pipe(root, prop('enioCallerCredentials'));

export const apiKeys = pipe(root, prop('apiKeys'));

// these are offened for the convenient memoization and importing

// PERM: customerOrganization
export const customerOrganization = createSelector(
	activeOrganization,
	activeOrganization => activeOrganization.meta.customerOrganization,
);

export const isTeleUser = createSelector(user, perms.isTeleUser);

// PERM: userType
export const isSalesUser = createSelector(user, user =>
	user ? perms.isSalesUser(user) : false,
);

// PERM: not-sub-contractor
export const isSubcontractorUser = createSelector([user], user =>
	perms.isSubcontractorUser(user),
);

// PERM: role.salesmanager-user
export const isSalesmanagerUser = createSelector([user], user =>
	perms.isSalesmanagerUser(user),
);

export const isSalesLeaderUser = createSelector([user], user =>
	perms.isSalesLeader(user),
);

export const hasSalesmanAppSalesmanagerPerms = createSelector(
	[user],
	user => true, // temporary(?) change to allow callsApp users to use the app as salesmanager
	// perms.isSalesmanagerUser(user) ||
	// (user.meta && user.meta.ihSalesmanagerPermission)
);

export const isSalesmanUser = createSelector([user], user => perms.isSalesmanUser(user));

// PERM: map-create-issues
export const userCanCreateIssues = createSelector(user, user =>
	user ? perms.userCanCreateIssues(user) : false,
);

// PERM: create-time-entries
export const userCanCreateTimeEntries = createSelector(user, user =>
	user ? perms.userCanCreateTimeEntries(user) : false,
);

// PERM: delete-buildings
export const userCanDeleteBuildings = createSelector(user, user =>
	user ? perms.userCanDeleteBuildings(user) : false,
);

export const userAppPermissions = createSelector(user, user =>
	user ? perms.userAppPermissions(user) : null,
);

// PERM: view.buildings
export const userHasBuildingsAppPerms = createSelector(user, user =>
	user ? perms.userAppPermissions(user).has('buildings') : false,
);

export const feedbackModalOpen = pipe(root, prop('feedbackModalOpen'));

export const feedbackFormInitValues = pipe(root, prop('feedbackFormInitValues'));

export const feedbackLoading = pipe(root, prop('feedbackLoading'));

// PERM: role.pilot
export const isPilotUser = createSelector(user, user =>
	user ? perms.isPilotUser(user) : false,
);

// PERM: admin-user-management
export const isAdminUser = createSelector(user, user =>
	user ? perms.isAdminUser(user) : false,
);

export const usersAppRolesPageAccess = createSelector(user, user =>
	user ? perms.usersAppRolesPageAccess(user) : false,
);

export const usersAppOrganizationsPageAccess = createSelector(user, user =>
	user ? perms.usersAppOrganizationsPageAccess(user) : false,
);

export const unseenNoticesCount = createSelector(personalNoticesPagination, pagination =>
	pagination ? pagination.unseenPersonalNoticesCount : 0,
);

export const showBuildingEncounters = createSelector(user, user =>
	user ? perms.showBuildingEncounters(user) : false,
);

export const usersAppUserPermissionAccess = createSelector(user, user =>
	user ? perms.usersAppUserPermissionAccess(user) : false,
);

export const buildingsAppRemovalRequestsPageAccess = createSelector(user, user =>
	user ? perms.buildingsAppRemovalRequestsPageAccess(user) : false,
);

export const buildingsAppShowCsvImporterAccess = createSelector(user, user =>
	user ? perms.buildingsAppShowCsvImporterAccess(user) : false,
);

export const buildingsAppDeleteCsvImportClientsAccess = createSelector(user, user =>
	user ? perms.buildingsAppDeleteCsvImportClientsAccess(user) : false,
);

export const canViewLockedTeamCalendar = createSelector(user, user =>
	user ? perms.viewLockedTeamCalendar(user) : false,
);

export const showFacebookLeadsUpload = createSelector(user, user =>
	user ? perms.showFacebookLeadsUpload(user) : false,
);

export const showProfinderListsManagement = createSelector(user, user =>
	user ? perms.showProfinderListsManagement(user) : false,
);

export const canAddCalendarResourcesToAnyTeam = createSelector(user, user =>
	user ? perms.userCanAddCalendarResourcesToAnyTeam(user) : false,
);

export const canShowEncounterReason = createSelector(user, user =>
	user ? perms.canShowEncounterReason(user) : false,
);

// time entry selectors

export const userEntriesToday = pipe(root, prop('userEntriesToday'));

export const processingTimeEntries = pipe(root, prop('processingTimeEntries'));

export const activeTimerRunning = pipe(root, prop('activeTimerRunning'));

export const activeTimerId = pipe(root, prop('activeTimerId'));

// Overview user IDs for search

export const overviewSearchIds = pipe(root, prop('overviewSearchIds'));

export const overviewSearchTeams = pipe(root, prop('overviewSearchTeams'));

export const reasonMappings = pipe(root, prop('reasonMappings'));

export const reasonMappingsForSalesmanVisit = createSelector(reasonMappings, mappings =>
	mappings.filter(m => m.type === reasonMappingTypeSalesmanVisit),
);
export const reasonMappingsForSalesmanVisitByStates = createSelector(
	reasonMappingsForSalesmanVisit,
	mappings =>
		mappings.reduce((acc, cur) => {
			const currentValues = acc[cur.state] || [];
			acc[cur.state] = [...currentValues, cur];
			return acc;
		}, {}),
);
export const reasonMappingsForVisits = createSelector(reasonMappings, mappings =>
	mappings.filter(m => m.type === reasonMappingTypeVisit),
);
export const reasonMappingsForCalls = createSelector(reasonMappings, mappings =>
	mappings.filter(m => m.type === reasonMappingTypeCall),
);

export const processingTag = pipe(root, prop('processingTag'));

export const activeCallPools = pipe(root, prop('activeCallPools'));

export const activeCallPoolId = pipe(root, prop('activeCallPoolId'));

export const activeCallPool = createSelector(
	[activeCallPoolId, activeCallPools],
	(id, pools) => {
		if (id) {
			return pools.find(p => p.id === id);
		}
		// Return undefined to maintain consistent return value with 'find'
		return undefined;
	},
);

export const activeCallPoolsProcessing = pipe(root, prop('activeCallPoolsProcessing'));

export const dailyCallDurationInSeconds = pipe(root, prop('dailyCallDurationInSeconds'));
