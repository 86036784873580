import {useFetchHoliday, apiResourceName} from './useFetchHoliday';
import {useQuery} from '@tanstack/react-query';

const staleTime = 60 * 1000 * 60 * 24; // day

export const useQueryHolidayIndex = ({params = {}, useQueryOptions = {}}) => {
	const {index} = useFetchHoliday();
	return useQuery({
		queryKey: [`${apiResourceName}`, 'index', params],
		queryFn: () => index(params),
		staleTime,
		...useQueryOptions,
	});
};
