import {FormattedMessage, FormattedNumber, injectIntl} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/PanelHeaderTitle';
import Panel from 'components/generic/Panel';
import React, {useState} from 'react';
import {compose} from 'redux';
import {currency} from 'constants/loc';
import {useStateDebounced} from 'hooks/useStateDebounced';
import Button from 'components/generic/Button';
import {Settings} from 'lucide-react';
import clsx from 'clsx';
import {useAuth} from 'hooks/useAuth';
import TargetEditorModal from 'views/TargetEditor/TargetEditorModal';

const TargetRow = ({title, value, target, formattingStyle}) => {
	const numberFormatOptions = {
		style: formattingStyle,
		...(formattingStyle === 'currency' ? {currency} : {}),
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	};

	// 0-100
	const percent = target === 0 && !!value ? 100 : parseInt((value / target) * 100) || 0;

	const progress = Math.min(
		100,
		target === 0 && !!value ? 100 : parseInt((value / target) * 100) || 0,
	);

	const barColor =
		progress < 15 ? 'bg-red-500' : progress < 70 ? 'bg-yellow-500' : 'bg-teal-500';

	// TODO create badge component with variants (class-variance-authority)
	const percentBgColor =
		progress < 15 ? 'bg-red-50' : progress < 70 ? 'bg-yellow-50' : 'bg-teal-50';

	const percentTextColor =
		progress < 15 ? 'text-red-600' : progress < 70 ? 'text-yellow-700' : 'text-teal-600';

	const barClasses = clsx(
		'absolute inset-0 h-1 bg-green-400 rounded-md w-40 transition-all transition-delay-300 ease-in-out duration-500',
		barColor,
	);

	const percentClasses = clsx(
		'rounded-lg px-2 py-0.5 font-medium',
		percentBgColor,
		percentTextColor,
	);

	// enable animations
	const percentDebounced = useStateDebounced(progress, 0, 300);

	// eslint does not allow the style prop to be other than an object
	const formatPercentOptions = {
		style: 'percent',
	};

	return (
		<div className="flex flex-col gap-1">
			<div className="flex justify-between items-center">
				<span className="font-medium">{title}</span>
				<div className="flex justify-end gap-5 items-center">
					<span className="text-zinc-500">
						<span>
							{formattingStyle === 'count' ? (
								<FormattedMessage id="{num} pcs." values={{num: value}} />
							) : (
								<FormattedNumber value={value} {...numberFormatOptions} />
							)}
						</span>
						<span className="mx-0.5">/</span>
						<span>
							{formattingStyle === 'count' ? (
								<FormattedMessage id="{num} pcs." values={{num: target}} />
							) : (
								<FormattedNumber value={target} {...numberFormatOptions} />
							)}
						</span>
					</span>
					<span className={percentClasses}>
						<FormattedNumber value={percent / 100} {...formatPercentOptions} />
					</span>
				</div>
			</div>
			<div className="h-1 bg-gray-200 w-full rounded-md relative">
				<div className={barClasses} style={{width: percentDebounced + '%'}} />
			</div>
		</div>
	);
};

const SalesTargets = ({data}) => (
	<div className="flex flex-col gap-4 px-2">
		{data?.map(({label, targets, dealsSum}, i) => (
			<TargetRow
				key={i}
				title={label}
				formattingStyle="currency"
				target={targets['dashboard-sales-sum']}
				value={dealsSum}
			/>
		))}
	</div>
);

const AllTargets = ({data}) => (
	<div className="flex flex-col gap-4">
		{data?.map(({label, targets, dealsSum, successfulVisits, offers}, i) => (
			<div key={i} className="flex flex-col gap-2">
				<span className="block text-center text-sm py-1 border-b border-b-zinc-100">
					{label}
				</span>
				<div className="flex flex-col gap-4 px-2">
					<TargetRow
						title={<FormattedMessage id="Sales" />}
						formattingStyle="currency"
						target={targets['dashboard-sales-sum']}
						value={dealsSum}
					/>
					<TargetRow
						title={<FormattedMessage id="Visits" />}
						formattingStyle="count"
						target={targets['dashboard-visits-count']}
						value={successfulVisits}
					/>
					<TargetRow
						title={<FormattedMessage id="Offers" />}
						formattingStyle="count"
						target={targets['dashboard-offers-count']}
						value={offers}
					/>
				</div>
			</div>
		))}
	</div>
);

const GoalTrackingPanel = ({data}) => {
	const {user} = useAuth();
	const canEditDashboardTargets = !!user?.permissions.find(
		p => p.slug === 'users.dashboard-targets',
	);
	const groupedBy = data?.[0]?.groupedBy;
	const [dashboardTargetsModalOpen, setDashboardTargetsModalOpen] = useState(false);

	return (
		<Panel
			className="!px-0"
			header={
				<div className="flex justify-between items-center">
					<PanelHeaderTitle>
						<FormattedMessage id="Goal tracking" />
					</PanelHeaderTitle>
					{canEditDashboardTargets && (
						<Button size="xs" onClick={() => setDashboardTargetsModalOpen(true)}>
							<Settings className="size-4" />
						</Button>
					)}
				</div>
			}
		>
			<div className="pt-4 pb-6 max-h-[480px] overflow-y-auto px-2 -mx-2">
				{groupedBy === 'user' ? <AllTargets data={data} /> : <SalesTargets data={data} />}
			</div>
			{canEditDashboardTargets && (
				<TargetEditorModal
					show={dashboardTargetsModalOpen}
					onClose={() => setDashboardTargetsModalOpen(false)}
					initialTab="targets"
				/>
			)}
		</Panel>
	);
};

export default compose(injectIntl)(GoalTrackingPanel);
