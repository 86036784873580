import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatMapFilterFormInitValues,
	formatCallPoolAreaFormInitValues,
	formatCallPoolCreatorInitValues,
} from './utils';
import {emptyCallPoolCreatorValues} from './constants';
import * as commonSelectors from 'modules/common/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(seq, x => !!x);

export const mapQuery = pipe(root, prop('mapQuery'));

export const urlQuery = createSelector([mapQuery], mapQuery => {
	return formatUrlQuery(mapQuery);
});

export const layersVisibility = createSelector([mapQuery], mapQuery => ({
	areasLayer: mapQuery.areasLayer,
	buildingsLayer: mapQuery.buildingsLayer,
	callPoolsLayer: mapQuery.callPoolsLayer,
	groundwaterAreasLayer: mapQuery.groundwaterAreasLayer,
	propertyLinesLayer: mapQuery.propertyLinesLayer,
}));

export const mapFilterFormInitValues = createSelector([mapQuery], mapQuery => {
	return formatMapFilterFormInitValues(mapQuery);
});

export const callPools = pipe(root, prop('callPools'));

export const callPoolsLoading = pipe(root, prop('callPoolsLoading'));

export const activeCallPool = pipe(root, prop('activeCallPool'));

export const activeCallPoolLoading = pipe(root, prop('activeCallPoolLoading'));

export const selection = pipe(root, prop('selection'));

export const selectionType = createSelector(
	[mapQuery],
	mapQuery => mapQuery.selectionType,
);

export const selectionLoading = pipe(root, prop('selectionLoading'));

export const issues = pipe(root, prop('issues'));

export const teams = pipe(root, prop('teams'));

export const teamsLoading = pipe(root, prop('teamsLoading'));

export const processing = pipe(root, prop('processing'));

export const selectionInfoTab = pipe(root, prop('selectionInfoTab'));

export const offers = pipe(root, prop('offers'));

export const offersPagination = pipe(root, prop('offersPagination'));

export const offersLoading = pipe(root, prop('offersLoading'));

export const offersModalOpen = pipe(root, prop('offersModalOpen'));

export const drawEndVisible = pipe(root, prop('drawEndVisible'));

export const customDrawArea = pipe(root, prop('customDrawArea'));

export const customAreaCreatorOpen = pipe(root, prop('customAreaCreatorOpen'));

export const showDrawMenu = pipe(root, prop('showDrawMenu'));

export const drawType = pipe(root, prop('drawType'));

export const drawing = pipe(root, prop('drawing'));

export const callPoolCreatorOpen = pipe(root, prop('callPoolCreatorOpen'));

export const products = pipe(root, prop('products'));

export const organizations = pipe(root, prop('organizations'));

export const buildings = pipe(root, prop('buildings'));

export const areaSettingsModalOpen = pipe(root, prop('areaSettingsModalOpen'));

export const smartDataOptions = pipe(root, prop('smartDataOptions'));

export const smartDataOptionsLoading = pipe(root, prop('smartDataOptionsLoading'));

export const mapSource = createSelector([mapQuery], mapQuery => mapQuery.mapSource);

export const profinderListCreatorOpen = pipe(root, prop('profinderListCreatorOpen'));

export const creatingProfinderList = pipe(root, prop('creatingProfinderList'));

export const profinderListsProcessing = pipe(root, prop('profinderListsProcessing'));

export const selectedCallPoolArea = createSelector(
	[selection, selectionType, activeCallPool],
	(selection, selectionType, activeCallPool) =>
		activeCallPool &&
		selection &&
		selectionType === 'area' &&
		selection.ids &&
		selection.ids.length === 1
			? activeCallPool.areas.find(a => a.id === selection.ids[0])
			: null,
);

export const selectionInActiveCallPool = createSelector(
	[selectedCallPoolArea],
	selectedCallPoolArea => !!selectedCallPoolArea,
);

export const initCallPoolAreaFormValues = createSelector(
	[selectedCallPoolArea, activeCallPool],
	(selectedCallPoolArea, activeCallPool) =>
		selectedCallPoolArea && activeCallPool
			? formatCallPoolAreaFormInitValues(selectedCallPoolArea, activeCallPool.teams)
			: null,
);

export const callPoolCreatorInitValues = createSelector(
	[activeCallPool, teams, products, smartDataOptions],
	(activeCallPool, teams, products, smartDataOptions) =>
		activeCallPool
			? formatCallPoolCreatorInitValues(activeCallPool, teams, products, smartDataOptions)
			: emptyCallPoolCreatorValues,
);

export const encounters = pipe(root, prop('encounters'));

export const activeEncounterSource = pipe(root, prop('activeEncounterSource'));

export const activeEncounterSourceType = pipe(root, prop('activeEncounterSourceType'));

export const mobileSidebarOpen = pipe(root, prop('mobileSidebarOpen'));

// PERM: show-call-pool-admin-settings
export const showCallPoolAdminSettings = createSelector(
	[commonSelectors.user, commonSelectors.isAdminUser],
	(user, isAdminUser) =>
		isAdminUser || (user.meta && user.meta.callPoolAdminSettingsPermission),
);

export const usersByTeamId = pipe(root, prop('usersByTeamId'));
export const usersByTeamIdProcessing = pipe(root, prop('usersByTeamIdProcessing'));

export const availableTags = pipe(root, prop('availableTags'));
