export const TYPE_CALL = 'call';
export const TYPE_VISIT = 'visit';
export const TYPE_SALESMANVISIT = 'salesmanVisit';

export const STATES = {
	NOT_REACHED: 'notReached',
	CANCELLED_DEAL: 'cancelledDeal',
	CONTACTED: 'contacted',
	CANCELLED_OFFER: 'cancelledOffer',
	OFFER: 'offer',
	VISIT: 'visit',
	CANCELLED: 'cancelled',
};

export const statesByType = {
	[TYPE_CALL]: [STATES.NOT_REACHED, STATES.VISIT, STATES.CONTACTED, STATES.CANCELLED],
	[TYPE_VISIT]: [STATES.NOT_REACHED, STATES.VISIT, STATES.CONTACTED, STATES.CANCELLED],
	[TYPE_SALESMANVISIT]: [
		STATES.NOT_REACHED,
		STATES.CANCELLED_DEAL,
		STATES.CONTACTED,
		STATES.CANCELLED_OFFER,
		STATES.OFFER,
	],
};
