import {reset, change, formValueSelector} from 'redux-form';
import {effect} from 'utils/redux';
import {mergeLeft, assocPath, min, max, merge, pipe, pick} from 'ramda';
import {catchNonFatalDefault, logInfo, handleAsFatal} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import * as confirmerActions from 'modules/confirmer/actions';
import * as commonSelectors from 'modules/common/selectors';
import * as commonActions from 'modules/common/actions';
import {selectableTeamsInclude} from './constants';
import msgs from 'dicts/messages';
import services from 'services';
import {
	getCalendarResources,
	getTeams,
	deleteCalendarResource,
	postCalendarResource,
	putCalendarResource,
	getBuildings,
	searchUsers as searchUsersIo,
	getBuilding,
	getCalendarResourceEncounter,
	getCalendarResource,
	initAddBuildingMap as doInitAddBuildingMap,
	postBuilding,
	postFormFill,
	getLead,
	getUserTeams,
	getMarketingLeadSources,
	getProducts,
	postClient,
	checkTeamCalendarVisibility,
} from './io';
import {
	getCalendarEvent,
	getCalendarEvents,
	putCalendarEvent,
	postCalendarEvent,
	getCalendarEventTypes,
	deleteCalendarEvent as deleteCalendarEventIo,
	restoreCalendarEvent as restoreCalendarEventIo,
} from 'modules/common/io';
import {initializeCallReminders as fetchCallReminders} from 'fragments/callReminder/effectHelpers';
import {decorateWithNotifications} from 'io/app';
import {parseUrlQuery} from './utils';
import {replaceQuery, getQuery} from 'io/history';
import {appointmentsHourRange} from 'constants/domain';
import {setHour, getHourBoundaries} from 'utils/time';
import * as Ls from 'io/localStorage';
import {buildingFocusZoom, localeCenterWeb, localeZoomFactor} from 'constants/maps';
import cache from './cache';
import {over} from 'utils/lenses';
import {getLocationByAddress} from 'io/geo';
import {transform} from 'ol/proj';
import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import createReminderEffects from 'fragments/callReminder/effects';

const nsStr = namespace.join('.');

const calendarEncounterAddFormVal = formValueSelector('calendarEncounterAddForm');

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchCalendarResources =
	(_teams = [], doCheckTeamCalendarVisibility = false) =>
	(getState, dispatch) => {
		const fetchableQuery = selectors.calendarQueryFetchable(getState());
		const callRemindersVisible = selectors.teamCalendarCallRemindersVisible(getState());
		const userId = commonSelectors.user(getState()).id;

		const {dateFrom, dateTo, teamId, onlyRequests} = fetchableQuery;
		const teams = selectors.activeOrganizationTeams(getState());
		// force teamId before sending request if it's empty in query somehow and do some logging
		// (unless we're fetching only requests in which case null teamId should be sent)
		if (!onlyRequests && (!teamId || teamId === '0')) {
			const fallbackTeam = teams.length ? teams[0] : _teams.length ? _teams[0] : null;

			if (fallbackTeam) {
				const err = new Error(
					"Missing teamId when fetching calendar resources in Team Calendar. Using user's first team as fallback.",
				);
				logInfo(err);
			} else {
				const err = new Error(
					'Missing teamId when fetching calendar resources in Team Calendar. Cannot use fallback, crashing the app.',
				);
				handleAsFatal(err);
			}

			fetchableQuery.teamId = fallbackTeam.id;
		}

		return Promise.all([
			getCalendarResources(fetchableQuery),
			getCalendarEvents({
				...fetchableQuery,
				showOnTeamCalendar: true,
				withTrashed: fetchableQuery?.includeTrashedEvents === true,
			}),
			callRemindersVisible
				? fetchCallReminders({actions, userId, dateFrom, dateTo})(getState, dispatch)
				: Promise.resolve([]),
			doCheckTeamCalendarVisibility
				? fetchableQuery.teamId
					? checkTeamCalendarVisibility(fetchableQuery.teamId).then(res =>
							dispatch(actions._setLockData(res)),
					  )
					: Promise.resolve(null).then(() =>
							dispatch(actions._setLockData({data: [], lockFrom: null})),
					  )
				: Promise.resolve(null),
		]).then(res => {
			const resources = res[0].concat(res[1]);
			dispatch(actions._setCalendarResources(resources));
		});
	};

export let onReminderClick = reminder => (getState, dispatch) => {
	const activeOrganizationId = commonSelectors.activeOrganizationId(getState());

	const referrerUrl = createReferrerUrl(history.location);
	const url =
		activeOrganizationId === 5
			? `/project-sales/condo/${reminder.buildingId}${encodeQuery({
					referrer: 'team-calendar',
					referrerUrl,
			  })}`
			: reminder.calendarResourceId
			? `/sales/${reminder.calendarResourceId}${encodeQuery({
					referrer: 'team-calendar',
					referrerUrl,
			  })}`
			: reminder.buildingId
			? `/calls/buildings/${reminder.buildingId}${encodeQuery({
					referrer: 'team-calendar',
					referrerUrl,
			  })}`
			: null;

	if (url) {
		window.location.assign(url);
	}
};

export let initialize = () => (getState, dispatch) => {
	const orgId = commonSelectors.activeOrganizationId(getState());
	const storedQuery = Ls.getJson(`${nsStr}_org-${orgId}:query`) || {};
	const isAdminUser = commonSelectors.isAdminUser(getState());
	// use possible localStorage values for query if not present in url
	const sourceQuery = {...storedQuery, ...getQuery()};
	const {calendarQuery} = parseUrlQuery(sourceQuery, selectors.showRequests(getState()));
	const {buildingId, clientId, leadId, openToDate, openItemId} = calendarQuery;
	if (openToDate) {
		// open calendar to correct week if openToDate present
		calendarQuery.weekSample = openToDate;
	}
	if (calendarQuery?.organizationId && sourceQuery?.referrer === 'marketing') {
		const activeOrganizationId = commonSelectors.activeOrganizationId(getState());
		if (activeOrganizationId !== calendarQuery.organizationId) {
			dispatch(commonActions.setOrganizationChangeRedirect(window.location.href));
			dispatch(commonActions.setOrganization(calendarQuery.organizationId));
		}
	}
	dispatch(actions._updateCalendarQuery(calendarQuery));

	decorateWithNotifications(
		{
			id: 'init-team-calendar',
			failureStyle: 'error',
		},
		Promise.all([
			getUserTeams({includeTeamUsers: false}).then(teams => {
				dispatch(actions._setUserTeam(teams[0]));
			}),
			getTeams({include: selectableTeamsInclude}).then(teams => {
				dispatch(actions._setActiveOrganizationTeams(teams));
				if (!isAdminUser) {
					dispatch(actions._setAllTeams(teams));
				}
				// if query doesn't have team or team isn't found, set first team as active
				if (teams.length) {
					const teamId = selectors.teamId(getState());
					if ((!teamId || !teams.find(t => t.id === teamId)) && teamId !== 'requests') {
						dispatch(actions._updateCalendarQuery({teamId: teams[0].id}));
						replaceQuery(mergeLeft(selectors.urlQuery(getState())));
					}
				}
				return teams;
			}),
			getMarketingLeadSources().then(sources => {
				dispatch(actions._setMarketingLeadSources(sources));
			}),
			getProducts().then(products => {
				dispatch(actions._setProducts(products));
			}),
			getCalendarEventTypes({_limit: 999}).then(eventTypes => {
				dispatch(actions._setCalendarEventTypes(eventTypes));
			}),
			isAdminUser &&
				getTeams({getAllTeams: true, include: 'organization,users'}).then(teams => {
					dispatch(actions._setAllTeams(teams));
				}),
		]).then(([_, _teams]) => {
			return fetchCalendarResources(_teams, true)(getState, dispatch);
		}),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());

			// navigated from leadsApp, fetch building and set as initial building in calendarEncounterAddForm
			// also fetch lead and set it's comment as formFill description
			if (buildingId || leadId) {
				return decorateWithNotifications(
					{id: 'get-lead-building', failureStyle: 'warning'},
					Promise.all([
						buildingId
							? getBuilding(buildingId).then(b => {
									const leadBuilding = {...b, clients: b.clients};
									dispatch(change('calendarEncounterAddForm', 'building', leadBuilding));
									dispatch(change('calendarEncounterAddForm', 'clientId', clientId));
									dispatch(actions._setBuilding({leadBuilding, leadClient: clientId}));
							  })
							: Promise.resolve(null),
						leadId
							? getLead(leadId).then(l => {
									dispatch(
										change('calendarEncounterAddForm', 'formFill', {
											description: l.comment,
										}),
									);
									dispatch(actions._setLead(l));
							  })
							: Promise.resolve(null),
					]),
				)(getState, dispatch);
			}

			if (openToDate) {
				// open matching slot if openToDate present
				dispatch(actions._openToDate(openToDate));
			}

			// open a certain calendar resource when entering the view. note that you need to also set weekSample in the query manually when navigating here, teams calendar doesn't do it.
			if (openItemId) {
				const item = selectors.itemsSource(getState()).find(x => x.id === openItemId);
				dispatch(actions._setSelectedItem(item));
				replaceQuery(q => ({...q, openItemId: ''}));
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateCalendarResources = teamChanged => (getState, dispatch) => {
	const urlQuery = selectors.urlQuery(getState());

	replaceQuery(mergeLeft(urlQuery));

	// also store some keys in localStorage
	const orgId = commonSelectors.activeOrganizationId(getState());
	const storableQuery = pick(['teamId'], urlQuery);
	Ls.updateJson(`${nsStr}_org-${orgId}:query`, mergeLeft(storableQuery));

	decorateWithNotifications(
		{
			id: 'update-calendar-resources',
			failureStyle: 'error',
		},
		fetchCalendarResources([], teamChanged)(getState, dispatch),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarResources = creator('updateCalendarResources', updateCalendarResources);

const _removeCalendarResources = resources => (getState, dispatch) => {
	const successMsg = resources.length > 1 ? 'Entries deleted' : 'Entry deleted';
	dispatch(actions._startOp());

	return decorateWithNotifications(
		{
			id: 'remove-calendar-resources',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: successMsg}),
			failureStyle: 'error',
		},
		Promise.all(resources.map(r => deleteCalendarResource(r.id))),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._opOk());
			dispatch(actions._removeCalendarResources(resources));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let removeCalendarResources = resources => (getState, dispatch) => {
	const onConfirm = () => {
		_removeCalendarResources(resources)(getState, dispatch);
	};

	if (resources.length > 1) {
		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete all selected entries?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	} else {
		_removeCalendarResources(resources)(getState, dispatch);
	}
};
removeCalendarResources = creator('removeCalendarResources', removeCalendarResources);

export let createCalendarResources = resources => (getState, dispatch) => {
	const successMsg = resources.length > 1 ? 'Entries saved' : 'Entry saved';

	decorateWithNotifications(
		{
			id: 'create-free-calendar-resources',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: successMsg}),
			failureStyle: 'error',
		},
		Promise.all(resources.map(r => postCalendarResource(r))),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(newResources => {
			dispatch(actions._opOk());
			dispatch(actions._addCalendarResources(newResources));
			dispatch(actions.toggleItemsViewer(true));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

createCalendarResources = creator('createCalendarResources', createCalendarResources);

export let createEncounter =
	({calendarResource, formFill}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'create-encounter',
				loading: intl.formatMessage({id: 'Saving'}),
				success: intl.formatMessage({id: 'Entry saved'}),
				failureStyle: 'error',
			},
			(formFill ? postFormFill(formFill) : Promise.resolve({})).then(f =>
				postCalendarResource({...calendarResource, formFillId: f.id}),
			),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(cr => {
				dispatch(actions._opOk());
				dispatch(actions._addCalendarResources([cr]));
				dispatch(actions.toggleItemsViewer(true));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

createEncounter = creator('createEncounter', createEncounter);

export let updateCalendarResource = resource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-calendar-resource',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar entry saved'}),
			failureStyle: 'error',
		},
		putCalendarResource(resource),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(updatedResource => {
			dispatch(actions._opOk());
			dispatch(actions._resetEditors());
			dispatch(actions._updateCalendarResource(updatedResource));

			const fetchableQuery = selectors.calendarQueryFetchable(getState());
			if (fetchableQuery.teamId) {
				checkTeamCalendarVisibility(fetchableQuery.teamId).then(res => {
					dispatch(actions._setLockData(res));
				});
			}

			// fetch new calendar resource when cancelling previous one
			if (updatedResource.newCalendarId) {
				return decorateWithNotifications(
					{id: 'fetch-new-calendar-resource', failureStyle: 'warning'},
					getCalendarResource(updatedResource.newCalendarId),
				)(getState, dispatch).then(newResource => {
					dispatch(actions._addCalendarResources([newResource]));
				});
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

updateCalendarResource = creator('updateCalendarResource', updateCalendarResource);

export let toggleItemsCreator = () => (getState, dispatch) => {
	const leadBuilding = selectors.leadBuilding(getState());
	if (!leadBuilding) {
		dispatch(reset('calendarEncounterAddForm'));
	}
	dispatch(reset('calendarResourceAddForm'));
};
toggleItemsCreator = creator('toggleItemsCreator', toggleItemsCreator);

export let searchBuildings =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-buildings',
				failureStyle: 'warning',
			},
			getBuildings({_q: text, _limit: 8, include: 'clients'}),
		)(getState, dispatch)
			.then(buildings => {
				callback(buildings);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchBuildings = creator('searchBuildings', searchBuildings);

export let searchUsers =
	({text, callback}) =>
	(getState, dispatch) => {
		const org = commonSelectors.activeOrganization(getState());
		decorateWithNotifications(
			{
				id: 'search-buildings',
				failureStyle: 'warning',
			},
			searchUsersIo({_q: text, _limit: 999, organization: org.id}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchUsers = creator('searchUsers', searchUsers);

export let refreshSelectedBuildingClients = buildingId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'refresh-selected-building-clients',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'warning',
		},
		getBuilding(buildingId),
	)(getState, dispatch)
		.then(building => {
			const selectedBuilding = calendarEncounterAddFormVal(getState(), 'building');
			const newBuilding = {...selectedBuilding, clients: building.clients};
			dispatch(change('calendarEncounterAddForm', 'building', newBuilding));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
refreshSelectedBuildingClients = creator(
	'refreshSelectedBuildingClients',
	refreshSelectedBuildingClients,
);

export let getPreviewableEncounter = resource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-previewable-encounter',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.loading}),
		},
		getCalendarResourceEncounter(resource.id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(enc => {
			const encounter = assocPath(['source', 'calendarResource'], resource, enc);
			dispatch(actions._setPreviewableEncounter(encounter));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getPreviewableEncounter = creator('getPreviewableEncounter', getPreviewableEncounter);

export let fetchBuildingCalendarResources = buildingId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-building-calendar-resources',
			failureStyle: 'warning',
		},
		getCalendarResources({buildingId, searchByPreviousDates: false}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(resources => {
			dispatch(actions._setBuildingCalendarResources(resources));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchBuildingCalendarResources = creator(
	'fetchBuildingCalendarResources',
	fetchBuildingCalendarResources,
);

export let setBonusItemBeingReserved = item => (getState, dispatch) => {
	const nextHour = pipe(
		max(appointmentsHourRange[0]),
		min(appointmentsHourRange[1]),
	)(new Date().getHours() + 2);

	const d = setHour(item.dateFrom, nextHour, 0, 0, 0);
	const [dateFrom, dateTo] = getHourBoundaries(d);
	const newItem = merge(item, {dateFrom, dateTo});

	dispatch(actions._setBonusItemBeingReserved(newItem));
};
setBonusItemBeingReserved = creator(
	'setBonusItemBeingReserved',
	setBonusItemBeingReserved,
);

export let reserveBonusItem = resource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'reserve-bonus-item',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar entry saved'}),
			failureStyle: 'error',
		},
		putCalendarResource(resource),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(updatedResource => {
			dispatch(actions._opOk());
			dispatch(actions._updateCalendarResource(updatedResource));
			dispatch(actions.closeBonusItemReservationModal());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
reserveBonusItem = creator('reserveBonusItem', reserveBonusItem);

export let initAddBuildingMap = () => (getState, dispatch) => {
	const apiToken = commonSelectors.apiToken(getState());
	const org = commonSelectors.activeOrganization(getState());
	const building = selectors.buildingToAdd(getState());

	// Fetch address coords from Google, set to state (buildingToAdd) and as initial center
	decorateWithNotifications(
		{id: 'init-add-building-map', loading: intl.formatMessage({id: msgs.loading})},
		getLocationByAddress(`${building.address},${building.zip}`)
			.then(res => {
				const {lat, lng} = res.geometry.location;
				return {
					coords: transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857'),
					zoom: buildingFocusZoom,
				};
			})
			.catch(e => {
				// address not found in Google, return localeCenterWeb as fallback
				logInfo(e);
				return {
					coords: localeCenterWeb,
					zoom: 4 * localeZoomFactor,
				};
			})
			.then(({coords, zoom}) => {
				dispatch(
					nActions.info({
						id: 'drag-marker-info',
						message: intl.formatMessage({
							id: 'You can adjust the position of the building by dragging the marker',
						}),
						duration: medDur,
					}),
				);
				dispatch(actions._setBuildingToAddCoords(coords));

				return doInitAddBuildingMap({
					apiToken,
					organizationId: org.id,
					initialZoom: zoom,
					initialCenter: coords,
					onDragEnd: coords => dispatch(actions._setBuildingToAddCoords(coords)),
				});
			}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['addBuildingMap'], mergeLeft(resources)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initAddBuildingMap = creator('initAddBuildingMap', initAddBuildingMap);

export let addBuilding = building => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'add-building',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Building created'}),
		},
		postBuilding(building),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => {
			dispatch(actions._addBuilding());
			dispatch(
				change('calendarEncounterAddForm', 'building', {...b, clients: {data: []}}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
addBuilding = creator('addBuilding', addBuilding);

export let openRoutePlanner = () => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	const slotSelection = selectors.slotSelection(getState());
	const teamId = selectors.teamId(getState());
	const date = slotSelection.date ? slotSelection.date.getTime() : null;

	history.push(
		`/team-calendar/calendar/route-planner${encodeQuery({teamId, date, referrerUrl})}`,
	);
};
openRoutePlanner = creator('openRoutePlanner', openRoutePlanner);

const reminderEffects = createReminderEffects({namespace, actions});
export const {saveCalendarCallReminder, removeCallReminder, setReminderSeen} =
	reminderEffects;

export let createClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'create-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(newClient => {
				const selectedBuilding = calendarEncounterAddFormVal(getState(), 'building');
				const newBuilding = {
					...selectedBuilding,
					clients: {
						data: [...selectedBuilding.clients.data, newClient],
					},
				};
				dispatch(change('calendarEncounterAddForm', 'building', newBuilding));
				dispatch(change('calendarEncounterAddForm', 'clientId', newClient.id));
				dispatch(actions._clientSaved());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createClient = creator('createClient', createClient);

export let createCalendarEvent = event => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'create-calendar-event',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar event saved'}),
			failureStyle: 'error',
		},
		postCalendarEvent(event),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(newEvent => {
			getCalendarEvent(newEvent.id).then(newEvent => {
				dispatch(actions._opOk());
				if (newEvent?.showOnTeamCalendar) {
					dispatch(actions._addCalendarResources([newEvent]));
				}
				dispatch(actions.toggleItemsViewer(true));
			});
		});
};
createCalendarEvent = creator('createCalendarEvent', createCalendarEvent);

export let deleteCalendarEvent = event => (getState, dispatch) => {
	const currentQuery = selectors.calendarQuery(getState());
	const onConfirm = () => {
		deleteCalendarEventIo(event.id).then(() => {
			dispatch(actions._removeCalendarResources([event]));
			if (currentQuery.includeTrashedEvents) {
				dispatch(
					actions._addCalendarResources([
						{...event, deletedAt: new Date().toISOString()},
					]),
				);
			}
			dispatch(actions.setSelectedItem(null));
		});
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete calendar event?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteCalendarEvent = creator('deleteCalendarEvent', deleteCalendarEvent);

export let restoreCalendarEvent = event => (getState, dispatch) => {
	const onConfirm = () => {
		restoreCalendarEventIo(event.id).then(newEvent => {
			getCalendarEvent(newEvent.id).then(newEvent => {
				dispatch(actions._opOk());
				dispatch(actions._removeCalendarResources([event]));
				dispatch(actions._addCalendarResources([newEvent]));
				dispatch(actions.setSelectedItem(null));
			});
		});
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Restore calendar event?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
restoreCalendarEvent = creator('restoreCalendarEvent', restoreCalendarEvent);

export let updateCalendarEvent = event => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-calendar-event',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar event saved'}),
			failureStyle: 'error',
		},
		putCalendarEvent(event),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(updatedResource => {
			getCalendarEvent(updatedResource.id).then(event => {
				dispatch(actions._opOk());
				dispatch(actions._resetEditors());
				if (event?.showOnTeamCalendar === true) {
					dispatch(actions._updateCalendarEvent(event));
				} else {
					dispatch(actions._removeCalendarResources([event]));
				}
			});
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarEvent = creator('updateCalendarEvent', updateCalendarEvent);

export let searchCalendarEventTypes =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-calendar-event-types',
				failureStyle: 'warning',
			},
			getCalendarEventTypes({_limit: 999}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchCalendarEventTypes = creator('searchCalendarEventTypes', searchCalendarEventTypes);
