import * as React from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import services from 'services';
import * as nActions from 'modules/notifications/actions';
import {shortDur} from 'constants/notifications';
import {browserSettingsLink} from 'utils/browserSettingsLink';
import {Copy} from 'lucide-react';

const copySettingToClipBoard = async value => {
	services.get('store').dispatch(
		nActions.success({
			id: 'clipboard-copied',
			duration: shortDur,
			message: services.get('intl').formatMessage({id: 'Link copied to clipboard'}),
		}),
	);
	navigator.clipboard.writeText(value);
};

export default function AudioTest() {
	const settingsLink = browserSettingsLink();

	return (
		<div className="flex flex-col gap-2 text-xs">
			<div className="text-gray-700">
				<FormattedMessage id="Browser mic settings instruction" />
			</div>
			<div className="flex gap-1">
				<input
					value={settingsLink}
					readOnly="readonly"
					className="flex-1 h-6 px-2 rounded-md flex items-center bg-gray-100 text-gray-600"
					onFocus={e => e.target.select()}
				></input>
				<button
					className="bg-gray-500 text-white h-6 px-2 rounded-md flex items-center gap-1"
					onClick={() => copySettingToClipBoard(settingsLink)}
				>
					<Copy className="size-3" />
					<FormattedMessage id="Copy" />
				</button>
			</div>
		</div>
	);
}
