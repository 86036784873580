import React from 'react';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Button from 'components/generic/Button';
import SpacedItems from 'components/generic/SpacedItems';
import FormBlock from 'components/generic/FormBlock';
import LabelLike from 'components/generic/LabelLike';
import Modal from 'components/generic/Modal';
import ModalHeaderTitle from 'components/generic/ModalHeaderTitle';
import messages from 'dicts/messages';
import ReactDropzone from 'components/generic/ReactDropzone';
import {compose} from 'redux';
import Field from 'components/generic/Field';
import FieldError from 'components/generic/FieldError';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {applyState} from 'utils/redux';
import Loader from 'components/generic/Loader';
import styled from 'styled-components';
import ContentSpacing from 'components/generic/ContentSpacing';
import H5 from 'components/generic/H5';
import {infoColor} from 'styles/constants';
import P from 'components/generic/P';
import Hr from 'components/generic/Hr';

const LoaderWrapper = styled.div`
	margin-top: 15px;
	text-align: center;
	height: 24px;
`;

const InfoHeading = styled(H5)`
	margin-top: 0;
`;
const InfoIcon = styled.i`
	color: ${infoColor};
`;

const CsvFileSelector = ({
	intl,
	input,
	csvFileValue,
	csvFileSelectorProcessing,
	csvFileSelectorOpen,
	openCsvFileSelector,
	closeCsvFileSelector,
	clearCsvFileSelection,
	submit,
	handleSubmit,
}) => {
	const closeModal = csvFileSelectorProcessing ? () => null : closeCsvFileSelector;
	return (
		<FormBlock>
			<LabelLike>
				<FormattedMessage id="File" />
				{csvFileValue ? `: ${csvFileValue}` : ''}
			</LabelLike>
			<SpacedItems>
				<div> </div>

				{!!csvFileValue && (
					<Button
						onClick={() => clearCsvFileSelection()}
						disabled={csvFileSelectorProcessing}
					>
						<FormattedMessage id="Clear" />
					</Button>
				)}
				<Button onClick={() => openCsvFileSelector()}>
					<FormattedMessage id="Upload CSV" />
				</Button>
			</SpacedItems>
			{csvFileSelectorOpen && (
				<Modal
					hide={() => closeModal()}
					verticalPadding
					headerTitle={
						<ModalHeaderTitle>
							<FormattedMessage id="Upload CSV" />
						</ModalHeaderTitle>
					}
					cancelButton={
						<Button onClick={() => closeModal()}>
							<FormattedMessage id={messages.close} />
						</Button>
					}
				>
					<form onSubmit={handleSubmit}>
						<Field
							name="file"
							onChange={() => {
								setTimeout(() => {
									// Wait for the file to be available in the store
									submit();
								});
							}}
							props={{
								csvFileSelectorProcessing,
							}}
							component={({input, inputId, meta, csvFileSelectorProcessing}) => (
								<FormBlock>
									<ReactDropzone
										disabled={csvFileSelectorProcessing}
										{...input}
										meta={meta}
										id={inputId}
										title={intl.formatMessage({id: 'Add file'})}
										subtitle={
											csvFileSelectorProcessing ? (
												<Loader />
											) : (
												intl.formatMessage({
													id: 'Filter buildings by uploading CSV-file',
												})
											)
										}
									/>
									<FieldError {...meta} />
									<LoaderWrapper>
										{csvFileSelectorProcessing ? (
											<>
												<Loader />
											</>
										) : (
											''
										)}
									</LoaderWrapper>
								</FormBlock>
							)}
						/>
					</form>
					<Hr />
					<ContentSpacing>
						<InfoHeading>
							<InfoIcon className="fa fa-info-circle pad-right" />
							<FormattedMessage id="Instructions" />
						</InfoHeading>
						<P>
							<FormattedMessage id="Recommended file format: utf-8" />
						</P>
						<P>
							<b>
								<FormattedMessage id="Instructions for headings and required columns" />
							</b>
							<br />
							<FormattedMessage id="Name heading columns as instructed" />
							<br />
							<FormattedMessage id="Address column name" />
							<br />
							<FormattedMessage id="Zip code column name" />
						</P>
						<P>
							<b>
								<FormattedMessage id="Instructions for non required columns" />
							</b>
							<br />
							<FormattedMessage id="City column name" />
							<br />
							<FormattedMessage id="Municipal column name" />
						</P>
					</ContentSpacing>
				</Modal>
			)}
		</FormBlock>
	);
};
export const CSV_FILE_FORM = 'csvFileSelectorForm';

export default compose(
	injectIntl,
	connect(applyState({})),
	reduxForm({
		form: CSV_FILE_FORM,
	}),
)(CsvFileSelector);
