import React from 'react';
import {Bar} from 'react-chartjs-2';
import Panel from 'components/generic/Panel';
import PanelHeaderTitle from 'components/generic/PanelHeaderTitle';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import {colors} from 'styles/constants';
import {compose} from 'redux';
import {isNil} from 'ramda';
import {currency, currencySymbol} from 'constants/loc';

const SalesPipelineChart = ({data, intl}) => {
	const amountsKeys = Object.keys(data ?? []).filter(key => {
		return !key.includes('€') && !key.includes('SEK');
	});
	const amountsData = amountsKeys.map(key => data[key]);

	const chartData = {
		labels: amountsKeys,
		datasets: [
			{
				data: amountsData,
				borderColor: colors.grey6,
				backgroundColor: colors.info3,
			},
		],
	};

	const options = {
		indexAxis: 'y',
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						let currencyKeyValue = currency === 'SEK' ? 'SEK' : '€';
						let label = context.label || '';
						const key = `${label} ${currencyKeyValue}`;
						const sum = data[key] ?? null;
						const pcsLabel = `${context.raw} ${intl.formatMessage({id: 'pcs'})}`;

						if (!isNil(sum)) {
							return `${intl.formatMessage(
								{id: '{amount} {unit}'},
								{amount: sum, unit: currencySymbol},
							)}, ${pcsLabel}`;
						}
						return pcsLabel;
					},
				},
			},
		},
	};

	return (
		<Panel
			header={
				<PanelHeaderTitle>
					<FormattedMessage id="Sales pipeline" /> (<FormattedMessage id="pcs" />)
				</PanelHeaderTitle>
			}
		>
			<div className="flex pt-2 pb-3">
				<Bar options={options} data={chartData} />
			</div>
		</Panel>
	);
};
export default compose(injectIntl)(SalesPipelineChart);
