import React from 'react';
import {screenMdMax} from 'styles/constants';
import {FormattedMessage} from '@meiko/react-intl';
import Modal from 'components/generic/Modal';
import messages from 'dicts/messages';
import PanelHeaderTitle from 'components/generic/PanelHeaderTitle';
import Button from 'components/generic/Button';
import TargetEditor from './index';

const TargetEditorModal = ({show, onClose, initialTab = 'targets'}) => {
	return (
		<>
			{show && (
				<Modal
					hide={onClose}
					maxWidth={screenMdMax}
					verticalPadding
					headerTitle={
						<PanelHeaderTitle>
							<FormattedMessage id="DashboardTargets" />
						</PanelHeaderTitle>
					}
					cancelButton={
						<Button onClick={() => onClose()}>
							<FormattedMessage id={messages.close} />
						</Button>
					}
				>
					<TargetEditor initialTab={initialTab} />
				</Modal>
			)}
		</>
	);
};

export default TargetEditorModal;
