import {parsePhoneNumber} from 'react-phone-number-input';
import {locale} from 'constants/app';

const regex = /^(\+358|\+46|\d\d\d)(\d{3})(\d{4}$|\d{3})(\d+)?$/;

export default function formatPhoneNumber(phone) {
	const result = regex.exec(phone) || [null, phone];
	return result.slice(1).join(' ');
}

// TODO: should use react-phone-number-input instead to validate phone numbers
// It however expects phone numbers in international format
export const isValidPhoneNumber = phone => {
	if (!regex.test(phone)) {
		return false;
	}
	return true;
};

export const normalizePhone = val => {
	if (!val) return val;

	// remove all characters except numbers and +
	return val.replace(/[^\d+]/g, '');
};

// extracts phone number from text
export const extractPhoneNumber = str => {
	const match = str.match(/(\+358|\+46|\d\d\d)(\d{3})(\d{4}$|\d{3})(\d+)?/);
	return match ? match[0] : null;
};

// converts national phone number to international based on locale
// does nothing if value is already in international format
// e.g. 0401234567 => +358401234567
export const parseIntPhoneNumber = phone => {
	if (!phone) return '';

	const phoneNumber = parsePhoneNumber(phone, locale.toUpperCase());
	return phoneNumber ? phoneNumber.number : '';
};
