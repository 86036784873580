import {effect} from 'utils/redux';
import namespace from './namespace';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import {getDashboard as getDashboardIO, getTeams as getTeamsIO} from './io';
import {getCalendarResourcesCount} from 'modules/calendarApp/SalesmanVisitsPage/io';
import {user} from 'modules/common/selectors';

const creator = effect(namespace);

export let initialize = () => () => {};
initialize = creator('initialize', initialize);

export let getDashboard = payload => (_getState, dispatch) => {
	getDashboardIO(payload)
		.catch(catchNonFatalDefault)
		.then(data => {
			dispatch(actions._getDashboard(data));
		});

	getCalendarResourcesCount({
		_limit: 25,
		onlyWithoutSalesmanVisit: true,
		withoutFreeTimes: true,
		bonus: false,
		withoutCancelled: true,
		unacknowledged: true,
		salesmanId: user(_getState()).id,
	}).then(({count}) => {
		dispatch(actions._getUnacknowledgedVisitsCount(count));
	});
};
getDashboard = creator('getDashboard', getDashboard);

export let getTeams =
	(query = {}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{
				id: 'fetch-teams',
				failuterStyle: 'error',
			},
			getTeamsIO({
				...query,
				isCalendar: true,
				type: 'sales',
			}),
		)(getState, dispatch).then(result => {
			dispatch(actions._getTeams(result));
		});
	};

getTeams = creator('getTeams', getTeams);
