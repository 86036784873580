import {state as lifecycle} from 'fragments/lifecycle';
import createReminderState from 'fragments/callReminder/state';

const reminderState = createReminderState({});

export default {
	...lifecycle,
	...reminderState,
	initialized: false,
	processing: false,
	calResLoading: false,
	calendarQuery: {
		teamId: null,
		weekSample: new Date(),
		buildingId: null,
		clientId: null,
		leadId: null,
		openToDate: null,
		openItemId: null,
		organizationId: null,
		includeTrashedEvents: false,
	},
	hourItems: [],
	hourlessItems: [],
	itemsSource: [],
	teams: [],
	activeOrganizationTeams: [],
	itemsViewerOpen: false,
	itemsCreatorOpen: false,
	slotSelection: {
		type: null,
		date: null,
		iDay: null,
		hour: null,
	},
	selectedItem: null,
	previewableEncounter: null,
	areasEditorOpen: false,
	dateEditorOpen: false,
	buildingCalendarResources: [],
	buildingCalendarResourcesLoading: false,
	calendarBuildingModalOpen: false,
	building: null,
	bonusItemReservationModalOpen: false,
	bonusItemBeingReserved: null,
	addBuildingModalOpen: false,
	mapModalOpen: false,
	addBuildingFormInitValues: {
		address: '',
		zip: '',
	},
	buildingToAdd: {
		address: '',
		zip: '',
		type: '',
		manufacturingYear: '',
		coords: [],
	},
	leadBuilding: null,
	leadClient: null,
	lead: null,
	callReminderBuilding: {},
	userTeam: {},
	marketingLeadSources: [],
	products: [],
	clientEditorOpen: false,
	lockingResources: [],
	lockFrom: null,
	lockModalOpen: false,
	calendarEventTypes: [],
};
