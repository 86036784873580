import React, {useMemo} from 'react';
import {useQueryTargetIndex} from 'hooks/api/target/useQueryTarget';
import {useQueryOrganizationById} from 'hooks/api/organization/useQueryOrganization';
import {injectIntl, FormattedNumber, FormattedMessage} from '@meiko/react-intl';
import {currencyOptions, createBatchPayloadForBudgets} from './utils';
import {getYear, getMonth} from 'date-fns';
import {useQueryTeamIndex} from 'hooks/api/team/useQueryTeam';
import {useForm, useFieldArray} from 'react-hook-form';
import {useMutationTargetBatch} from 'hooks/api/target/useMutationTarget';
import {Loader2} from 'lucide-react';
import {useAuth} from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import {useDeepCompareEffect} from 'react-use';

export const OrganizationBudgetForm = injectIntl(({organizationId, date, intl}) => {
	const {user} = useAuth();
	const canEditBudget = !!user?.permissions.find(p => p.slug === 'budget.edit');

	const year = date ? getYear(new Date(date)) : undefined;
	const month = date ? getMonth(new Date(date)) + 1 : undefined;

	const {data: teamsData, isFetching: isTeamsDataFetching} = useQueryTeamIndex({
		params: {
			organizationId,
			getAllTeams: false,
			type: 'sales',
		},
		useQueryOptions: {
			enabled: !!organizationId,
		},
	});

	const teams = teamsData?.data || [];
	const teamIds = teams.map(({id}) => id);

	// common query params for targets
	const targetParamsBase = {
		year,
		month,
		timeSpan: 'month',
		type: 'dashboard-sales-budget-sum',
		organizationId,
	};

	// organization budget
	const {data: organizationBudgetData, isFetching: isOrganizationBudgetDataFetching} =
		useQueryTargetIndex({
			params: targetParamsBase,
			useQueryOptions: {
				enabled: !!date && !!organizationId,
			},
		});

	const organizationBudgetDataFiltered = organizationBudgetData?.data.filter(
		({teamId}) => !teamId,
	);

	const {data: selectedOrganizationData} = useQueryOrganizationById({
		params: {
			organizationId,
		},
		useQueryOptions: {
			enabled: !!organizationId,
		},
	});

	// team budgets
	const {data: teamsBudgetData, isFetching: isTeamsBudgetDataFetching} =
		useQueryTargetIndex({
			params: {...targetParamsBase, teamIds},
			useQueryOptions: {
				enabled: !!date && !!organizationId && !!teams.length,
			},
		});

	// default values for team budget fields
	const teamValues = useMemo(
		() =>
			teams.map(team => {
				const targetData = teamsBudgetData?.data.find(({teamId}) => teamId === team.id);

				return {
					teamId: team.id,
					title: team.title,
					budget: targetData?.target || '',
					...(targetData?.id && {id: targetData.id}),
				};
			}),
		[teamsBudgetData, teams],
	);

	// default values for organization budget fields
	const organizationValues = useMemo(
		() => [
			{
				organizationId,
				title: selectedOrganizationData?.data?.title,
				budget: organizationBudgetDataFiltered?.[0]?.target || '',
				...(organizationBudgetDataFiltered?.[0]?.id && {
					id: organizationBudgetDataFiltered?.[0]?.id,
				}),
			},
		],
		[organizationId, organizationBudgetDataFiltered, selectedOrganizationData],
	);

	// form
	const {control, register, handleSubmit, reset, watch} = useForm({
		defaultValues: {
			teams: teamValues,
			organizations: organizationValues,
		},
	});

	// form: team fields array
	const {fields: teamFields} = useFieldArray({
		control,
		name: 'teams',
	});

	// form: organization fields array
	// we only support one organization for now but the form is designed to support multiple organizations
	const {fields: organizationFields} = useFieldArray({
		control,
		name: 'organizations',
	});

	// form: we need to watch all the fields (teams is an array) to trigger the sum calculations
	const watchedFields = watch();

	// team budget sum
	const teamBudgetSum = watchedFields.teams.reduce(
		(acc, field) => acc + Number(field.budget),
		0,
	);

	// organization budget sum
	const organizationBudgetSum = watchedFields.organizations.reduce(
		(acc, field) => acc + Number(field.budget),
		0,
	);

	// the difference between the team budget sum and the organization budget sum
	const teamBudgetSumDiff = teamBudgetSum - organizationBudgetSum;

	// reset the form with the default values
	useDeepCompareEffect(() => {
		reset({teams: teamValues, organizations: organizationValues});
	}, [teamValues, organizationValues]);

	// submit the form
	const {mutateAsync, isLoading} = useMutationTargetBatch();

	const {openNotification} = useNotifications();
	const onSubmit = async formData => {
		try {
			await mutateAsync(
				createBatchPayloadForBudgets({
					organizationId,
					formData,
					year,
					month,
				}),
			);
		} catch (error) {
			openNotification({
				message: intl.messages['Error submitting budgets'],
				type: 'error',
			});
		}
	};

	// check if anything is pending to show a loading indicator
	const isAnythingPending =
		isOrganizationBudgetDataFetching || isTeamsBudgetDataFetching || isTeamsDataFetching;

	const inputClasses =
		'w-full h-8 px-2 text-center focus:outline-gray-300 focus:ring-0 disabled:text-gray-500 hover:bg-gray-50 focus:bg-white';

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="relative" noValidate>
			{isAnythingPending && (
				<div className="absolute inset-0 bg-white/60 backdrop-blur-[1px] z-10 rounded-lg" />
			)}
			<div className="flex flex-col gap-6 text-sm">
				<div className="flex flex-col divide-y rounded-lg border overflow-hidden">
					<div className="grid divide-x text-xs text-gray-600 grid-cols-[1fr_repeat(2,minmax(0,1fr))] border-b bg-gray-50">
						<div className="px-4 py-2 flex items-center">
							<FormattedMessage id="Organization" />
						</div>
						<div className="px-4 py-2 flex justify-center items-center">
							<FormattedMessage id="Budget (€/month)" />
						</div>
						<div className="px-4 py-2 flex justify-center items-center">
							<FormattedMessage id="Calculated team budgets (month)" />
						</div>
					</div>

					{organizationFields.map((organization, organizationIndex) => (
						<div
							key={organizationIndex}
							className="grid divide-x grid-cols-[1fr_repeat(2,minmax(0,1fr))]"
						>
							<div className="py-2 px-4 font-medium flex items-center">
								{organization.title}
							</div>
							<div className="py-2 px-4 flex items-center">
								<input
									{...register(`organizations.${organizationIndex}.budget`)}
									type="number"
									step={10000}
									className={inputClasses}
									disabled={!canEditBudget}
									onFocus={e => e.target.select()}
								/>
							</div>
							<div className="py-2 px-4 font-medium flex justify-center items-center">
								<span className="rounded-lg bg-amber-100 text-gray-700 px-2 py-0.5 flex items-center gap-1">
									{typeof teamBudgetSum === 'number' ? (
										<FormattedNumber value={teamBudgetSum} {...currencyOptions} />
									) : (
										'-'
									)}
									{typeof teamBudgetSumDiff === 'number' && teamBudgetSumDiff !== 0 && (
										<span className="text-xs text-yellow-600">
											(<FormattedNumber value={teamBudgetSumDiff} {...currencyOptions} />)
										</span>
									)}
								</span>
							</div>
						</div>
					))}
				</div>
				<div className="flex flex-col divide-y rounded-lg border overflow-hidden">
					<div className="grid divide-x text-xs text-gray-600 grid-cols-[1fr_repeat(1,minmax(0,1fr))] border-b bg-gray-50">
						<div className="px-4 py-2 flex items-center">
							<FormattedMessage id="Team" />
						</div>
						<div className="px-4 py-2 flex justify-center items-center">
							<FormattedMessage id="Budget (€/month)" />
						</div>
					</div>
					{teamFields.map((team, teamIndex) => (
						<div
							key={teamIndex}
							className="grid divide-x grid-cols-[1fr_repeat(1,minmax(0,1fr))]"
						>
							<div className="py-2 px-4 font-medium flex items-center">{team.title}</div>
							<div className="py-2 px-4 flex items-center">
								<input
									{...register(`teams.${teamIndex}.budget`)}
									type="number"
									step={10000}
									className={inputClasses}
									disabled={!canEditBudget}
									onFocus={e => e.target.select()}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="flex justify-end items-center mt-6">
				<button
					type="submit"
					disabled={isLoading || isAnythingPending}
					className="bg-green-600 text-white font-medium text-sm h-10 px-4 rounded-lg disabled:opacity-50"
				>
					{isLoading ? (
						<Loader2 className="w-4 h-4 animate-spin" />
					) : (
						<FormattedMessage id="Save" />
					)}
				</button>
			</div>
		</form>
	);
});
