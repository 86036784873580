import {effect} from 'utils/redux';
import namespace from './namespace';
import {appName} from '../constants';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications, bindToBuildingPresenceChannel} from 'io/app';
import * as actions from './actions';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';
import * as rootActions from 'modules/common/actions';
import {
	attachTag as attachTagIo,
	detachTag as detachTagIo,
	getBuildingsTags,
	getCallPool,
	getTags as getAvailableTags,
} from 'modules/common/io';
import * as salesmanAppContactsActions from 'modules/salesmanApp/contactsPage/actions';
import {
	getBuilding,
	getEncounters,
	getEncounter,
	postClient,
	updateClient,
	getUserTeams,
	getFreeCalendarResources,
	getBuildingSalesTeams,
	deleteClient,
	skipBuilding,
	postFormFill,
	postCall,
	postCalendarResource,
	getCallSurvey,
	postSurveyFill,
	getLead,
	getMarketingLeadSources,
	getAllProducts,
	getSurveyCallReview,
} from './io';
import {
	telClient as _doTelClient,
	leaddeskTalkCallClient as doLeaddeskTalkCallClient,
	leaddeskCallClient as doLeaddeskCallClient,
	soittolinjaCallClient as doSoittolinjaCallClient,
	leaddeskHangup,
	leaddeskTalkHangup,
} from 'io/calls';
import {getCallableClient} from 'utils/calls';
import getSipModule from 'io/sip';
import {getNextBuilding} from '../io';
import {change, blur, reset} from 'redux-form';
import msgs from 'dicts/messages';
import services from 'services';
import createDatePickEffects from 'fragments/calendarResourcePicker/effects';
import createBuildingModalEffects from 'fragments/buildingModalActions/effects';
import createReminderEffects from 'fragments/callReminder/effects';
import {initializeCallReminder as fetchCallReminder} from 'fragments/callReminder/effectHelpers';
import {deleteCallReminder} from 'fragments/callReminder/io';
import * as confirmerActions from 'modules/confirmer/actions';
import {bindToCalendarResourceReservationEvents} from 'fragments/calendarResourcePicker/effectHelpers';
import {medDur, longDur} from 'constants/notifications';
import {getReferrer, getReferrerUrl} from 'utils/url';
import {resolveObject} from 'utils/promises';
import {describeThrow, handledError} from 'utils/errors';
import {addHours} from 'utils/time';
import importGoogleMaps from 'services/importGoogleMaps';
import * as nActions from 'modules/notifications/actions';
import {isPilotUser} from 'utils/perms';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';
import {pushQuery, replacePath} from 'io/history';
import {mergeLeft} from 'ramda';
import {createTopic} from 'services/createPusher';
import {SIP_PROVIDER} from 'constants/caller';

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

// simple local state

let st_comingFromPrevPoolBuilding = false;

const setupChannels = (getState, dispatch) => {
	pusher = services.get('pusher');
	const user = rootSelectors.user(getState());

	const calendarResourcesChannel = pusher.subscribe(
		createTopic('calendarResource', user.accountId),
	);
	bindToCalendarResourceReservationEvents({actions, calendarResourcesChannel, user})(
		getState,
		dispatch,
	);
};

const clearChannels = (getState, _dispatch) => {
	const user = rootSelectors.user(getState());
	if (!user) {
		// User not available in store (e.g. logged out), disconnect from pusher
		pusher.disconnect();
		return;
	}
	pusher.unsubscribe(createTopic('calendarResource', user.accountId));
};

const reserveBuilding = buildingId => (getState, dispatch) => {
	const user = rootSelectors.user(getState());
	const presenceCallBuildingChannel = pusher.subscribe(
		createTopic(`presence-call-building-${buildingId}`, user.accountId),
	);
	return bindToBuildingPresenceChannel(presenceCallBuildingChannel)(getState, dispatch);
};

const releaseBuilding = (buildingId, getState, _dispatch) => {
	const user = rootSelectors.user(getState());
	if (!user) {
		// User not available in store (e.g. logged out), disconnect from pusher
		pusher.disconnect();
		return;
	}
	pusher.unsubscribe(createTopic(`presence-call-building-${buildingId}`, user.accountId));
};

const setActiveClient = clientId => (getState, dispatch) => {
	dispatch(change('callClientForm', 'clientId', clientId));
	dispatch(change('callClientForm', 'contactClientId', clientId));
};

const doTelClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		_doTelClient({client, buildingId: buildingId, appName});
		setActiveClient(client.id)(getState, dispatch);
	};

const doCallClient =
	({client, buildingId, user, building}) =>
	(getState, dispatch) => {
		if (user.defaultCall === 'soittolinja') {
			doSoittolinjaCallClient({client, buildingId, appName});
		} else if (user.defaultCall === 'leaddeskTalk') {
			const clearCallState = () => {
				dispatch(actions._clearCalls());
			};
			doLeaddeskTalkCallClient({
				client,
				buildingId,
				appName,
				onCallEnded: clearCallState,
			});
		} else if (user.defaultCall === 'leaddesk') {
			doLeaddeskCallClient({client, buildingId, appName});
		} else if (user.defaultCall === SIP_PROVIDER.ENIO) {
			const {callClient} = getSipModule({
				provider: SIP_PROVIDER.ENIO,
				isPilot: isPilotUser(user),
			});
			callClient({client, building, user, appName});
		} else if (user.defaultCall === SIP_PROVIDER.SOMIC) {
			const {callClient} = getSipModule({
				provider: SIP_PROVIDER.SOMIC,
				isPilot: false,
			});
			callClient({client, buildingId, appName});
		} else {
			// even this method can trigger a regular tel call if that's what the user has set as their default method
			_doTelClient({client, buildingId, appName});
		}

		setActiveClient(client.id)(getState, dispatch);

		// we offer a hangup button in the UI for old-style leaddesk calls. leaddesk talk doesn't need it because its talk widget and thus its hangup button is within the same UI. not sure about soittolinja, but nobody's requested a hangup button for it.
		if (user.defaultCall === 'leaddesk') {
			dispatch(actions._startedLeaddeskCall());
		} else if (user.defaultCall === 'leaddeskTalk') {
			dispatch(actions._startedLeaddeskTalk());
		}
	};

// NOTE: may crash if used after module destroyed
const tryAutoCall = (getState, dispatch) => {
	const callPool = selectors.activeCallPool(getState());
	const building = selectors.building(getState());
	const encounters = selectors.encounters(getState());
	const clients = selectors.clients(getState());
	const user = rootSelectors.user(getState());

	// not in a call pool
	if (!callPool) return;

	const client = getCallableClient({callPool, building, clients, encounters});

	if (client) {
		if (
			[
				'leaddeskTalk',
				'leaddesk',
				'soittolinja',
				SIP_PROVIDER.ENIO,
				SIP_PROVIDER.SOMIC,
			].includes(user.defaultCall)
		) {
			const user = rootSelectors.user(getState());
			doCallClient({client, buildingId: building.id, building: building, user})(
				getState,
				dispatch,
			);
		} else {
			doTelClient({client, buildingId: building.id})(getState, dispatch);
		}
	}
};

// NOTE: safe to call at any time, but only because userTeams has a valid initial value
const fetchBuildingData =
	({
		buildingId,
		doRefresh = false,
		suppressReservationFailure = false,
		notifyOpts = {},
	}) =>
	(getState, dispatch) => {
		const isSales = rootSelectors.isSalesUser(getState());

		const canAddCalendarResourcesToAnyTeam =
			rootSelectors.canAddCalendarResourcesToAnyTeam(getState());
		// userTeams are always fetched (if necessary) before this routine is called
		// btw: also had the idea of setting up a callback (local variable at the top of the file) here, like "onUserTeamsFetched", which would get called after user teams are fetched. after that we'd proceed getting the free calendar resources (which depend on the team data). not worth the effort though, would get messy and hardly saves any time.
		const userTeams = selectors.userTeams(getState());
		const calResTeamId =
			isSales && !canAddCalendarResourcesToAnyTeam && userTeams.length
				? userTeams[0].id
				: null;

		// if activeTimer isn't "working" type and user has permissions start a new "working" type timer
		const startWorkingTimer =
			rootSelectors.activeTimerRunning(getState()) !== 'working' &&
			rootSelectors.userCanCreateTimeEntries(getState());

		return decorateWithNotifications(
			{
				id: 'init-calls-buildings',
				failureDuration: e =>
					e.causedByReservationFailure || e.causedByNoPermission ? longDur : medDur,
				suppressFailure: suppressReservationFailure
					? e => e.causedByReservationFailure
					: false,
				...notifyOpts,
			},
			Promise.all([
				startWorkingTimer ? dispatch(rootActions._startTimerFromCalls('working')) : null,
				!doRefresh
					? reserveBuilding(buildingId)(getState, dispatch).then(() => {
							dispatch(actions._reservationOk());
					  })
					: Promise.resolve(),
				getBuilding(buildingId).then(building => {
					dispatch(actions._setBuilding(building));
				}),
				getEncounters(buildingId).then(encounters => {
					dispatch(actions._setEncounters(encounters));
				}),
				getFreeCalendarResources(buildingId, calResTeamId).then(cr => {
					dispatch(actions._setFreeCalRes(cr));
				}),
				!(doRefresh || isSales) || canAddCalendarResourcesToAnyTeam
					? getBuildingSalesTeams(buildingId).then(data => {
							dispatch(actions._setSalesTeams(data));
					  })
					: Promise.resolve(),
				fetchCallReminder({actions, buildingId})(getState, dispatch),
				getAllProducts().then(products => {
					dispatch(actions._setProducts(products));
				}),
			]),
		)(getState, dispatch);
	};

// this is a separate helper to increase readability. note that it doesn't return a promise since it may change the page
// NOTE: may crash if used after module destroyed
const doAfterEncounterOperation = buildingNeedsRefresh => (getState, dispatch) => {
	const seq = selectors.seq(getState());

	// we expect referrer to be up-to-date at this point
	const referrer = getReferrer(history.location.search);
	const referrerUrl = getReferrerUrl(history.location.search);

	const activeCallPool = selectors.activeCallPool(getState());
	const currentBuildingId = selectors.building(getState()).id;
	const leaveCallPoolAfterEncountedSaved = selectors.leaveCallPoolAfterEncountedSaved(
		getState(),
	);

	const isInCallPool = referrer === 'callPool' && activeCallPool;

	if (isInCallPool && leaveCallPoolAfterEncountedSaved) {
		// Reset the setting value
		dispatch(actions.setLeaveCallPoolAfterEncountedSaved(false));
		history.push(`/calls/dashboard/`);
	} else if (isInCallPool) {
		dispatch(actions._setFetchingNextBuilding(true));

		decorateWithNotifications(
			{
				id: 'next-building',
				failureStyle: e => (e.causedByNoBuildingsAvailable ? 'info' : 'warning'),
				failureDuration: longDur,
			},
			getNextBuilding(activeCallPool.id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._setFetchingNextBuilding(false));

				if (!buildingNeedsRefresh) {
					// just stop loading if no need to refresh
					dispatch(actions._setBuilding(selectors.building(getState())));
					throw e;
				}

				return (
					fetchBuildingData({buildingId: currentBuildingId, doRefresh: true})(
						getState,
						dispatch,
					)
						// ignore any errors that happen when fetching the refreshed data - the previous error (when fetching the next building) is more relevant
						.catch(() => {})
						.then(() => Promise.reject(e))
				);
			})
			.then(building => {
				// TODO: should add this check to dashboard getNextBuilding too
				if (selectors.seq(getState()) !== seq) {
					throw handledError('seq changed');
				}

				dispatch(actions._setFetchingNextBuilding(false));

				dispatch(actions.getTags(building.id));

				// need to use helper state to communicate between this and the changeBuilding effect that gets executed later. drawback of the arrangement described below.
				st_comingFromPrevPoolBuilding = true;

				// btw: could perform the change here instantly instead of waiting on router + react to trigger changeBuilding. would require calling the stuff inside the handler and changeBuilding manually here, and result in a bit more complexity. not really worth it at this point since it would save < 100 ms, and letting the URL be the primary source works fine here because it doesn't refresh / isn't read often.
				replacePath(
					`/calls/buildings/${building.id}`,
					mergeLeft({
						referrer: 'callPool',
					}),
				);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	} else if (referrer === 'listview') {
		history.push(referrerUrl);
	} else if (referrer === 'freeride') {
		history.push(referrerUrl);
	} else if (referrer === 'salesman-app-contacts') {
		dispatch(salesmanAppContactsActions.updateBuilding(currentBuildingId));
		history.push(referrerUrl);
	} else {
		fetchBuildingData({buildingId: currentBuildingId, doRefresh: true})(
			getState,
			dispatch,
		).catch(catchNonFatalDefault(getState, dispatch));

		const {pathname} = history.location;
		// In freeride application, close the building overlay after saving encounter and refresh the map
		if (pathname === '/calls/freeride') {
			pushQuery(q => ({...q, refreshMap: true}));
		}
	}
};

const datePickEffects = createDatePickEffects({
	namespace,
	actions,
	selectResource: dateId => (getState, dispatch) => {
		dispatch(change('callClientForm', 'calendarResourceId', dateId));
		// this does maybe something important with redux-form, not sure what
		setTimeout(() => {
			dispatch(blur('callClientForm', 'calendarResourceId'));
		});
	},
});

export const {selectCalendarResource} = datePickEffects;

const buildingModalEffects = createBuildingModalEffects({namespace, actions});

export const {removeBuilding, saveBuildingData} = buildingModalEffects;

const reminderEffects = createReminderEffects({namespace, actions});

export const {saveCallReminder, removeCallReminder} = reminderEffects;

export let initialize =
	({buildingId, allowAutoCall = false, leadId = null, callPoolId = null}) =>
	(getState, dispatch) => {
		const seq = selectors.seq(getState());

		setupChannels(getState, dispatch);

		dispatch(actions._setOpenedAt(new Date()));

		dispatch(actions.getTags(buildingId));

		decorateWithNotifications(
			{
				id: 'init-calls-buildings',
				failureDuration: e =>
					e.causedByReservationFailure || e.causedByNoPermission ? longDur : medDur,
			},
			Promise.all([
				(rootSelectors.isSalesUser(getState())
					? getUserTeams({includeTeamUsers: true}).then(teams => {
							dispatch(actions._setUserTeams(teams));
					  })
					: Promise.resolve()
				).then(() =>
					fetchBuildingData({buildingId, notifyOpts: {disableEverything: true}})(
						getState,
						dispatch,
					),
				),
				getCallSurvey(buildingId).then(survey => {
					dispatch(actions._setSurvey(survey));
				}),
				getSurveyCallReview().then(survey => {
					if (survey) dispatch(actions._setSurveyCallReview(survey));
				}),
				leadId
					? getLead(leadId).then(l => {
							dispatch(actions._setLead(l));
					  })
					: Promise.resolve(),
				getMarketingLeadSources().then(sources => {
					dispatch(actions._setMarketingLeadSources(sources));
				}),
				getAvailableTags({
					type: TYPE_BUILDING,
					getAllTags: false,
					view: appName,
				}).then(({data: tags}) => {
					dispatch(actions._setAvailableTags(tags));
				}),
				callPoolId
					? getCallPool(callPoolId).then(callPool => {
							dispatch(actions._setActiveCallPool(callPool));
					  })
					: Promise.resolve(),
			]),
		)(getState, dispatch)
			// perform auto call after the entire page has loaded. initially added a one-off check after building & call pool loads to run after both are ready, but it was more complicated and didn't make a big difference in practice.
			.then(() => {
				if (allowAutoCall && seq === selectors.seq(getState())) {
					tryAutoCall(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));

		// import Google Maps for street view. this can be done separately of the other stuff.
		decorateWithNotifications(
			{id: 'import-maps'},
			importGoogleMaps().catch(
				describeThrow(intl.formatMessage({id: 'Error loading map'})),
			),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	};
initialize = creator('initialize', initialize);

export let changeBuilding =
	({nextId, currentId}) =>
	(getState, dispatch) => {
		const seq = selectors.seq(getState());

		const comingFromPrev = st_comingFromPrevPoolBuilding;
		// reset ASAP
		st_comingFromPrevPoolBuilding = false;

		releaseBuilding(currentId, getState, dispatch);

		dispatch(actions._setOpenedAt(new Date()));

		fetchBuildingData({buildingId: nextId, suppressReservationFailure: comingFromPrev})(
			getState,
			dispatch,
		)
			.catch(e => {
				// getting the next pool building may sometimes return buildings that are actually reserved on the pusher channel (this is a legit case since the backend doesn't keep track of every reserved building channel). if that happens, return to the after-encounter routine, thus trying to find a new building.
				// note: also mark the building skipped, since in some cases the backend might return the same building again. skipping should have no dramatic side effects other than excluding it from call pools momentarily.
				if (
					selectors.seq(getState()) === seq &&
					comingFromPrev &&
					e.causedByReservationFailure
				) {
					skipBuilding(nextId).then(() => {
						doAfterEncounterOperation(true)(getState, dispatch);
					});
				}
				throw e;
			})
			// see the other comment about auto-call
			.then(() => {
				if (selectors.seq(getState()) === seq && comingFromPrev) {
					tryAutoCall(getState, dispatch);
				}

				dispatch(actions.getTags(nextId));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
changeBuilding = creator('changeBuilding', changeBuilding);

export let getEncounterData = encounterId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-encounter-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(encounterId),
	)(getState, dispatch)
		.then(encounter => {
			dispatch(actions._setEncounterData(encounter));
		})
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getEncounterData = creator('getEncounterData', getEncounterData);

export let saveCallData =
	({call, calendarResource, formFill, survey, surveyCallReview}) =>
	(getState, dispatch) => {
		// validation for "not reached" button, because it doesn't submit form
		if (!call.clientId) {
			dispatch(
				nActions.warning({
					id: 'form-submit-fail',
					message: intl.formatMessage({id: 'Select called client'}),
					duration: medDur,
				}),
			);
			dispatch(actions._opFailed());
			return;
		}

		const seq = selectors.seq(getState());

		const reminder = selectors.callReminder(getState());
		const reminderOld = reminder && addHours(reminder.createdAt, 1) < new Date();

		const postIfNeeded = (item, relationName, postFunc) =>
			item ? postFunc(item).then(({id}) => ({[relationName]: id})) : Promise.resolve({});

		const referrer = getReferrer(history.location.search);
		const source = referrer === 'salesman-app-contacts' ? 'salesmanApp' : null;

		decorateWithNotifications(
			{
				id: 'save-call',
				failureStyle: e => (e.causedByDateTaken ? 'warning' : 'error'),
				failureDuration: longDur,
				success: intl.formatMessage({id: msgs.saved}),
				loading: intl.formatMessage({id: msgs.processing}),
			},
			Promise.all([
				resolveObject({
					rel1: postIfNeeded(
						calendarResource,
						'calendarResourceId',
						postCalendarResource,
					),
					rel2: postIfNeeded(formFill, 'formFillId', postFormFill),
				})
					.then(({rel1, rel2}) => postCall({...call, ...rel1, ...rel2, source}))
					.catch(e => {
						if (e.causedByDateTaken) {
							dispatch(change('callClientForm', 'calendarResourceId', null));
							dispatch(actions._calendarResourceReserved(call.calendarResourceId));
						}
						throw e;
					})
					.then(call =>
						Promise.all([
							survey
								? // post survey after call saved, because we need call id for the survey's sourceId
								  postSurveyFill({...survey, sourceId: call.data.id})
								: Promise.resolve(null),
							surveyCallReview
								? postSurveyFill({...surveyCallReview, sourceId: call.data.id})
								: Promise.resolve(null),
						]),
					),
				reminderOld && call.reached ? deleteCallReminder(reminder.id) : Promise.resolve(),
			]),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._encounterSaved());

				if (selectors.seq(getState()) === seq) {
					doAfterEncounterOperation(true)(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveCallData = creator('saveCallData', saveCallData);

export let skip = () => (getState, dispatch) => {
	const seq = selectors.seq(getState());

	decorateWithNotifications(
		{id: 'skip-b'},
		skipBuilding(selectors.building(getState()).id),
	)(getState, dispatch)
		.then(() => {
			if (selectors.seq(getState()) === seq) {
				doAfterEncounterOperation(false)(getState, dispatch);
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
skip = creator('skip', skip);

export let telClient = client => (getState, dispatch) => {
	const building = selectors.building(getState());

	doTelClient({client, buildingId: building.id})(getState, dispatch);
};
telClient = creator('telClient', telClient);

// TODO: This is not actually leaddesk talk, as we handle multiple clients here.
// Need to refactor this to be more generic.
export let leaddeskCallClient = client => (getState, dispatch) => {
	const building = selectors.building(getState());
	const user = rootSelectors.user(getState());

	doCallClient({client, buildingId: building.id, building, user})(getState, dispatch);
};
leaddeskCallClient = creator('leaddeskCallClient', leaddeskCallClient);

// so far we only have leaddesk calls that are stoppable, so no need to accept a call type parameter
export let hangupCall = () => (getState, dispatch) => {
	if (selectors.leaddeskCallInProgress(getState())) {
		leaddeskHangup();
	} else if (selectors.leaddeskTalkInProgress(getState())) {
		leaddeskTalkHangup();
	}
	dispatch(actions._clearCalls());
};
hangupCall = creator('hangupCall', hangupCall);

// TODO: wtf? this creates AND updates, and buildingId decides that?
export let createClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			!buildingId ? updateClient(client) : postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(client => {
				dispatch(
					actions._updateClients({
						client: client,
						type: !buildingId ? 'update' : 'add',
					}),
				);

				// note: if we turn client management into a fragment then these need to be handled through an optional callback
				if (!buildingId) {
					dispatch(change('callClientForm', 'clientId', null));
					dispatch(change('callClientForm', 'contactClientId', null));
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createClient = creator('createClient', createClient);

export let removeClient =
	({id, reason}) =>
	(getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._setProcessing(true));
			decorateWithNotifications(
				{
					id: 'delete-client',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Client deleted'}),
				},
				deleteClient({id, reason}),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._setProcessing(false));
					throw e;
				})
				.then(() => {
					const clients = selectors.clients(getState());
					const isLastClient = clients.length === 1 && clients[0].id === id;
					if (isLastClient) {
						dispatch(change('callClientForm', 'clientId', id));
						dispatch(change('callClientForm', 'contactClientId', null));
						dispatch(change('callClientForm', 'state', 'contacted'));
						dispatch(change('callClientForm', 'reason', 'noBuildingContactInfo'));
						const reasonMappings = rootSelectors.reasonMappings(getState());
						const reasonMappingId = reasonMappings.find(
							({key, state}) =>
								state === 'notReached' && key === 'clientContactInformationDeleted',
						);
						dispatch(change('callClientForm', 'reasonMappingId', reasonMappingId?.id));
					} else {
						// note: if we turn client management into a fragment then these need to be handled through an optional callback
						dispatch(change('callClientForm', 'clientId', null));
						dispatch(change('callClientForm', 'contactClientId', null));
					}

					dispatch(actions._removeClient(id));
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete client?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};
removeClient = creator('removeClient', removeClient);

export let setClientSwedish = client => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'set-client-swedish',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Saved'}),
		},
		updateClient(client),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(client => {
			dispatch(
				actions._updateClients({
					client: client,
					type: 'update',
				}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
setClientSwedish = creator('setClientSwedish', setClientSwedish);

export let updateFreeCalendarResources = () => (getState, dispatch) => {
	const buildingId = selectors.building(getState()).id;
	const isSales = rootSelectors.isSalesUser(getState());

	const canAddCalendarResourcesToAnyTeam = rootSelectors.canAddCalendarResourcesToAnyTeam(
		getState(),
	);
	const userTeams = selectors.userTeams(getState());
	const calResTeamId =
		isSales && !canAddCalendarResourcesToAnyTeam && userTeams.length
			? userTeams[0].id
			: null;

	decorateWithNotifications(
		{
			id: 'update-free-cal-res',
			failureStyle: 'warning',
		},
		getFreeCalendarResources(buildingId, calResTeamId),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setFreeCalResLoading(false));
			throw e;
		})
		.then(cr => {
			dispatch(actions._setFreeCalRes(cr));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateFreeCalendarResources = creator(
	'updateFreeCalendarResources',
	updateFreeCalendarResources,
);

export let resetCallForm = () => (getState, dispatch) => {
	dispatch(actions.selectCalendarResource(null));
	dispatch(reset('callClientForm'));
};
resetCallForm = creator('resetCallForm', resetCallForm);

export let destroy = buildingId => (getState, dispatch) => {
	releaseBuilding(buildingId, getState, dispatch);
	clearChannels(getState, dispatch);
};
destroy = creator('destroy', destroy);

export let getTags = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-tags',
			failureStyle: 'warning',
		},
		getBuildingsTags(id),
	)(getState, dispatch)
		.then(tags => {
			dispatch(actions._getTags(tags));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getTags = creator('getTags', getTags);

export let attachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'attach-tag',
			failureStyle: 'error',
		},
		attachTagIo(params),
	)(getState, dispatch)
		.then(() => dispatch(actions._attachTag()))
		.then(() => dispatch(actions.getTags(buildingId)))
		.catch(catchNonFatalDefault);
};

attachTag = creator('attachTag', attachTag);

export let detachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'detach-tag',
			failureStyle: 'error',
		},
		detachTagIo(params),
	)(getState, dispatch)
		.catch(catchNonFatalDefault)
		.then(() => dispatch(actions._detachTag()))
		.then(() => dispatch(actions.getTags(buildingId)));
};

detachTag = creator('detachTag', detachTag);
