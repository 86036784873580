import {
	evolve,
	assoc,
	pick,
	merge,
	find,
	contains,
	over,
	lensPath,
	prop,
	sortBy,
	any,
} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {
	getWeekBoundaries,
	increaseHour,
	getHourBoundaries,
	setUtcHour,
	toLocaleDateString,
} from 'utils/time';
import {isSalesAssignment} from 'utils/calendarResources';
import {salesUserTypes} from './constants';
import {logInfo} from 'io/errors';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			weekSample: weekSample => (weekSample ? new Date(Number(weekSample)) : new Date()),
			salesmanId: salesmanId => (salesmanId ? Number(salesmanId) : null),
			includeTrashedEvents: includeTrashedEvents => includeTrashedEvents === 'true',
		},
		query,
	);
	return {calendarQuery: replace(vals, initState.calendarQuery)};
};

export const formatUrlQuery = ({calendarQuery}) => {
	const {weekSample, salesmanId, includeTrashedEvents} = calendarQuery;

	return {
		includeTrashedEvents,
		weekSample: weekSample.getTime(),
		salesmanId: salesmanId ? String(salesmanId) : '',
	};
};

export const getUserType = user =>
	find(r => contains(r.type, salesUserTypes), user.roles) ? 'sales' : 'clientAcquisition';

export const formatFetchableCalendarQuery = ({
	calendarQuery,
	salesmanId,
	currentUser,
	selectableUsers = [],
}) => {
	const {weekSample, includeTrashedEvents} = calendarQuery;
	const [dateFrom, dateTo] = getWeekBoundaries(weekSample).map(d => d.toJSON());

	let selectedUser = currentUser;

	if (salesmanId !== currentUser.id) {
		const user = selectableUsers.find(u => u.id === salesmanId);
		if (user) {
			selectedUser = user;
		} else {
			const err = new Error(
				`Trying to fetch calendar resources in Calendar for undefined user (id: ${salesmanId}). Using current user as fallback.`,
			);
			logInfo(err);
		}
	}

	const userType = getUserType(selectedUser);

	const userQuery = {
		clientAcquisition: {reserverId: selectedUser.id},
		sales: {salesmanId: selectedUser.id},
	}[userType];

	// prettier-ignore
	const salesUserQuery = userType === 'sales' && selectedUser.id === currentUser.id ? {
    checkVisibilityForSalesman: true,
  } : {};

	return {
		dateFrom,
		dateTo,
		includeTrashedEvents,
		...userQuery,
		...salesUserQuery,
	};
};

export const formatCalendarResourceAddFormInput = (salesmanId, team) => {
	return {
		areas: [],
		salesmanId,
		teamId: team ? team.id : null,
	};
};

export const formatCalendarResourceAddFormOutput = ({form, slotSelection}) => {
	const {areas, teamId, salesmanId} = form;
	const {date, hour} = slotSelection;
	const _areas = areas.map(a => ({id: a}));
	const time = increaseHour(date, hour);
	const [dateFrom, dateTo] = getHourBoundaries(time);

	return {areas: _areas, dateFrom, dateTo, teamId, salesmanId};
};

export const formatCalendarResourceEditFormInput = resource => {
	if (!resource) return {};

	return {
		areas: resource.areas.map(a => a.id),
		date: resource.dateFrom,
		products: resource.products?.map(p => p.id) || [],
	};
};

export const formatCalendarEventEditFormInput = event => {
	if (!event) return {};
	return {
		startsAt: new Date(event.startsAt),
		endsAt: new Date(event.endsAt),
		teamId: event.teamId,
		title: event.title,
		description: event.description,
		resolved: event.resolved,
		showOnTeamCalendar: event.showOnTeamCalendar,
	};
};

export const formatCalendarResourceEditFormOutput = ({form, selectedItem}) => {
	const areas = form.areas ? form.areas.map(a => ({id: a})) : [];

	const [dateFrom, dateTo] = form.date
		? selectedItem.type === 'bonus'
			? [setUtcHour(form.date, 0, 0, 0, 0), setUtcHour(form.date, 0, 0, 0, 0)]
			: getHourBoundaries(form.date)
		: [null, null];

	const products = form.products ? form.products.map(a => ({id: a})) : [];

	return {
		id: selectedItem.id,
		areas,
		dateFrom,
		dateTo,
		products,
	};
};

export const formatCalendarEventEditFormOutput = ({form, selectedItem}) => {
	const {startsAt, endsAt, resolved, showOnTeamCalendar, title, description} = form;

	return {
		id: selectedItem.id,
		startsAt,
		endsAt,
		resolved,
		showOnTeamCalendar,
		title,
		description,
	};
};

export const formatCalendarEventCreateFormOutput = ({form}) => {
	const {
		startsAt,
		endsAt,
		user,
		team,
		title,
		description,
		showOnTeamCalendar = false,
		type,
		building,
		clientId,
	} = form;

	return {
		startsAt,
		endsAt,
		title,
		description,
		showOnTeamCalendar,
		clientId,
		userId: user.id,
		teamId: team.id,
		calendarEventTypeId: type.id,
		buildingId: building?.id || null,
		resolved: false,
	};
};

export const formatEncounterAddFormInput = (user, salesmanId, team) => {
	return {
		reserver: user,
		salesmanId,
		teamId: team ? team.id : null,
		requestDate: toLocaleDateString(new Date()),
		appointmentType: 'call',
		ihSource: 'call',
	};
};

export const formatCalendarEncounterAddFormOutput = ({form, slotSelection}) => {
	const {
		building,
		clientId,
		reserver,
		salesmanId,
		teamId,
		requestSource,
		requestDate,
		isRequest,
		appointmentType,
		ihSource,
	} = form;
	const {date, hour} = slotSelection;
	const time = increaseHour(date, hour);
	const [dateFrom, dateTo] = getHourBoundaries(time);

	return {
		buildingId: building.id,
		clientId,
		reserverId: reserver.id,
		salesmanId,
		teamId,
		dateFrom,
		dateTo,
		appointmentType,
		ihSource,
		requestSource: isRequest ? requestSource : null,
		requestDate: isRequest ? requestDate : null,
	};
};

export const formatItemReservationCancellationOutput = (item, cancelled) => {
	let newItem = null;

	if (item.bonus && cancelled) {
		const date = setUtcHour(item.dateFrom, 0, 0, 0, 0);
		newItem = merge(item, {
			dateFrom: date,
			dateTo: date,
			salesmanId: isSalesAssignment(item) ? item.salesmanId : null,
		});
	} else {
		newItem = assoc('cancelled', cancelled, item);
	}

	return pick(['id', 'cancelled', 'dateFrom', 'dateTo', 'salesmanId'], newItem);
};

// PERM: ignore-visible-for-salesman
export const isSalesmanUser = user => !!user.roles.find(r => r.type === 'salesman');

// PERM: calendar-create-free-times
export const isProjectSalesUser = user =>
	any(r => contains(r.slug, 'projektimyynti'), user.roles);

export const sortTeamUsers = teams => {
	if (!teams.length) return [];
	return over(lensPath([0, 'users']), sortBy(prop('lastName')), teams);
};

export const formatCalendarResourceDateFormInput = resource => {
	if (!resource) return {};

	return {
		date: resource.dateFrom,
	};
};

export const formatCalendarResourceDateFormOutput = ({form, item, userId}) => {
	const [dateFrom, dateTo] = form.date ? getHourBoundaries(form.date) : [null, null];

	return {
		id: item.id,
		salesmanId: userId,
		dateFrom,
		dateTo,
	};
};

export const formatReminderAddFormInput = slotSelection => {
	const date = new Date(slotSelection.date).setHours(slotSelection.hour);

	return {callAt: new Date(date)};
};

export const formatClientFormOutput = form => {
	return {...form, emailMarketing: form.emailMarketing ? new Date() : null};
};

export const formatCalendarEventAddFormInput = (slotSelection, user, team) => {
	let startsAt = null;
	let endsAt = null;

	if (slotSelection?.date) {
		startsAt = new Date(slotSelection.date);
		startsAt.setHours(slotSelection.hour);
		endsAt = new Date(slotSelection.date);
		endsAt.setHours(slotSelection.hour + 1);
	}

	return {startsAt, endsAt, team, user};
};
