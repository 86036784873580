import {state as lifecycle} from 'fragments/lifecycle';
import createReminderState from 'fragments/callReminder/state';

const reminderState = createReminderState({});

export default {
	...lifecycle,
	...reminderState,
	initialized: false,
	processing: false,
	userTeams: [],
	calResLoading: false,
	calendarQuery: {
		weekSample: new Date(),
		salesmanId: null,
		includeTrashedEvents: false,
	},
	hourItems: [],
	hourlessItems: [],
	itemsSource: [],
	itemsViewerOpen: false,
	itemsCreatorOpen: false,
	slotSelection: {
		type: null,
		date: null,
		iDay: null,
		hour: null,
	},
	selectedItem: null,
	previewableEncounter: null,
	dateEditorOpen: false,
	buildingCalendarResources: [],
	buildingCalendarResourcesLoading: false,
	bonusItemReservationModalOpen: false,
	bonusItemBeingReserved: null,
	callReminderBuilding: {},
	marketingLeadSources: [],
	clientEditorOpen: false,
	users: [],
	lockFrom: null,
	calendarEventTypes: [],
};
