import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');
export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const getTags = creator('getTags');
export const _getTags = creator('_getTags', P.Array);

export const openTag = creator('openTag', P.Object);

export const openTagModal = creator('openTagModal');
export const closeTagModal = creator('closeTagModal');

export const createTag = creator('createTag');

export const updateTag = creator('updateTag', P.Number);

export const deleteTag = creator('deleteTag', P.Number);

export const setShowDeleted = creator('setShowDeleted', P.Boolean);
export const _setShowDeleted = creator('_setShowDeleted', P.Boolean);

export const restoreTag = creator('restoreTag', P.Number);

export const recheckQuery = creator('recheckQuery');

export const searchUsers = creator('searchUsers', P.Object);

export const searchTeams = creator('searchTeams', P.Object);
