import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as commonSelectors from 'modules/common/selectors';
// import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage} from 'io/app';
import {canReadEncounterable} from 'modules/overviewApp/encounterableEndingReasonsPage/selectors';

const creator = effect(namespace);

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Overview [app]')(getState, dispatch);
	const user = commonSelectors.user(getState());
	const doInitialize = () => {
		dispatch(actions._initialize());
	};

	/* 2024-01-27:
	 *
	 * We want show 'ending-reasons' page to any user who has either full access to overview app or specific read access to the encounterables page.
	 * Having read access to ending reasons is enough to access the overview app (but other content will be authorized separately).
	 *
	 * Hence, this special case for the overview app.
	 */
	const canAccessEndingReasons = canReadEncounterable(getState());
	if (canAccessEndingReasons) {
		doInitialize();
		return;
	}

	ensureAccess('overview', user)(getState, dispatch)
		.then(() => {
			doInitialize();
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);
