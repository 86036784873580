import {CALL_STATUS} from 'io/sip/constants';

export const callStatusMessage = callStatus => {
	let callStatusMsg = '';
	switch (callStatus) {
		case CALL_STATUS.IN_CALL:
			callStatusMsg = 'Call duration';
			break;
		case CALL_STATUS.RINGING:
			callStatusMsg = 'Calling [waiting for answer]';
			break;
		case CALL_STATUS.TRYING:
			callStatusMsg = 'Connecting call';
			break;
		case CALL_STATUS.OK:
			// Use 'Connecting call' instead of 'Connection established' as for user it's irrelevent information
			callStatusMsg = 'Connecting call';
			break;
		case CALL_STATUS.MEDIA_ADDED:
			// Use 'Connecting call' instead of 'Connection established [call]' as for user it's irrelevent information
			callStatusMsg = 'Connecting call';
			break;
		case CALL_STATUS.BUSY_HERE:
			callStatusMsg = 'Busy [call in progress]';
			break;
		case CALL_STATUS.CALL_TERMINATED:
			callStatusMsg = 'Call terminated';
			break;
		case CALL_STATUS.TERMINATING:
			callStatusMsg = 'Call terminating...';
			break;
		case CALL_STATUS.NOT_FOUND:
			callStatusMsg = 'Phonenumber not found';
			break;
		case CALL_STATUS.REQUEST_TERMINATED:
			callStatusMsg = 'Request Terminated [calling]';
			break;
		case CALL_STATUS.TEMPORARILY_UNAVAILABLE:
			callStatusMsg = 'Temporarily Unavailable [calling]';
			break;
		default:
			callStatusMsg = 'No active call';
	}
	return callStatusMsg;
};
