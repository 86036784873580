import {useState, useEffect} from 'react';

export const useStateDebounced = (value, initialValue, delay = 300) => {
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => clearTimeout(timer);
	}, [value, delay]);

	return debouncedValue;
};
