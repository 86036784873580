import {reset, formValueSelector, change} from 'redux-form';
import {effect} from 'utils/redux';
import {mergeLeft, assocPath, uniqBy, prop, min, max, merge, pipe} from 'ramda';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import * as confirmerActions from 'modules/confirmer/actions';
import * as commonSelectors from 'modules/common/selectors';
import * as calendarSelectors from 'modules/calendarApp/common/selectors';
import msgs from 'dicts/messages';
import services from 'services';
import {
	getUserTeams,
	getCalendarResources,
	getBonusCalendarResources,
	deleteCalendarResource,
	postCalendarResource,
	putCalendarResource,
	getBuildings,
	searchUsers as ioSearchUsers,
	searchTeams as ioSearchTeams,
	getUsers,
	getCalendarResourceEncounter,
	getMarketingLeadSources,
	checkTeamCalendarVisibility,
	postClient,
} from './io';
import {
	getCalendarEvent,
	getCalendarEvents,
	putCalendarEvent,
	postCalendarEvent,
	getCalendarEventTypes,
	deleteCalendarEvent as deleteCalendarEventIo,
	restoreCalendarEvent as restoreCalendarEventIo,
	getProducts,
} from 'modules/common/io';
import {initializeCallReminders as fetchCallReminders} from 'fragments/callReminder/effectHelpers';
import {decorateWithNotifications} from 'io/app';
import {parseUrlQuery} from './utils';
import {replaceQuery, getQuery} from 'io/history';
import {appointmentsHourRange} from 'constants/domain';
import {setHour, getHourBoundaries} from 'utils/time';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import createReminderEffects from 'fragments/callReminder/effects';

const history = services.get('history');

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const calendarEncounterAddFormVal = formValueSelector('calendarEncounterAddForm');

const fetchCalendarResources = (getState, dispatch) => {
	const fetchableQuery = selectors.calendarQueryFetchable(getState());
	const team = selectors.team(getState());
	const userId = selectors.salesmanId(getState());
	const userType = selectors.userType(getState());
	const {dateFrom, dateTo} = fetchableQuery;

	return Promise.all([
		getCalendarResources(fetchableQuery),
		fetchCallReminders({actions, userId, dateFrom, dateTo})(getState, dispatch),
		userType === 'sales' && !!team
			? getBonusCalendarResources({...fetchableQuery, teamId: team.id})
			: Promise.resolve([]),
		getCalendarEvents({
			...fetchableQuery,
			userId: userId,
			withTrashed: fetchableQuery?.includeTrashedEvents === true,
		}),
	]).then(res => {
		const resources = uniqBy(prop('id'), res[0].concat(res[2]).concat(res[3]));
		dispatch(actions._setCalendarResources(resources));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {calendarQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateCalendarQuery(calendarQuery));

	// set current user as active salesman
	if (!selectors.salesmanId(getState())) {
		const userId = commonSelectors.user(getState()).id;
		dispatch(actions._updateCalendarQuery({salesmanId: userId}));
	}

	const activeOrganizationId = commonSelectors.activeOrganizationId(getState());

	// fetch certain users to show in user selection if the current user has permissions for it
	let userRoleTypes = [];

	if (calendarSelectors.showOrganizationSalesmen(getState())) {
		userRoleTypes = [...userRoleTypes, 'salesman', 'salesmanager'];
	}

	if (calendarSelectors.showOrganizationTelemen(getState())) {
		userRoleTypes = [...userRoleTypes, 'teleman'];
	}

	const showTeamUsers = calendarSelectors.showTeamUsers(getState());

	decorateWithNotifications(
		{
			id: 'init-calendar',
			failureStyle: 'error',
		},
		Promise.all([
			Promise.all([
				getUserTeams(getState, dispatch).then(teams => {
					dispatch(actions._setUserTeams(teams));
					return teams;
				}),
				userRoleTypes.length
					? getUsers({
							organizationId: activeOrganizationId,
							roleTypes: userRoleTypes,
					  }).then(users => {
							dispatch(actions._setUsers(users));
					  })
					: Promise.resolve(null),
			]).then(([teams]) =>
				Promise.all([
					fetchCalendarResources(getState, dispatch),
					showTeamUsers && teams.length
						? checkTeamCalendarVisibility(teams[0].id).then(({lockFrom}) =>
								dispatch(actions._setLockFrom(lockFrom)),
						  )
						: Promise.resolve(null),
				]),
			),
			getMarketingLeadSources().then(sources => {
				dispatch(actions._setMarketingLeadSources(sources));
			}),
			getProducts().then(products => {
				dispatch(actions._setProducts(products));
			}),
			getCalendarEventTypes({_limit: 999}).then(eventTypes => {
				dispatch(actions._setCalendarEventTypes(eventTypes));
			}),
		]),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateCalendarResources = () => (getState, dispatch) => {
	replaceQuery(mergeLeft(selectors.urlQuery(getState())));
	decorateWithNotifications(
		{
			id: 'update-calendar-resources',
			failureStyle: 'error',
		},
		fetchCalendarResources(getState, dispatch),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarResources = creator('updateCalendarResources', updateCalendarResources);

const _removeCalendarResources = resources => (getState, dispatch) => {
	const successMsg = resources.length > 1 ? 'Entries deleted' : 'Entry deleted';
	dispatch(actions._startOp());

	return decorateWithNotifications(
		{
			id: 'remove-calendar-resources',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: successMsg}),
			failureStyle: 'error',
		},
		Promise.all(resources.map(r => deleteCalendarResource(r.id))),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._opOk());
			dispatch(actions._removeCalendarResources(resources));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let removeCalendarResources = resources => (getState, dispatch) => {
	const onConfirm = () => {
		_removeCalendarResources(resources)(getState, dispatch);
	};

	if (resources.length > 1) {
		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete all selected entries?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	} else {
		_removeCalendarResources(resources)(getState, dispatch);
	}
};
removeCalendarResources = creator('removeCalendarResources', removeCalendarResources);

export let onReminderClick = reminder => (getState, dispatch) => {
	const activeOrganizationId = commonSelectors.activeOrganizationId(getState());

	const referrerUrl = createReferrerUrl(history.location);
	const url =
		activeOrganizationId === 5
			? `/project-sales/condo/${reminder.buildingId}${encodeQuery({
					referrer: 'calendar',
					referrerUrl,
			  })}`
			: reminder.calendarResourceId
			? `/sales/${reminder.calendarResourceId}${encodeQuery({
					referrer: 'calendar',
					referrerUrl,
			  })}`
			: reminder.buildingId
			? `/calls/buildings/${reminder.buildingId}${encodeQuery({
					referrer: 'calendar',
					referrerUrl,
			  })}`
			: null;

	if (url) {
		window.location.assign(url);
	}
};

export let createCalendarResources = resources => (getState, dispatch) => {
	const successMsg = resources.length > 1 ? 'Entries saved' : 'Entry saved';

	decorateWithNotifications(
		{
			id: 'create-free-calendar-resources',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: successMsg}),
			failureStyle: 'error',
		},
		Promise.all(resources.map(r => postCalendarResource(r))),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(newResources => {
			dispatch(actions._opOk());
			dispatch(actions._addCalendarResources(newResources));
			dispatch(actions.toggleItemsViewer(true));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createCalendarResources = creator('createCalendarResources', createCalendarResources);

export let updateCalendarResource = resource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-calendar-resource',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar entry saved'}),
			failureStyle: 'error',
		},
		putCalendarResource(resource),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(updatedResource => {
			dispatch(actions._opOk());
			dispatch(actions._resetEditors());
			dispatch(actions._updateCalendarResource(updatedResource));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarResource = creator('updateCalendarResource', updateCalendarResource);

export let updateCalendarEvent = event => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-calendar-event',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar event saved'}),
			failureStyle: 'error',
		},
		putCalendarEvent(event),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(updatedResource => {
			getCalendarEvent(updatedResource.id).then(event => {
				dispatch(actions._opOk());
				dispatch(actions._resetEditors());
				dispatch(actions._updateCalendarEvent(event));
			});
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarEvent = creator('updateCalendarEvent', updateCalendarEvent);

export let toggleItemsCreator = () => (getState, dispatch) => {
	dispatch(reset('calendarResourceAddForm'));
	dispatch(reset('calendarEncounterAddForm'));
};
toggleItemsCreator = creator('toggleItemsCreator', toggleItemsCreator);

export let searchBuildings =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-buildings',
				failureStyle: 'warning',
			},
			getBuildings({_q: text, _limit: 8, include: 'clients'}),
		)(getState, dispatch)
			.then(buildings => {
				callback(buildings);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchBuildings = creator('searchBuildings', searchBuildings);

export let searchUsers =
	({text, callback, query = {}}) =>
	(getState, dispatch) => {
		const org = commonSelectors.activeOrganization(getState());
		decorateWithNotifications(
			{
				id: 'search-buildings',
				failureStyle: 'warning',
			},
			ioSearchUsers({_q: text, _limit: 999, organization: org.id, ...query}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchUsers = creator('searchUsers', searchUsers);

export let searchTeams =
	({text, callback}) =>
	(getState, dispatch) => {
		const org = commonSelectors.activeOrganization(getState());
		decorateWithNotifications(
			{
				id: 'search-teams',
				failureStyle: 'warning',
			},
			ioSearchTeams({_q: text, _limit: 5, organization: org.id}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchTeams = creator('searchTeams', searchTeams);

export let searchCalendarEventTypes =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-calendar-event-types',
				failureStyle: 'warning',
			},
			getCalendarEventTypes({_limit: 999}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchCalendarEventTypes = creator('searchCalendarEventTypes', searchCalendarEventTypes);

export let getPreviewableEncounter = resource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-previewable-encounter',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.loading}),
		},
		getCalendarResourceEncounter(resource.id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(enc => {
			const encounter = assocPath(['source', 'calendarResource'], resource, enc);
			dispatch(actions._setPreviewableEncounter(encounter));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getPreviewableEncounter = creator('getPreviewableEncounter', getPreviewableEncounter);

export let fetchBuildingCalendarResources = buildingId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-building-calendar-resources',
			failureStyle: 'warning',
		},
		getCalendarResources({buildingId, searchByPreviousDates: false}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(resources => {
			dispatch(actions._setBuildingCalendarResources(resources));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchBuildingCalendarResources = creator(
	'fetchBuildingCalendarResources',
	fetchBuildingCalendarResources,
);

export let setBonusItemBeingReserved = item => (getState, dispatch) => {
	const nextHour = pipe(
		max(appointmentsHourRange[0]),
		min(appointmentsHourRange[1]),
	)(new Date().getHours() + 2);

	const d = setHour(item.dateFrom, nextHour, 0, 0, 0);
	const [dateFrom, dateTo] = getHourBoundaries(d);
	const newItem = merge(item, {dateFrom, dateTo});

	dispatch(actions._setBonusItemBeingReserved(newItem));
};
setBonusItemBeingReserved = creator(
	'setBonusItemBeingReserved',
	setBonusItemBeingReserved,
);

export let reserveBonusItem = resource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'reserve-bonus-item',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar entry saved'}),
			failureStyle: 'error',
		},
		putCalendarResource(resource),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(updatedResource => {
			dispatch(actions._opOk());
			dispatch(actions._updateCalendarResource(updatedResource));
			dispatch(actions.closeBonusItemReservationModal());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
reserveBonusItem = creator('reserveBonusItem', reserveBonusItem);

const reminderEffects = createReminderEffects({namespace, actions});
export const {saveCalendarCallReminder, removeCallReminder, setReminderSeen} =
	reminderEffects;

export let createClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'create-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(newClient => {
				const selectedBuilding = calendarEncounterAddFormVal(getState(), 'building');
				const newBuilding = {
					...selectedBuilding,
					clients: {
						data: [...selectedBuilding.clients.data, newClient],
					},
				};
				dispatch(change('calendarEncounterAddForm', 'building', newBuilding));
				dispatch(change('calendarEncounterAddForm', 'clientId', newClient.id));
				dispatch(actions._clientSaved());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createClient = creator('createClient', createClient);

export let createCalendarEvent = event => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'create-calendar-event',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar event saved'}),
			failureStyle: 'error',
		},
		postCalendarEvent(event),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(newEvent => {
			getCalendarEvent(newEvent.id).then(newEvent => {
				dispatch(actions._opOk());
				dispatch(actions._addCalendarResources([newEvent]));
				dispatch(actions.toggleItemsViewer(true));
			});
		});
};
createCalendarEvent = creator('createCalendarEvent', createCalendarEvent);

export let deleteCalendarEvent = event => (getState, dispatch) => {
	const currentQuery = selectors.calendarQuery(getState());
	const onConfirm = () => {
		deleteCalendarEventIo(event.id).then(() => {
			dispatch(actions._removeCalendarResources([event]));
			if (currentQuery.includeTrashedEvents) {
				dispatch(
					actions._addCalendarResources([
						{...event, deletedAt: new Date().toISOString()},
					]),
				);
			}
			dispatch(actions.setSelectedItem(null));
		});
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete calendar event?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteCalendarEvent = creator('deleteCalendarEvent', deleteCalendarEvent);

export let restoreCalendarEvent = event => (getState, dispatch) => {
	const onConfirm = () => {
		restoreCalendarEventIo(event.id).then(newEvent => {
			getCalendarEvent(newEvent.id).then(newEvent => {
				dispatch(actions._opOk());
				dispatch(actions._removeCalendarResources([event]));
				dispatch(actions._addCalendarResources([newEvent]));
				dispatch(actions.setSelectedItem(null));
			});
		});
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Restore calendar event?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
restoreCalendarEvent = creator('restoreCalendarEvent', restoreCalendarEvent);
