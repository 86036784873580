import * as React from 'react';
import {Volume2} from 'lucide-react';
const busyhere = document.getElementById('busyhereBeta');
const callEndedAudio = document.getElementById('callended');
const callAudio = document.getElementById('audio-remote');

export default function VolumeControl() {
	const [volume, setVolume] = React.useState(100);

	return (
		<div className="flex items-center gap-2 justify-between px-4 py-2 bg-gray-50">
			<Volume2 className="size-4" />
			<input
				type="range"
				defaultValue={volume}
				min={0}
				max={100}
				step={5}
				className="w-full flex-1"
				onChange={e => {
					const newVolume = e.target.value / 100;
					setVolume(e.target.value);
					busyhere.volume = newVolume;
					callEndedAudio.volume = newVolume;
					callAudio.volume = newVolume;
				}}
			/>
			<div className="w-12 text-right text-sm">&nbsp;{volume} %</div>
		</div>
	);
}
