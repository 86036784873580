import React, {useState} from 'react';
import {useAuth} from 'hooks/useAuth';
import {OrganizationBudgetForm} from './OrganizationBudgetForm';
import MonthPicker from './MonthPicker';
import OrganizationPicker from './OrganizationPicker';
import {useSessionStorage} from 'react-use';
import {sessionStorageKeys, getThisMonthISOString} from './utils';

export const OrganizationBudget = () => {
	const [date, setDate] = useSessionStorage(
		sessionStorageKeys.date,
		getThisMonthISOString(),
	);
	const {organizationId: userOrganizationId} = useAuth();
	const [organizationId, setOrganizationId] = useState(userOrganizationId);

	return (
		<div className="flex flex-col gap-6">
			<div className="flex justify-between items-start gap-8">
				<div className="flex flex-col gap-2 pl-4 border-r border-gray-100 pr-8">
					<MonthPicker month={date} onMonthChange={setDate} />
					<OrganizationPicker
						organizationId={organizationId}
						onOrganizationIdChange={setOrganizationId}
					/>
				</div>
			</div>
			<OrganizationBudgetForm organizationId={organizationId} date={date} />
		</div>
	);
};
