import React, {useEffect, useState} from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import Label from 'components/generic/Label';
import Field from 'components/generic/Field';
import FormBlock from 'components/generic/FormBlock';
import ReactSelect from 'components/generic/ReactSelect';
import FieldError from 'components/generic/FieldError';
import PropTypes from 'prop-types';
import {isNil} from 'ramda';

/*
 * Formlet for selecting products
 *
 * Intended to be used in redudx-forms for selecting products.
 * The formlet will by default fetch products lazily (via callback) when the input is focused.
 * The callback is usually located in module's effects-file.
 *
 * Products can also be passed as a prop, which will be used as default options.
 */
const ProductFormlet = ({
	name,
	label = null,
	isMulti = false,
	searchProducts = () => null,
	products = null,
}) => {
	const [cachedProducts, setCachedProducts] = useState(!isNil(products) ? products : []);

	useEffect(() => {
		if (!isNil(products)) {
			setCachedProducts(products);
		}
	}, [products]);

	return (
		<>
			<Field
				name={name}
				props={{cachedProducts}}
				component={({input, inputId, meta, cachedProducts}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							{label ? label : <FormattedMessage id="Products" />}
						</Label>
						<ReactSelect
							{...input}
							minWidth="150px"
							id={inputId}
							block
							isMulti={isMulti}
							async
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							getOptionValue={option => option.id}
							getOptionLabel={option => option.title}
							defaultOptions={cachedProducts}
							loadOptions={(text, callback) => {
								if (cachedProducts.length > 0) {
									// Only filter when we have cached products
									callback(
										cachedProducts.filter(p =>
											p.title.toLowerCase().includes(text.toLowerCase()),
										),
									);
									return;
								}

								searchProducts({
									text: '',
									callback: products => {
										setCachedProducts(products);
										callback(products);
									},
								});
							}}
							onFocus={() => {
								// Fetch products when input is focused (once)
								if (cachedProducts.length > 0) {
									return;
								}

								// When used inside effects-files, the callback is usually passed as a prop and will resolve with fetched products
								searchProducts({
									text: '',
									callback: products => {
										setCachedProducts(products);
									},
								});
							}}
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
		</>
	);
};

ProductFormlet.propTypes = {
	name: PropTypes.string.isRequired,
};

export default ProductFormlet;
