import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {TAG_FORM} from 'views/UsersApp/TagsPage/components/TagForm';
import {getFormValues} from 'redux-form';
import {checkboxValsToIdObjectList} from 'utils/data';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [
				{
					...state,
				},
				effects.initialize(),
			];
		}

		case actions._initialize.type: {
			return [
				{
					...state,
					initialized: true,
				},
				null,
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.getTags.type: {
			return [
				{
					...state,
					tagsLoading: true,
				},
				effects.getTags(),
			];
		}

		case actions._getTags.type: {
			return [
				{
					...state,
					tagsLoading: false,
					tags: payload,
				},
				null,
			];
		}

		case actions.openTag.type: {
			return [
				{
					...state,
					isTagModalOpen: true,
					activeTag: payload,
				},
				null,
			];
		}

		case actions.openTagModal.type: {
			return [
				{
					...state,
					isTagModalOpen: true,
				},
				null,
			];
		}

		case actions.closeTagModal.type: {
			return [
				{
					...state,
					isTagModalOpen: false,
					activeTag: null,
				},
				null,
			];
		}

		case actions.createTag.type: {
			const form = getFormValues(TAG_FORM)(fullState);
			const values = {
				...form,
				users: (form?.users || []).map(o => ({id: o.value})),
				teams: (form?.teams || []).map(o => ({id: o.value})),
				organizations: checkboxValsToIdObjectList(form?.organizations || []),
			};

			return [
				{
					...state,
					processing: true,
				},
				effects.createTag(values),
			];
		}

		case actions.updateTag.type: {
			const id = payload;
			const form = getFormValues(TAG_FORM)(fullState);

			const values = {
				...form,
				users: (form?.users || []).map(o => ({id: o.value})),
				teams: (form?.teams || []).map(o => ({id: o.value})),
				organizations: checkboxValsToIdObjectList(form?.organizations || []),
			};

			return [
				{
					...state,
					processing: true,
				},
				effects.updateTag({id, values}),
			];
		}

		case actions.deleteTag.type: {
			const id = payload;
			return [state, effects.deleteTag(id)];
		}

		case actions.setShowDeleted.type: {
			return [state, effects.setShowDeleted(payload)];
		}

		case actions._setShowDeleted.type: {
			return [
				{
					...state,
					tagsQuery: {
						...state.tagsQuery,
						trashed: payload,
					},
				},
				null,
			];
		}

		case actions.restoreTag.type: {
			const id = payload;
			return [state, effects.restoreTag(id)];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.searchUsers.type: {
			return [state, effects.searchUsers(payload)];
		}

		case actions.searchTeams.type: {
			return [state, effects.searchTeams(payload)];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
