import React from 'react';
import propTypes from 'prop-types';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Field from 'components/generic/Field';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';
import ReactSelect from 'components/generic/ReactSelect';
import {compose} from 'redux';
import FieldError from 'components/generic/FieldError';

/**
 * To make some options fixed, you can add the isFixed property to the option object.
 */
const OrganizationsFormlet = ({
	name,
	organizations,
	intl,
	required,
	disabled,
	isMulti,
	...rest
}) => {
	const organizationValues = organizations.map(org => ({
		label: org.title,
		value: org.id,
		isFixed: org?.isFixed ?? false,
	}));

	const options = organizationValues;
	return (
		<Field
			{...rest}
			name={name}
			props={{organizations, isMulti, options, disabled}}
			component={({input, inputId, options, meta, isMulti, disabled}) => (
				<FormBlock>
					<Label htmlFor={inputId}>
						<FormattedMessage id="Organization" />
					</Label>
					<br />
					<ReactSelect
						block
						styles={{
							multiValueRemove: (base, state) => {
								return state.data.isFixed ? {...base, display: 'none'} : base;
							},
						}}
						{...input}
						id={inputId}
						required={required}
						isClearable={!required}
						options={options}
						disabled={disabled}
						isMulti={isMulti}
					/>
					<FieldError {...meta} />
				</FormBlock>
			)}
		/>
	);
};
OrganizationsFormlet.propTypes = {
	name: propTypes.string,
};

export default compose(injectIntl)(OrganizationsFormlet);
