import React from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {IntlProvider} from '@meiko/react-intl';
import {applyState} from 'utils/redux';
import {allMessages, language as localeLang} from 'constants/loc';
// note: there's a bit of circular dependency between constants/loc and utils/loc, don't swap their import order
import {getUserLanguage} from 'utils/loc';
import {user} from 'modules/common/selectors';

// NOTE: also utils/createIntl! both are used.

const IntlWrapper = props => {
	const {children, user} = props;

	const userLanguage = user ? getUserLanguage(user) : localeLang;

	// note that locale default language is static. we want to ensure uniform display of dates and currencies across users, using the current locale's convention regardless of the selected message language.
	return (
		<IntlProvider
			locale={userLanguage}
			messages={allMessages[userLanguage]}
			defaultLocale={localeLang}
		>
			{children}
		</IntlProvider>
	);
};

IntlWrapper.propTypes = {
	children: propTypes.element,
	user: propTypes.object,
};

export default connect(applyState({user}))(IntlWrapper);
