const settingsLinks = {
	'Google Chrome': 'chrome://settings/content/microphone',
	'Microsoft Edge': 'edge://settings/content/microphone',
	Brave: 'brave://settings/content/microphone',
};

export const browserSettingsLink = () => {
	if (!navigator.userAgentData) {
		return false;
	}
	const browserName = navigator.userAgentData.brands.find(entry =>
		Object.keys(settingsLinks).includes(entry.brand),
	)?.brand;
	if (!settingsLinks[browserName]) {
		return false;
	}
	return settingsLinks[browserName];
};
