import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';
import {map} from 'ramda';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const defaultIncludes = 'organizations,users,teams';

export const getTags = (query = {}) =>
	httpJson(
		'GET',
		'/admin/tags',
		{getAllTags: true, include: defaultIncludes, ...query},
		{},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(normalize.tag));

export const createTag = input =>
	httpJson('POST', '/admin/tags', {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.tag));

export const updateTag = (id, input) =>
	httpJson('PUT', `/admin/tags/${id}`, {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.tag));

export const deleteTag = id =>
	httpJson('DELETE', `/admin/tags/${id}`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const restoreTag = id =>
	httpJson('POST', `/admin/tags/${id}/restore`, {}, {})
		.then(getResponseData(normalize.tag))
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})));

export const searchUsers = query =>
	httpJson('get', '/users', query)
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.then(getResponseData(map(normalize.user)));

export const searchTeams = query =>
	httpJson('get', '/teams', query)
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.then(getResponseData(normalize.team));
