import {useDispatch} from 'react-redux';

import {info, success, warning, error, loading} from 'modules/notifications/actions';

const NOTIFICATION_TYPES = {
	info,
	success,
	warning,
	error,
	loading,
};

const DEFAULT_TYPE = 'success';

export default function useNotifications() {
	const dispatch = useDispatch();

	const openNotification = ({type = DEFAULT_TYPE, id, message, duration}) => {
		if (!message) {
			throw new Error('Notification message is required');
		}
		try {
			const notificationId = id ?? Math.random().toString(36).substring(2, 15);
			const notificationType =
				NOTIFICATION_TYPES[type] ?? NOTIFICATION_TYPES[DEFAULT_TYPE];
			const notificationPayload = {id: notificationId, message, duration};

			dispatch(notificationType(notificationPayload));
		} catch (error) {
			console.error(error);
		}
	};

	return {
		openNotification,
	};
}
