import React from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import Label from 'components/generic/Label';
import Field from 'components/generic/Field';
import FormBlock from 'components/generic/FormBlock';
import ReactSelect from 'components/generic/ReactSelect';
import FieldError from 'components/generic/FieldError';
import PropTypes from 'prop-types';
import {state} from 'dicts/products';
import services from 'services';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const ProductStateFormlet = ({
	name,
	label = null,
	isMulti = false,
	isClearable = false,
}) => {
	return (
		<Field
			name={name}
			props={{
				options: Object.keys(state).map(key => ({
					value: key,
					label: intl.formatMessage({id: state[key]}),
				})),
				isClearable,
			}}
			component={({input, inputId, meta, options, isClearable}) => (
				<FormBlock>
					<Label htmlFor={inputId}>
						{label ? label : <FormattedMessage id="Product state" />}
					</Label>
					<ReactSelect
						{...input}
						minWidth="150px"
						id={inputId}
						block
						isMulti={isMulti}
						options={options}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						isClearable={isClearable}
					/>
					<FieldError {...meta} />
				</FormBlock>
			)}
		/>
	);
};

ProductStateFormlet.propTypes = {
	name: PropTypes.string.isRequired,
};

export default ProductStateFormlet;
