export const defaultPredicate = value => typeof value !== 'undefined';

export const shake = (obj, pred = defaultPredicate) => {
	const ret = {};
	for (let prop in obj) {
		if (pred(obj[prop])) {
			ret[prop] = obj[prop];
		}
	}
	return ret;
};
