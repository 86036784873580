import React from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import Field from 'components/generic/Field';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';
import ReactSelect from 'components/generic/ReactSelect';
import FieldError from 'components/generic/FieldError';

const TeamsFormlet = ({name, teams, disabled, isMulti = true, ...rest}) => {
	return (
		<Field
			{...rest}
			name={name}
			props={{availableTeams: teams, disabled}}
			component={({input, inputId, availableTeams, meta, disabled}) => (
				<FormBlock>
					<Label htmlFor={inputId}>
						<FormattedMessage id="Teams" />
					</Label>
					<br />
					<ReactSelect
						block
						{...input}
						id={inputId}
						isMulti={isMulti}
						isSearchable
						isClearable
						disabled={disabled}
						options={availableTeams.map(team => {
							return {
								label: `${team.title} ${
									team?.organization ? team?.organization?.title : ''
								}`,
								value: team.id,
							};
						})}
					/>
					<FieldError {...meta} />
				</FormBlock>
			)}
		/>
	);
};

export default TeamsFormlet;
